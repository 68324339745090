import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import './index.css'



const ClienteSecundario = ({ states, setters, action }) => {


    const [nome, setNome] = useState('')
    const [cpf, setCpf] = useState('')
    const [telefone, setTelefone] = useState('')
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')


    const [errorNome, setErrorNome] = useState(false)
    const [errorCpf, setErrorCpf] = useState(false)
    const [errorTelefone, setErrorTelefone] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorCep, setErrorCep] = useState(false)
    const [errorNumero, setErrorNumero] = useState(false)
    const [errorComplemento, setErrorComplemento] = useState(false)

    const [prestador, setPrestador] = useState('')
    const [errorPrestador, setErrorPrestador] = useState(false)
    const [select, setSelect] = useState(false)
    const [selectPrestador, setSelectPrestador] = useState({})

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }

    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }

    const handleAction = () => {
        let error = false

        if (nome === '') {
            setErrorNome(true)
            error = true
        } else setErrorNome(false)

        if (email === '') {
            setErrorEmail(true)
            error = true
        } else setErrorEmail(false)

        if (cpf === '') {
            setErrorCpf(true)
            error = true
        } else setErrorCpf(false)

        if (telefone === '') {
            setErrorTelefone(true)
            error = true
        } else setErrorTelefone(false)

        if (cep === '') {
            setErrorCep(true)
            error = true
        } else setErrorCep(false)

        if (numero === '') {
            setErrorNumero(true)
            error = true
        } else setErrorNumero(false)

        if (complemento === '') {
            setComplemento('N/A')
        } 

        if (error) return;

        action({
            nome,
            cpf,
            telefone,
            email,
            cep,
            numero,
            complemento,
            prestador_id: localStorage.getItem('empresa') === 'true' ? prestador : null
        })



    }

    const handlePsicologo = async (data) => {
        if (data === 'outro') {
            setters.setOffsetPrestadores(states.offsetPrestadores + states.limitePrestadores)
            return
        }
        const findServico = states.dataPrestadores.find(servico => servico.id === data)
        setSelectPrestador(findServico)
        setPrestador(data)
        setSelect(true)
        setErrorPrestador(false)
    }

    const removePrestador = () => {
        setSelect(false)
        setPrestador('')
        setSelectPrestador({})

    }



    useEffect(() => {
        if (states.modalPacientes === false) {
            setSelect(false)
            setPrestador('')
            setSelectPrestador({})
            setters.setPacientes({})
            setters.setDataPrestadores([])
            setters.setLimiteBuscaPrestadores(false)
            setters.setOffsetPrestadores(0)
            setNome('')
            setCpf('')
            setTelefone('')
            setEmail('')
            setCep('')
            setNumero('')
            setComplemento('')
            setErrorNome(false)
            setErrorCpf(false)
            setErrorTelefone(false)
            setErrorEmail(false)
            setErrorCep(false)
            setErrorNumero(false)
            setErrorComplemento(false)
        } else {
            if (states.isEdit) {
                setNome(states.Pacientes.nome)
                setCpf(states.Pacientes.cpf)
                setTelefone(states.Pacientes.telefone)
                setEmail(states.Pacientes.email)
                setCep(states.Pacientes.cep)
                setNumero(states.Pacientes.numero)
                setComplemento(states.Pacientes.complemento)
            } else {
                setters.setPacientes({})
                setNome('')
                setCpf('')
                setTelefone('')
                setEmail('')
                setCep('')
                setNumero('')
                setComplemento('')
                setErrorNome(false)
                setErrorCpf(false)
                setErrorTelefone(false)
                setErrorEmail(false)
                setErrorCep(false)
                setErrorNumero(false)
                setErrorComplemento(false)
            }
        }

    }, [states.modalPacientes])



    return (
        <>
            <Transition.Root show={states.modalPacientes} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setters.setModalPacientes(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {/* Mexer na altura, só colocar o height na classname abaixo */}
                                <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => setters.setModalPacientes(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className='flex-col border-b border-gray-900/10'>
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Paciente</h3>
                                        <div className='flex justify-center flex-col items-center gap-x-4 pb-4'>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                {
                                                    states.isEdit ? `Atualize os dados do paciente ${states.Pacientes.nome}` : 'Adicione um novo paciente'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className='pt-10 pl-10 pr-10'>
                                        <div className="space-y-12">

                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do paciente.</p>
                                                </div>
                                                <div className="sm:col-span-2">

                                                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-4 l gap-x-6 ">
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                                                                <input
                                                                    type="text"
                                                                    value={nome}
                                                                    onChange={(e) => setNome(e.target.value)}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorNome && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>

                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">E-mail</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    value={email}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorEmail && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">CPF</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    value={formatToCpfOrCnpj(cpf)}
                                                                    onChange={(e) => setCpf(e.target.value.replace(/\D/g, ''))}
                                                                    maxLength={14}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorCpf && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    value={formatToPhoneBrl(telefone)}
                                                                    onChange={(e) => setTelefone(e.target.value.replace(/\D/g, ''))}
                                                                    maxLength={15}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorTelefone && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações de localização</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados de localização.</p>
                                                </div>
                                                <div className="sm:col-span-2">

                                                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 l gap-x-6 ">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Cep</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    value={formatCep(cep)}
                                                                    onChange={(e) => setCep(e.target.value.replace(/\D/g, ''))}
                                                                    maxLength={9}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorCep && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Número</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    value={numero}
                                                                    onChange={(e) => setNumero(e.target.value.replace(/\D/g, ''))}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorNumero && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Complemento</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    value={complemento}
                                                                    onChange={(e) => setComplemento(e.target.value)}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorComplemento && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {
                                                (localStorage.getItem('empresa') === 'true') &&
                                                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                    <div>
                                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações do psicólogo</h2>
                                                        <p className="mt-1 text-sm leading-6 text-gray-600">Selecione o psicologo para esse paciente.</p>
                                                    </div>
                                                    <div className="sm:col-span-2">

                                                        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 l gap-x-6 ">
                                                            <div className="sm:col-span-2">
                                                                {
                                                                    !select ? (
                                                                        <>
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Selecione o psicólogo</label>
                                                                            <div className="flex border rounded-[15px] p-3 hover:shadow mt-4">
                                                                                <select
                                                                                    value={prestador}
                                                                                    onChange={(e) => handlePsicologo(e.target.value)}
                                                                                    className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                                >
                                                                                    <option value="">Selecione o psicólogo</option>
                                                                                    {states.dataPrestadores.map((servico, index) => (
                                                                                        <option key={index} value={servico.id}>{servico.nome}</option>
                                                                                    ))}
                                                                                    {
                                                                                        (!states.limiteBuscaPrestadores && states.dataPrestadores.length >= states.limitePrestadores) &&
                                                                                        <option value="outro">Carregar mais</option>
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            {
                                                                                errorPrestador && <span className="text-red-500 text-sm">Selecione um psicólogo</span>
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-4">
                                                                            <div className="flex flex-col">
                                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Dados</label>
                                                                                <p className="text-sm font-semibold leading-5 text-gray-900">{selectPrestador.nome} - {formatToCpfOrCnpj(selectPrestador.cpf)}</p>
                                                                            </div>
                                                                            <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Contato</label>
                                                                                <p className="text-sm font-semibold leading-5 text-gray-900">{formatToPhoneBrl(selectPrestador.telefone)} - {selectPrestador.email}</p>
                                                                            </div>
                                                                            <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Categoria</label>
                                                                                <p className="text-sm font-semibold leading-5 text-gray-900">{selectPrestador.nome_categoria}</p>
                                                                            </div>
                                                                            <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                                <button type="button" className="text-sm font-semibold leading-5 text-white bg-red-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-red-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-100"
                                                                                    onClick={removePrestador}
                                                                                >
                                                                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    errorCep && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="mt-6 flex items-center justify-end gap-x-6">
                                            <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                                onClick={() => setters.setModalPacientes(false)}>
                                                Fechar
                                            </button>
                                            <button type="button" className="text-sm font-semibold leading-5 text-white bg-blue-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                                                onClick={handleAction}>
                                                {
                                                    states.isEdit ? 'Atualizar' : 'Adicionar'
                                                }
                                            </button>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}


export default ClienteSecundario;