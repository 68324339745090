import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Table from '../../Components/Table/MeusClientes';
import ModalShow from '../../Components/Modal/ClientesSecundario/ClienteSecundarioShow'



const ClienteSecundario = () => {
    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getMeusPacientesDataSistema,
        getMeusPacientes,
        getMeusPacientesQuery,
    } = useContext(AuthContextApi)
    const [query, setQuery] = useState('')
    const { token } = useParams()
    const hist = useNavigate()



    const primeiroLoad = async () => {
        await checkLogin()
        await getMeusPacientesDataSistema(token)
        await getMeusPacientes()
    }

    const loadUsuarios = async () => {
        if (query !== '') {
            await getMeusPacientesQuery(query)
        } else {
            await getMeusPacientes()
        }
    }

    const next = async () => {
        if (states.limiteBuscaPacientes) {
            if (states.offsetPacientes + states.limitePacientes > 0) return
            setters.setLimiteBuscaPacientes(true)
        }
        setters.setOffsetPacientes(states.offsetPacientes + states.limitePacientes)
    }

    const previous = async () => {
        if (states.offsetPacientes - states.limitePacientes < 0) return
        if (states.limiteBuscaPacientes) {
            setters.setLimiteBuscaPacientes(false)
        }
        setters.setOffsetPacientes(states.offsetPacientes - states.limitePacientes)
    }

    const search = async () => {
        setters.setOffsetPacientes(0)
        if (query === '') {
            await getMeusPacientes()
            return
        }
        const response = await getMeusPacientesQuery(query)
        if (response === false) {
            setQuery('')
            await getMeusPacientes()
        }
    }

    const clear = async () => {
        setters.setOffsetPacientes(0)
        setQuery('')
        await getMeusPacientes()
    }

    const showPaciente = async (data) => {
        setters.setPacientes(data)
        setters.setModalPacientesShow(true)
    }



    const painel = async (data) => {
        hist(`/meusPacientes/${token}/paciente/${data.id}`)
    }




    useEffect(() => {
        primeiroLoad()

        return () => {
            setQuery('')
            setters.setDataEstatisticaPacientes([])
            setters.setDataPacientes([])
            setters.setPacientes({})
            setters.setOffsetPacientes(0)
            setters.setLimiteBuscaPacientes(false)
        }
    }, [])




    useEffect(() => {
        if (states.dataPacientes?.length === 0) return
        loadUsuarios()
    }, [states.offsetPacientes])



    const headers = ['', 'Nome', 'CPF', 'E-mail', 'Telefone', 'Endereço', 'Cep', 'Cidade/Estado', 'Data de Criação', 'Data de Atualização']
    return (
        <>
            <div className="flex flex-col">
                <div className="flex-1 p-4 border rounded-[10px]  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Meus Pacientes</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações sobre meus pacientes
                    </p>
                </div>
                <div className="grid grid-cols-1 gap-4  mt-4">
                    {states.dataEstatisticaPacientes?.length > 0 && states.dataEstatisticaPacientes.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {
                    states.dataPacientes?.length > 0 ? (
                        <>
                            <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                                <h1 className="text-base font-semibold leading-6 text-gray-900">Pesquisa por paciente</h1>
                                <div className='flex flex-col mt-4'>
                                    <p className="text-sm text-gray-700">Pesquise por nome, cpf, código ou email</p>
                                    <div className='flex flex-col sm:flex-row mt-2'>
                                        <input type="text" className=" flex w-full sm:w-[80%] mt-1 p-2 border-2 border-gray-200 rounded-md"
                                            value={query} onChange={(e) => setQuery(e.target.value)} onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    search()
                                                }
                                            }}
                                        />
                                        <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={search}>Pesquisar</button>
                                        <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-green-600 text-white rounded-md hover:bg-green-500" onClick={clear}>Limpar</button>
                                    </div>
                                </div>
                            </div>
                            <div className=" p-4 border hover:shadow-md mt-5">
                                {
                                    states.dataPacientes?.length > 0 &&
                                    <Table title='Pacientes' description='Registro dos pacientes cadastrados'
                                        next={next} show={showPaciente} painel={painel}
                                        previous={previous} states={states} setters={setters} headers={headers}
                                        infoTable={{ offset: states.offsetPacientes, limit: states.limitePacientes }} dataTable={states.dataPacientes} query={query} />
                                }
                            </div>
                        </>
                    ) : (
                        <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Sem pacientes cadastrados</h1>
                        </div>
                    )
                }


            </div>
            <ModalShow states={states} setters={setters} />
        </>
    )

}



export default ClienteSecundario;