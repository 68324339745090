import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context";
import { AuthContextApi } from "../../Context/api";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { toast } from "react-toastify";
import { jsonConfig } from "../../Config";
import Table from "../../Components/Table/ClienteSecundario";
import Modal from "../../Components/Modal/ClientesSecundario/ClienteSecundario";
import ModalShow from "../../Components/Modal/ClientesSecundario/ClienteSecundarioShow";

const ClienteSecundario = () => {
  const { states, setters } = useContext(AuthContext);
  const {
    checkLogin,

    getPacientesDataSistema,
    getPacientes,
    postPaciente,
    getPacientesQuery,
    getPrestadoresForm,
    putPaciente,
  } = useContext(AuthContextApi);
  const [query, setQuery] = useState("");
  const { token } = useParams();
  const hist = useNavigate();

  const primeiroLoad = async () => {
    await checkLogin();
    await getPacientesDataSistema(token);
    await getPacientes();
  };

  const loadUsuarios = async () => {
    if (query !== "") {
      await getPacientesQuery(query);
    } else {
      await getPacientes();
    }
  };

  const next = async () => {
    if (states.limiteBuscaPacientes) {
      if (states.offsetPacientes + states.limitePacientes > 0) return;
      setters.setLimiteBuscaPacientes(true);
    }
    setters.setOffsetPacientes(states.offsetPacientes + states.limitePacientes);
  };

  const previous = async () => {
    if (states.offsetPacientes - states.limitePacientes < 0) return;
    if (states.limiteBuscaPacientes) {
      setters.setLimiteBuscaPacientes(false);
    }
    setters.setOffsetPacientes(states.offsetPacientes - states.limitePacientes);
  };

  const search = async () => {
    setters.setOffsetPacientes(0);
    if (query === "") {
      await getPacientes();
      return;
    }
    const response = await getPacientesQuery(query);
    if (response === false) {
      setQuery("");
      await getPacientes();
    }
  };

  const clear = async () => {
    setters.setOffsetPacientes(0);
    setQuery("");
    await getPacientes();
  };

  const showPaciente = async (data) => {
    setters.setPacientes(data);
    setters.setModalPacientesShow(true);
  };

  const addPaciente = async () => {
    if (localStorage.getItem('empresa') === 'true') {
      await getPrestadoresForm();
    }
    setters.setIsEdit(false);
    setters.setPacientes({});
    setters.setModalPacientes(true);
  };

  const editPaciente = async (data) => {
    setters.setOffsetCategorias(0);
    setters.setIsEdit(true);
    setters.setPacientes(data);
    setters.setModalPacientes(true);
  };

  const actionModal = async (data) => {
    setters.setModalPacientes(false);
    if (states.isEdit) {
      setters.setIsEdit(false);
      await putPaciente(data);
    } else {
      await postPaciente(data);
    }
  };

  const painel = async (data) => {
    hist(`/pacientes/${token}/paciente/${data.id}`);
  };

  const loadMorePrestador = async () => {
    await getPrestadoresForm();
  };

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    toast.success("Link copiado");
  };

  useEffect(() => {
    primeiroLoad();

    return () => {
      setQuery("");
      setters.setDataEstatisticaPacientes([]);
      setters.setDataPacientes([]);
      setters.setPacientes({});
      setters.setOffsetPacientes(0);
      setters.setLimiteBuscaPacientes(false);
    };
  }, []);

  useEffect(() => {
    if (states.dataPrestadores.length > 0) {
      loadMorePrestador();
    }
  }, [states.offsetPrestadores]);

  useEffect(() => {
    if (states.dataPacientes?.length === 0) return;
    loadUsuarios();
  }, [states.offsetPacientes]);

  const headers = [
    "",
    "Nome",
    "CPF",
    "E-mail",
    "Telefone",
    "Endereço",
    "Cep",
    "Cidade/Estado",
    "Data de Criação",
    "Data de Atualização",
  ];
  return (
    <>
      <div className="flex flex-col">
        <div className="flex-1 p-4 border rounded-[10px]  hover:shadow-md">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Pacientes
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Informações dos pacientes cadastrados no sistema
          </p>
        </div>
        {(localStorage.getItem("areaCliente") === "true") ? (
          <div className="flex-1 p-4 border rounded-[10px]  hover:shadow-md mt-4">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Link de acesso dos clientes
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Clique no botão abaixo para copiar o link de acesso dos clientes
            </p>
            <div className="flex flex-col mt-4">
              <p className="w-full p-2 border-2 border-gray-200 rounded-md">{`${jsonConfig.urlReactClientes}/plataforma/${token}`}</p>
              <button
                className="flex  mt-5 w-[30%] justify-center items-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 ml-2"
                onClick={() =>
                  copyLink(`${jsonConfig.urlReactClientes}/plataforma/${token}`)
                }
              >
                Copiar
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex-1 p-4 border rounded-[10px]  hover:shadow-md mt-4">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Link de acesso dos clientes - Faça o upgrade para ter acesso
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Clique no botão abaixo para copiar o link de acesso dos clientes
              </p>
              <p className="mt-2 text-sm text-gray-700">
                Seus pacientes poderão acessar o sistema através do link abaixo
              </p>
              <div className="flex flex-col mt-4">
                <p className="w-full p-2 border-2 border-gray-200 rounded-md">{`https://areadopaciente.com.br`}</p>
                <button
                  className="flex  mt-5 w-[30%] justify-center items-center p-2 bg-blue-500 text-white rounded-md  ml-2"
                  disabled
                >
                  Copiar
                </button>
              </div>
            </div>
          </div>
        )


        }
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-4">
          {states.dataEstatisticaPacientes?.length > 0 &&
            states.dataEstatisticaPacientes.map((card, index) => (
              <div
                key={card.title}
                className={
                  card.value > 0 && index === -1
                    ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                    : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                }
              >
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      {card.value > 0 && index === -1 ? (
                        <InformationCircleIcon
                          className="w-6 h-6 text-red-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <InformationCircleIcon
                          className="w-6 h-6 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        {card.value > 0 && index === -1 ? (
                          <dt className="truncate text-sm font-medium text-red-500">
                            {card.title}
                          </dt>
                        ) : (
                          <dt className="truncate text-sm font-medium text-gray-500">
                            {card.title}
                          </dt>
                        )}

                        <dd>
                          {card.value > 0 && index === -1 ? (
                            <div className="text-lg font-medium text-red-500">
                              {card.value}
                            </div>
                          ) : (
                            <div className="text-lg font-medium text-gray-900">
                              {card.value}
                            </div>
                          )}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <>
          <div className="flex-1 p-12 border  hover:shadow-md mt-5">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Pesquisa por paciente
            </h1>
            <div className="flex flex-col mt-4">
              <p className="text-sm text-gray-700">
                Pesquise por nome, cpf, código ou email
              </p>
              <div className="flex flex-col sm:flex-row mt-2">
                <input
                  type="text"
                  className=" flex w-full sm:w-[80%] mt-1 p-2 border-2 border-gray-200 rounded-md"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      search();
                    }
                  }}
                />
                <button
                  className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                  onClick={search}
                >
                  Pesquisar
                </button>
                <button
                  className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-green-600 text-white rounded-md hover:bg-green-500"
                  onClick={clear}
                >
                  Limpar
                </button>
              </div>
            </div>
          </div>
          <div className=" p-4 border hover:shadow-md mt-5">
            {/*
                                    states.dataPacientes?.length > 0 &&*/}
            <Table
              title="Pacientes"
              description="Registro dos pacientes cadastrados"
              next={next}
              show={showPaciente}
              add={addPaciente}
              edit={editPaciente}
              painel={painel}
              previous={previous}
              states={states}
              setters={setters}
              headers={headers}
              infoTable={{
                offset: states.offsetPacientes,
                limit: states.limitePacientes,
              }}
              dataTable={states.dataPacientes}
              query={query}
            />
            {/* */}
          </div>
        </>
      </div>
      <ModalShow states={states} setters={setters} />
      <Modal states={states} setters={setters} action={actionModal} />
    </>
  );
};

export default ClienteSecundario;
