import { Link } from "react-router-dom";
import { ArchiveBoxIcon, BanknotesIcon } from '@heroicons/react/24/outline'


const FinancieroIndex = ({ token }) => {





    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-[10px] overflow-hidden bg-white hover:shadow-md">
                <h1 className="text-3xl text-center font-bold">Painel Financeiro</h1>
                <p className="text-[18px] mt-1 text-center">Aqui você terá acesso aos dados financeiros do seu sistema</p>
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2">
                <div className="flex items-center justify-center">
                    <Link to={`/financeiroIndex/${token}/faturas`} className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-[15px] overflow-hidden bg-white hover:shadow-md">
                        <BanknotesIcon className="h-[30%] w-[30%] text-blue-600" />
                        <h2 className="text-xl font-bold text-center">Faturas</h2>
                        <p className="text-center">Clique nesse cartão para acessar as suas faturas.</p>
                    </Link>
                </div>
                <div className="flex items-center justify-center">
                    <Link to={`/financeiroIndex/${token}/carnes`} className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-[15px] overflow-hidden bg-white hover:shadow-md">
                        <ArchiveBoxIcon className="h-[30%] w-[30%] text-blue-600" />
                        <h2 className="text-xl text-center font-bold">Carnês Leão</h2>
                        <p className="text-center">Clique nesse cartão para acessar os carnês gerados</p>
                    </Link>
                </div>
            </div>
        </div>
    )

}



export default FinancieroIndex;