import { useState, useEffect } from 'react';

const ShowImageComponente = ({ file, isBase64 }) => {
    const [urlImg, setUrlImg] = useState(null);

    useEffect(() => {
        if (file && !isBase64) {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setUrlImg(reader.result);
                }
            }
            reader.readAsDataURL(file);
        } else {
            setUrlImg(null);
        }
    }, [file]);



    return (
        <div className={`border-2 'border-gray-300' border-dashed p-6 sm:w-[45em] text-center flex flex-col justify-center items-center rounded-[10px]`}>
            {file ? (
                <>
                    {
                        (isBase64) ? (<>
                            <img
                                className=" object-cover rounded-[10px]"
                                src={`data:image/png;base64,${file}`}
                                alt='Imagem do usuário'
                            />


                        </>) : (
                            <>
                                {
                                    (file.type.includes('image')) ? (
                                        <img src={urlImg} alt="Imagem" className=" object-cover rounded-[10px]" />
                                    ) : (
                                        <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                            </svg>
                                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                <span class="font-semibold">
                                                    Exibição de imagem
                                                </span>
                                            </p>
                                            <p class="text-xs text-gray-500 dark:text-gray-400">O arquivo selecionado não é uma imagem.</p>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </>
            ) : (
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span class="font-semibold">
                            Exibição de imagem
                        </span>
                    </p>
                    <p class="text-xs text-gray-500 dark:text-gray-400">Após selecionar a imagem, ela será exibida aqui.</p>
                </div>
            )}
        </div>
    );
}

export default ShowImageComponente;
