import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'




const ServicoShow = ({ states, setters }) => {


    const formatToMoney = value => {
        if (!value) return
        const valorFloat = value / 100;
        return valorFloat.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }



    useEffect(() => {
        if (states.modalServicosShow === false) {
            setters.setServicos({})
        }

    }, [states.modalServicosShow])


    return (
        <>
            <Transition.Root show={states.modalServicosShow} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setters.setModalServicosShow(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {/* Mexer na altura, só colocar o height na classname abaixo */}
                                <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => setters.setModalServicosShow(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className='flex-col border-b border-gray-900/10'>
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Serviço</h3>
                                        <div className='flex justify-center flex-col items-center gap-x-4 pb-4'>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                Informações sobre o serviço: <span className="font-semibold">{states.Servicos.nome}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='pt-10 pl-10 pr-10'>
                                        <div className="space-y-12">
                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do serviço.</p>
                                                </div>
                                                <div className="sm:col-span-2">

                                                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 gap-x-6 ">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{states.Servicos.nome}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Categoria</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{states.Servicos.categoria_nome}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Descrição</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{states.Servicos.descricao}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 mt-6">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Duração</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{states.Servicos.duracao}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Valor</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{states.Servicos.preco ? formatToMoney(states.Servicos.preco) : 'Gratuito'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Status</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                                                    {states.Servicos.status === 1 ? 'Ativo' : 'Inativo'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 mt-8">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Data de criação</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{moment(states.Categorias.data_criacao).format('DD/MM/YYYY')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Data da última atualização</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">{moment(states.Categorias.data_atualizacao).format('DD/MM/YYYY')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 flex items-center justify-end gap-x-6">
                                            <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                                onClick={() => setters.setModalServicosShow(false)}>
                                                Fechar
                                            </button>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}


export default ServicoShow;