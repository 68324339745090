import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../Context";
import { AuthContextApi } from "../../../Context/api";
import {
  InformationCircleIcon,
  PencilIcon,
  XMarkIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";
import NavMenu from "../../../Components/NavMenu/Configuracoes";
import ShowFile from "../../../Components/ShowImageUpload/ConfiguracoesPainel";
import UploadLogo from "../../../Components/Upload/ConfiguracoesPainel";

const ConfiguracoesNegocio = () => {
  const { states, setters } = useContext(AuthContext);
  const { checkLogin, checkConfigCliente, putConfigClienteForm } =
    useContext(AuthContextApi);
  const { token } = useParams();
  const [configCliente, setConfigCliente] = useState({});
  const [edit, setEdit] = useState(false);

  const [logo, setLogo] = useState("");
  const [errorLogo, setErrorLogo] = useState(false);
  const [errorLogoMessage, setErrorLogoMessage] =
    useState("Campo obrigatório.");

  const [horarioAbertura, setHorarioAbertura] = useState("");
  const [horarioFechamento, setHorarioFechamento] = useState("");
  const [corPrimaria, setCorPrimaria] = useState("");
  const [corSecundaria, setCorSecundaria] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [email, setEmail] = useState("");

  const [isBase64, setIsBase64] = useState(false);

  const [errorHoraAbertura, setErrorHoraAbertura] = useState(false);
  const [errorHoraFechamento, setErrorHoraFechamento] = useState(false);
  const [errorCorPrimaria, setErrorCorPrimaria] = useState(false);
  const [errorCorPrimariaMsg, setErrorCorPrimariaMsg] =
    useState("Campo obrigatório.");
  const [errorCorSecundaria, setErrorCorSecundaria] = useState(false);
  const [errorCorSecundariaMsg, setErrorCorSecundariaMsg] =
    useState("Campo obrigatório.");
  const [errorWhatsapp, setErrorWhatsapp] = useState(false);
  const [errorInstagram, setErrorInstagram] = useState(false);
  const [errorFacebook, setErrorFacebook] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const primeiroLoad = async () => {
    await checkLogin();
    await checkConfigCliente(token);
  };

  const formatToPhoneBrl = (value) => {
    return value?.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  };

  const handleColorPrimariaChange = (e) => {
    const value = e.target.value;
    // Verifica se o valor é um hexadecimal válido
    if (/^#[0-9A-F]{6}$/i.test(value)) {
      setErrorCorPrimaria(false);
      setErrorCorPrimariaMsg("Campo obrigatório.");
      setCorPrimaria(value);
    } else {
      setErrorCorPrimariaMsg(
        "Cor inválida. Por favor, insira um hexadecimal válido."
      );
      setErrorCorPrimaria(true);
    }
  };

  const handleColorSecundariaChange = (e) => {
    const value = e.target.value;
    // Verifica se o valor é um hexadecimal válido
    if (/^#[0-9A-F]{6}$/i.test(value)) {
      setErrorCorSecundaria(false);
      setErrorCorSecundariaMsg("Campo obrigatório.");
      setCorSecundaria(value);
    } else {
      setErrorCorSecundariaMsg(
        "Cor inválida. Por favor, insira um hexadecimal válido."
      );
      setErrorCorSecundaria(true);
    }
  };

  const action = async () => {
    let error = false;
    if (logo === "") {
      error = true;
      setErrorLogo(true);
      setErrorLogoMessage("Campo obrigatório.");
    } else {
      setErrorLogo(false);
      setErrorLogoMessage("Campo obrigatório.");
    }
    if (horarioAbertura === "") {
      error = true;
      setErrorHoraAbertura(true);
    } else setErrorHoraAbertura(false);
    if (horarioFechamento === "") {
      error = true;
      setErrorHoraFechamento(true);
    } else setErrorHoraFechamento(false);
    if (corPrimaria === "") {
      error = true;
      setErrorCorPrimaria(true);
      setErrorCorPrimariaMsg("Campo obrigatório.");
    } else setErrorCorPrimaria(false);
    if (corSecundaria === "") {
      error = true;
      setErrorCorSecundaria(true);
      setErrorCorSecundariaMsg("Campo obrigatório.");
    } else setErrorCorSecundaria(false);
    if (whatsapp === "") {
      error = true;
      setErrorWhatsapp(true);
    } else setErrorWhatsapp(false);
    if (instagram === "") {
      error = true;
      setErrorInstagram(true);
    } else setErrorInstagram(false);
    if (facebook === "") {
      error = true;
      setErrorFacebook(true);
    } else setErrorFacebook(false);
    if (email === "") {
      error = true;
      setErrorEmail(true);
    } else setErrorEmail(false);

    if (error) {
      error = false;
      return;
    }
    //Controla pelo base64 se teve logo

    const form = new FormData();
    // setHorarioAbertura(configCliente.horarioAbertura)
    // setHorarioFechamento(configCliente.horarioFechamento)
    const abertura =
      horarioAbertura !== configCliente.horarioAbertura
        ? horarioAbertura + ":00"
        : configCliente.horarioAbertura;
    const fechamento =
      horarioFechamento !== configCliente.horarioFechamento
        ? horarioFechamento + ":00"
        : configCliente.horarioFechamento;

    if (isBase64) {
      form.append("horarioAbertura", abertura);
      form.append("horarioFechamento", fechamento);
      form.append("corPrimaria", corPrimaria);
      form.append("corSecundaria", corSecundaria);
      form.append("whatsapp", whatsapp);
      form.append("instagram", instagram);
      form.append("facebook", facebook);
      form.append("email", email);
    } else {
      form.append("image", logo);
      form.append("horarioAbertura", abertura);
      form.append("horarioFechamento", fechamento);
      form.append("corPrimaria", corPrimaria);
      form.append("corSecundaria", corSecundaria);
      form.append("whatsapp", whatsapp);
      form.append("instagram", instagram);
      form.append("facebook", facebook);
      form.append("email", email);
    }
    const response = await putConfigClienteForm(form, token);
    if (response === true) {
      setEdit(false);
    }
  };

  useEffect(() => {
    primeiroLoad();
    return () => {
      setters.setClienteConfig({});
    };
  }, []);

  const updateLogo = (file) => {
    setLogo(file);
    setIsBase64(false);
  };

  useEffect(() => {
    // esta chamando varias vezes
    setConfigCliente(states.clienteConfig);
  }, [states.clienteConfig]);

  useEffect(() => {
    if (edit === false) {
      if (Object.keys(states.clienteConfig).lenght === "0") {
        setConfigCliente(states.clienteConfig);
      }
      setLogo("");
      setHorarioAbertura("");
      setHorarioFechamento("");
      setCorPrimaria("");
      setCorSecundaria("");
      setWhatsapp("");
      setInstagram("");
      setFacebook("");
      setEmail("");
    } else {
      setIsBase64(true);
      setLogo(configCliente.logo);
      setHorarioAbertura(configCliente.horarioAbertura);
      setHorarioFechamento(configCliente.horarioFechamento);
      setCorPrimaria(configCliente.corPrimaria);
      setCorSecundaria(configCliente.corSecundaria);
      setWhatsapp(configCliente.whatsapp);
      setInstagram(configCliente.instagram);
      setFacebook(configCliente.facebook);
      setEmail(configCliente.email);
    }
    setErrorLogo(false);
    setErrorHoraAbertura(false);
    setErrorHoraFechamento(false);
    setErrorCorPrimaria(false);
    setErrorCorSecundaria(false);
    setErrorWhatsapp(false);
    setErrorInstagram(false);
    setErrorFacebook(false);
    setErrorEmail(false);
  }, [edit]);

  return (
    <>
      <div className="flex flex-col">
        <NavMenu />
        <div className="flex-1 p-4 border rounded-[10px]  hover:shadow-md">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Configurações do negócio
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Informações cadastradas sobre o seu negócio
          </p>
        </div>
        <div className="flex flex-col sm:flex-row  mt-4 ">
          <div className="">
            <button
              className="flex items-center flex-1 p-4 border  rounded-[10px]  hover:shadow-md mt-4"
              onClick={() => setEdit(!edit)}
            >
              <div className="flex-shrink-0">
                {!edit ? (
                  <PencilIcon
                    className="w-6 h-6 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <XMarkIcon
                    className="w-6 h-6 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </div>
              <p className="text-sm font-semibold text-gray-500 truncate ml-4">
                {edit ? "Cancelar" : "Editar"}
              </p>
            </button>
          </div>
          {edit && (
            <div>
              <button
                className="flex items-center flex-1 p-4 border  rounded-[10px]  hover:shadow-md mt-4 sm:ml-5"
                onClick={() => action()}
              >
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="w-6 h-6 text-gray-300"
                    aria-hidden="true"
                  />
                </div>
                <p className="text-sm font-semibold text-gray-500 truncate ml-4">
                  Enviar
                </p>
              </button>
            </div>
          )}
        </div>
        {Object.keys(configCliente).length > 0 ? (
          <>
            {edit ? (
              <>
                <div className="flex flex-col mt-4">
                  {/* LOGO => APENAS A LOGO AQUI */}
                  <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 border p-4 rounded-[10px] hover:shadow">
                    <div className="sm:col-span-2">
                      <div className="flex ">
                        <label
                          htmlFor="logo"
                          className="block text-sm font-semibold leading-5 text-gray-700"
                        >
                          Logo
                        </label>
                        <p className="text-sm text-gray-500 ml-2">
                          Escolha a logo da sua empresa
                        </p>
                      </div>
                      <div className="flex flex-col sm:flex-row  items-between justify-between mt-3">
                        <UploadLogo
                          setError={setErrorLogo}
                          setErrorFileMessage={setErrorLogoMessage}
                          setFile={updateLogo}
                          file={logo}
                          isBase64={isBase64}
                        />
                        <span className="mt-4 sm:mt-0 ml-0 sm:ml-3"></span>
                        <ShowFile file={logo} isBase64={isBase64} />
                      </div>
                      {errorLogo && (
                        <>
                          <p className="text-red-500 text-sm mt-3 ml-2">
                            {errorLogoMessage}
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  {/* DE HORARIOS ATE COR SECUNDARIA */}
                  <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 mt-4 border p-5 rounded-[10px] hover:shadow">
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="horario_abertura"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Horário de abertura
                      </label>
                      <input
                        id="horario_abertura"
                        name="horario_abertura"
                        type="time"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={horarioAbertura}
                        onChange={(e) => setHorarioAbertura(e.target.value)}
                      />
                      {errorHoraAbertura && (
                        <>
                          <p className="text-red-500 text-sm mt-3 ml-2">
                            Campo obrigatório.
                          </p>
                        </>
                      )}
                    </div>
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="horario_fechamento"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Horário de fechamento
                      </label>
                      <input
                        id="horario_fechamento"
                        name="horario_fechamento"
                        type="time"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={horarioFechamento}
                        onChange={(e) => setHorarioFechamento(e.target.value)}
                      />
                      {errorHoraFechamento && (
                        <>
                          <p className="text-red-500 text-sm mt-3 ml-2">
                            Campo obrigatório.
                          </p>
                        </>
                      )}
                    </div>
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="cor_primaria"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Cor primária
                      </label>
                      <div className="flex items-center">
                        <input
                          id="cor_primaria"
                          name="cor_primaria"
                          type="color"
                          className="mt-1 block w-12 h-12 border border-gray-500  cursor-pointer"
                          value={corPrimaria}
                          onChange={handleColorPrimariaChange}
                        />
                        <input
                          type="text"
                          className={`ml-3 block w-full  pl-3 pr-10 py-2 text-base border ${
                            errorCorPrimaria
                              ? "border-red-500"
                              : "border-gray-500"
                          } focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                          value={corPrimaria}
                          onChange={handleColorPrimariaChange}
                        />
                      </div>
                      {errorCorPrimaria && (
                        <>
                          <p className="text-red-500 text-sm mt-3 ml-2">
                            {errorCorPrimariaMsg}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="cor_secundaria"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Cor secundária
                      </label>
                      <div className="flex items-center">
                        <input
                          id="cor_secundaria"
                          name="cor_secundaria"
                          type="color"
                          className="mt-1 block w-12 h-12 border border-gray-500  cursor-pointer"
                          value={corSecundaria}
                          onChange={handleColorSecundariaChange}
                        />
                        <input
                          type="text"
                          className={`ml-3 block w-full  pl-3 pr-10 py-2 text-base border ${
                            errorCorPrimaria
                              ? "border-red-500"
                              : "border-gray-500"
                          } focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                          value={corSecundaria}
                          onChange={handleColorSecundariaChange}
                        />
                      </div>
                      {errorCorSecundaria && (
                        <>
                          <p className="text-red-500 text-sm mt-3 ml-2">
                            {errorCorSecundariaMsg}
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  {/* DE HORARIOS ATE COR SECUNDARIA */}
                  <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 mt-4 border p-5 rounded-[10px] hover:shadow">
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="whatsapp"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Whatsapp
                      </label>
                      <input
                        id="whatsapp"
                        name="whatsapp"
                        type="text"
                        value={formatToPhoneBrl(whatsapp)}
                        onChange={(e) => {
                          const onlyNums = e.target.value.replace(/[^\d]/g, "");
                          if (onlyNums === "") {
                            setWhatsapp("");
                            setErrorWhatsapp(true);
                          } else {
                            setErrorWhatsapp(false);
                            if (onlyNums.length <= 11) {
                              setWhatsapp(onlyNums);
                            }
                          }
                        }}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      />
                      {errorWhatsapp && (
                        <span className="text-red-500 text-sm mt-3 ml-2">
                          Campo obrigatório.
                        </span>
                      )}
                    </div>
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="instagram"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Instagram
                      </label>
                      <input
                        id="instagram"
                        name="instagram"
                        type="text"
                        value={instagram}
                        onChange={(e) => setInstagram(e.target.value)}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      />
                      {errorInstagram && (
                        <span className="text-red-500 text-sm mt-3 ml-2">
                          Campo obrigatório.
                        </span>
                      )}
                    </div>
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="facebook"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Facebook
                      </label>
                      <input
                        id="facebook"
                        name="facebook"
                        type="text"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={facebook}
                        onChange={(e) => setFacebook(e.target.value)}
                      />
                      {errorFacebook && (
                        <span className="text-red-500 text-sm mt-3 ml-2">
                          Campo obrigatório.
                        </span>
                      )}
                    </div>
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="email"
                        className="block text-sm font-semibold leading-5 text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errorEmail && (
                        <span className="text-red-500 text-sm mt-3 ml-2">
                          Campo obrigatório.
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex  mt-4 justify-between flex-col sm:flex-row">
                  {/* EXIBICAOD A IMAGEM  */}
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols1 lg:grid-cols-1 mt-4 border sm:w-[25%] p-4 rounded-[10px]  hover:shadow-md">
                    <p className="text-base font-semibold leading-6 text-gray-900">
                      Logo
                    </p>
                    <div className="flex w-full  items-start justify-center">
                      <img
                        className="h-[10em] w-[10em] rounded-full"
                        src={`data:image/png;base64,${configCliente.logo}`}
                        alt={`Logo ${configCliente.cliente_nome}`}
                      />
                    </div>
                  </div>
                  {/* EXIBICAO DAS CONFIGURACOES EM GERAL */}
                  <div className=" mt-4 border sm:w-[75%] p-4 sm:ml-4 rounded-[10px]  hover:shadow-md">
                    <p className="text-base font-semibold leading-6 text-gray-900">
                      Configurações
                    </p>
                    <div className="flex flex-col sm:flex-row w-full  mt-2   p-5 ">
                      <div className="flex flex-col">
                        <div className="flex flex-col mt-2">
                          <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Cor primária
                          </h1>
                          <div className="flex items-center">
                            <p className="mt-2 text-sm text-gray-700">
                              {configCliente.corPrimaria}
                            </p>
                            <div
                              className="ml-4 w-10 h-10 rounded-full"
                              style={{
                                backgroundColor: configCliente.corPrimaria,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="flex flex-col mt-2">
                          <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Cor secundária
                          </h1>
                          <div className="flex items-center">
                            <p className="mt-2 text-sm text-gray-700">
                              {configCliente.corSecundaria}
                            </p>
                            <div
                              className="ml-4 w-10 h-10 rounded-full"
                              style={{
                                backgroundColor: configCliente.corSecundaria,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="flex flex-col mt-2">
                          <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Email
                          </h1>
                          <a
                            className="mt-2 text-sm text-gray-700 hover:text-blue-500"
                            href={`mailto:${configCliente.email}`}
                          >
                            {configCliente.email}
                          </a>
                        </div>
                        <div className="flex flex-col mt-2">
                          <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Facebook
                          </h1>
                          <a
                            className="mt-2 text-sm text-gray-700 hover:text-blue-500"
                            href={configCliente.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {configCliente.facebook}
                          </a>
                        </div>
                      </div>
                      <div className="flex flex-col sm:ml-32">
                        <div className="flex flex-col mt-2">
                          <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Instagram
                          </h1>
                          <a
                            className="mt-2 text-sm text-gray-700 hover:text-blue-500"
                            href={configCliente.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {configCliente.instagram}
                          </a>
                        </div>
                        <div className="flex flex-col mt-2">
                          <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Whatsapp
                          </h1>
                          <a
                            className="mt-2 text-sm text-gray-700 hover:text-blue-500"
                            href={`https://api.whatsapp.com/send?phone=55${configCliente.whatsapp}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {formatToPhoneBrl(configCliente.whatsapp)}
                          </a>
                        </div>
                        <div className="flex flex-col mt-2">
                          <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Horário de abertura
                          </h1>
                          <p className="mt-2 text-sm text-gray-700">
                            {configCliente.horarioAbertura}
                          </p>
                        </div>
                        <div className="flex flex-col mt-2">
                          <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Horário de fechamento
                          </h1>
                          <p className="mt-2 text-sm text-gray-700">
                            {configCliente.horarioFechamento}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-3 mt-4">
              <div className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        className="w-6 h-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="text-sm font-semibold text-gray-500 truncate">
                          Nenhuma informação encontrada
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ConfiguracoesNegocio;
