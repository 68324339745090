import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArchiveBoxIcon, BanknotesIcon, CalculatorIcon, ArrowDownCircleIcon, ArrowUpCircleIcon, InformationCircleIcon, BookOpenIcon } from '@heroicons/react/24/outline'
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment'

Chart.register(...registerables);

const FinancieroIndex = ({ token, states, setters, load, relatorio }) => {

    const [open, setOpen] = useState(true)
    const [data1Grafico, setData1Grafico] = useState([])
    const [valor1Grafico, setValor1Grafico] = useState([])
    const [data2Grafico, setData2Grafico] = useState([])
    const [valor2Grafico, setValor2Grafico] = useState([])
    const [valorShow1, setValorShow1] = useState(0)
    const [valorShow2, setValorShow2] = useState(0)



    let data1 = {
        labels: data1Grafico,
        datasets: [
            {
                label: 'Atendimentos por Dia (Gráfico 1)',
                data: valor1Grafico,
                fill: true,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
            },
        ],
    };

    let data2 = {
        labels: data2Grafico,
        datasets: [
            {
                label: 'Atendimentos por Dia (Gráfico 2)',
                data: valor2Grafico,
                fill: true,
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderColor: 'rgba(153, 102, 255, 1)',
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };


    const primeitoLoad = async (data) => {
        await load(data)
    }

    const generateRelatorio = data => {
        setters.setModalUsuarios(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja gerar o relatório financeiro?`)
        setters.setDescModalMsg(`Após a confirmação o relatório será gerado e você poderá baixá-lo quando desejar`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            await relatorio(data)
            setters.setModalMsg(false)
        })
    }

    useEffect(() => {
        primeitoLoad({
            inicio: states.dataInicialFinanceiro,
            fim: states.dataFinalFinanceiro
        })

        return () => {
            setters.setDataInicialFinanceiro(moment().subtract(1, 'year').format('YYYY-MM-DD'))
            setters.setDataFinalFinanceiro(moment().format('YYYY-MM-DD'))
            setters.setDataFinanceiro([])
            setters.setDataFinanceiroGrafico([])
        }

    }, [])

    useEffect(() => {
        const formattedDates = states.dataFinanceiroGrafico.map((item) => moment(item.dataInicial).format('DD/MM/YYYY'));
        const valores = states.dataFinanceiroGrafico.map((item) => parseInt(item.total));
        const valores2 = states.dataFinanceiroGrafico.map((item) => parseFloat(item.totalBRL)); // Remover caracteres não numéricos

        setValorShow1(valores.reduce((a, b) => a + b, 0))
        setValorShow2(valores2.reduce((a, b) => a + b, 0))

        setData1Grafico(formattedDates);
        setValor1Grafico(valores);
        setData2Grafico(formattedDates);
        setValor2Grafico(valores2);
    }, [states.dataFinanceiroGrafico]);






    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-[10px] overflow-hidden bg-white hover:shadow-md">
                <h1 className="text-3xl text-center font-bold">Painel Financeiro</h1>
                <p className="text-[18px] mt-1 text-center">Aqui você terá acesso aos dados financeiros do seu sistema</p>
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-4">
                {states.dataFinanceiro?.length > 0 && states.dataFinanceiro.map((card, index) => (
                    <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                        <div className="p-5">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    {
                                        card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                    }
                                </div>
                                <div className="ml-5 w-0 flex-1">
                                    <dl>
                                        {
                                            card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                        }

                                        <dd>
                                            {
                                                card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                            }
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="flex items-center justify-center p-4 border mt-4 mb-4 ">
                <div className="flex   justify-between">
                    <div className="p-4">
                        <p>Selecione a data inicial</p>
                        <input type="date" value={states.dataInicialFinanceiro} onChange={(e) => setters.setDataInicialFinanceiro(e.target.value)} className="border p-4  mt-4" />
                    </div>
                    <div className="p-4">
                        <p>Selecione a data final</p>
                        <input type="date" value={states.dataFinalFinanceiro} onChange={(e) => setters.setDataFinalFinanceiro(e.target.value)} className="border p-4  mt-4" />
                    </div>
                </div>

                <div className="flex  mt-5 justify-between">
                    <button className="bg-blue-500 p-2 text-gray-200 rounded-[10px] hover:shadow-lg hover:bg-blue-600" onClick={() => primeitoLoad({ inicio: states.dataInicialFinanceiro, fim: states.dataFinalFinanceiro })}>
                        Aplicar
                    </button>
                    <button className="bg-green-600 p-2 text-gray-200 rounded-[10px] ml-5 hover:shadow-lg hover:bg-green-700" onClick={() => generateRelatorio({ inicio: states.dataInicialFinanceiro, fim: states.dataFinalFinanceiro })}>
                        Gerar Relatório
                    </button>
                </div>
            </div>


            <div className="p-4 border">
                <div className="flex   justify-between">
                    <div className="flex flex-col items-start">
                        <p className="text-2xl font-bold">Financeiro</p> <br />
                        <p className="text-[18px] ml-2">Clique na seta para aparecer ou sumir com o painel</p>
                    </div>
                    <div className="flex items-start">
                        <button onClick={() => setOpen(!open)} className="p-2 text-white hover:shadow-lg border rounded-[10px]">
                            {
                                open ? <ArrowUpCircleIcon className="h-8 w-8 text-blue-500" /> : <ArrowDownCircleIcon className="h-8 w-8 text-blue-500" />
                            }
                        </button>
                    </div>
                </div>
                {
                    open &&
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                        <div className="flex items-center justify-center">
                            <Link to={`/financeiroIndex/${token}/faturas`} className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-[15px] overflow-hidden bg-white hover:shadow-md">
                                <BanknotesIcon className="h-[30%] w-[30%] text-blue-600" />
                                <h2 className="text-xl font-bold text-center">Faturas</h2>
                                <p className="text-center">Clique nesse cartão para acessar as suas faturas.</p>
                            </Link>
                        </div>
                        <div className="flex items-center justify-center">
                            <Link to={`/financeiroIndex/${token}/relatorios`} className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-[15px] overflow-hidden bg-white hover:shadow-md">
                                <CalculatorIcon className="h-[30%] w-[30%] text-blue-600" />
                                <h2 className="text-xl font-bold text-center">Relatórios</h2>
                                <p className="text-center">Clique nesse cartão para acessar os relatórios solicitados</p>
                            </Link>
                        </div>
                        <div className="flex items-center justify-center">
                            <Link to={`/financeiroIndex/${token}/carnes`} className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-[15px] overflow-hidden bg-white hover:shadow-md">
                                <ArchiveBoxIcon className="h-[30%] w-[30%] text-blue-600" />
                                <h2 className="text-xl text-center font-bold">Carnês Leão</h2>
                                <p className="text-center">Clique nesse cartão para acessar os carnês gerados</p>
                            </Link>
                        </div>
                    </div>
                }
            </div>
            <div className="p-4 border mt-5">

                <div className="flex flex-col  justify-between">

                    <div className="flex flex-row  border ">
                        <div className="container mx-auto p-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="bg-white shadow-md rounded-lg">
                                    {
                                        states.dataFinanceiroGrafico.length > 0 &&
                                        <>
                                            <div className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                <div className="p-5">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0">
                                                            <BanknotesIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />
                                                        </div>
                                                        <div className="ml-5 w-0 flex-1">
                                                            <dl>
                                                                <dt className="truncate text-sm font-medium text-gray-500">Faturamento</dt>
                                                                <dd>
                                                                    <div className="text-lg font-medium text-gray-900">{(valorShow2).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Line data={data2} options={options} />
                                        </>
                                    }
                                </div>
                                <div className=" bg-white shadow-md rounded-lg">
                                    {
                                        states.dataFinanceiroGrafico.length > 0 &&
                                        <>
                                            <div className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                                <div className="p-5">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0">
                                                            <BookOpenIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />
                                                        </div>
                                                        <div className="ml-5 w-0 flex-1">
                                                            <dl>
                                                                <dt className="truncate text-sm font-medium text-gray-500">Número de consultas</dt>
                                                                <dd>
                                                                    <div className="text-lg font-medium text-gray-900">{valorShow1}</div>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Line data={data1} options={options} />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

            </div>


        </div >
    )

}



export default FinancieroIndex;