import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Context";
import { AuthContextApi } from "../../../Context/api";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import Table from "../../../Components/Table/Faturas";
import NavMenu from "../../../Components/NavMenu/Financeiro";
import { useParams } from "react-router-dom";
import moment from "moment";

const FaturasCliente = () => {
  const { states, setters } = useContext(AuthContext);
  const { checkLogin, getFaturasDataSistema, getFaturas } =
    useContext(AuthContextApi);

  const [query, setQuery] = useState("");
  const { token } = useParams();

  const primeiroLoad = async () => {
    await getFaturasDataSistema(token);
    await getFaturas(token);
    await checkLogin();
  };

  const loadUsuarios = async () => {
    await getFaturas(token);
  };

  const next = async () => {
    if (states.limiteBuscaFaturas) {
      if (states.offsetFaturas + states.limiteFaturas > 0) return;
      setters.setLimiteBuscaFaturas(true);
    }
    setters.setOffsetFaturas(states.offsetFaturas + states.limiteFaturas);
  };

  const previous = async () => {
    if (states.offsetFaturas - states.limiteFaturas < 0) return;
    if (states.limiteBuscaFaturas) {
      setters.setLimiteBuscaFaturas(false);
    }
    setters.setOffsetFaturas(states.offsetFaturas - states.limiteFaturas);
  };

  const dowloadArquivoFaturaFunction = async (data) => {
    const valorReais = (data?.valor / 100).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
    setters.setModalMsg(true);
    setters.setPerigoModalMsg(false);
    setters.setMsgModalMsg(
      `Deseja mesmo realizar o dowload da fatura no valor de ${valorReais} com o vencimento para ${moment(
        data.data_vencimento
      ).format("DD/MM/YYYY")}?`
    );
    setters.setDescModalMsg("Após a confirmação o arquivo será baixado.");
    setters.setTitleButtonModalMsg("Confirmar dowload");
    setters.setFunctionModalMsg(() => async () => {
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${data.pathArquivo}`;
      link.download = data.nomeArquivo;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setters.setModalMsg(false);
    });
  };

  const dowloadArquivoPagamentoFaturaFunction = async (data) => {
    const valorReais = (data?.valor / 100).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
    setters.setModalMsg(true);
    setters.setPerigoModalMsg(false);
    setters.setMsgModalMsg(
      `Deseja mesmo realizar o dowload do comprovante da fatura no valor de ${valorReais} com o vencimento para ${moment(
        data.data_vencimento
      ).format("DD/MM/YYYY")}?`
    );
    setters.setDescModalMsg("Após a confirmação o arquivo será baixado.");
    setters.setTitleButtonModalMsg("Confirmar dowload");
    setters.setFunctionModalMsg(() => async () => {
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${data.pathArquivoPagamento}`;
      link.download = data.nomeArquivoPagamento;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setters.setModalMsg(false);
    });
  };

  useEffect(() => {
    primeiroLoad();

    return () => {
      setQuery("");
      setters.setDataEstatisticaFaturas([]);
      setters.setDataFaturas([]);
      setters.setFaturas({});
      setters.setOffsetFaturas(0);
      setters.setLimiteBuscaFaturas(false);
    };
  }, []);

  useEffect(() => {
    if (states.dataFaturas?.length === 0) return;
    loadUsuarios();
  }, [states.offsetFaturas]);

  const headers = [
    "",
    "Nome",
    "CPF/CNPJ",
    "Data de Vencimento",
    "Método de pagamento",
    "Desconto",
    "Multa",
    "Valor",
    "Status",
    "Data de Criação",
    "Data de Atualização",
  ];
  return (
    <>
      <div className="flex flex-col">
        <NavMenu />
        <div className="flex-1 p-4 border rounded-[10px]  hover:shadow-md">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Faturas
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Informações das faturas geradas
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
          {states.dataEstatisticaFaturas?.length > 0 &&
            states.dataEstatisticaFaturas.map((card, index) => (
              <div
                key={card.title}
                className={
                  card.value > 0 && index === -1
                    ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                    : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                }
              >
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      {card.value > 0 && index === -1 ? (
                        <InformationCircleIcon
                          className="w-6 h-6 text-red-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <InformationCircleIcon
                          className="w-6 h-6 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        {card.value > 0 && index === -1 ? (
                          <dt className="truncate text-sm font-medium text-red-500">
                            {card.title}
                          </dt>
                        ) : (
                          <dt className="truncate text-sm font-medium text-gray-500">
                            {card.title}
                          </dt>
                        )}

                        <dd>
                          {card.value > 0 && index === -1 ? (
                            <div className="text-lg font-medium text-red-500">
                              {card.value}
                            </div>
                          ) : (
                            <div className="text-lg font-medium text-gray-900">
                              {card.value}
                            </div>
                          )}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {states.dataFaturas?.length > 0 ? (
          <>
            <div className=" p-4 border hover:shadow-md mt-5">
              {states.dataFaturas?.length > 0 && (
                <Table
                  title="Faturas geradas"
                  description="Registro das faturas geradas cadastrados"
                  next={next}
                  previous={previous}
                  states={states}
                  setters={setters}
                  headers={headers}
                  infoTable={{
                    offset: states.offsetFaturas,
                    limit: states.limiteFaturas,
                  }}
                  dataTable={states.dataFaturas}
                  query={query}
                  dowload={dowloadArquivoFaturaFunction}
                  dowloadPagamento={dowloadArquivoPagamentoFaturaFunction}
                />
              )}
            </div>
          </>
        ) : (
          <div className="flex-1 p-12 border  hover:shadow-md mt-5">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Sem registro de faturas
            </h1>
          </div>
        )}
      </div>
    </>
  );
};

export default FaturasCliente;
