import { createContext, useContext } from "react";
import { AuthContext } from ".";
import { toast } from 'react-toastify';
import { conection } from '../utils/index'
import moment from 'moment';

export const AuthContextApi = createContext();


const AuthProvider = ({ children }) => {

    const { states, setters } = useContext(AuthContext);
    const axiosAuth = conection.auth();
    const axiosNoAuth = conection.noAuth();


    const checkConfigCliente = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosNoAuth.get(`/website/checkCliente/${token}`)
            setters.setErrorAcessoCliente(false)
            if (response.status === 200) {
                localStorage.setItem('clienteConfig', JSON.stringify(response.data.data))
                setters.setClienteConfig(response.data.data)
                setters.setClientePossuiConfig(true)
            } else {
                const haveLocalStorage = localStorage.getItem('clienteConfig')
                if (haveLocalStorage) {
                    localStorage.removeItem('clienteConfig')
                }
            }
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            if (err.response.status !== 402) {
                const haveLocalStorage = localStorage.getItem('clienteConfig')
                if (haveLocalStorage) {
                    localStorage.removeItem('clienteConfig')
                }
                setters.setClienteConfig({})
                setters.setClientePossuiConfig(false)
            } else {
                localStorage.setItem('clienteConfig', JSON.stringify(err.response.data.data))
                setters.setClienteConfig(err.response.data.data)
                setters.setClientePossuiConfig(true)
            }
            setters.setIsLoading(false)
            setters.setErrorAcessoCliente(true)
            setters.setMsgErrorAcessoCliente(err.response.data.msg)
            return false;
        }
    }

    const login = async (data) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosNoAuth.post('/user/login/cliente', data)
            localStorage.setItem('token', response.data.data.token)
            localStorage.setItem('permisssion', response.data.data.permissao)
            localStorage.setItem('usuario', response.data.data.user)
            localStorage.setItem('nome', response.data.data.nome)
            localStorage.setItem('iamPrestador', response.data.data.iamPrestador)
            localStorage.setItem('empresa', response.data.data.empresa)
            localStorage.setItem('areaCliente', response.data.data.haveAreaCliente)
            localStorage.setItem('conversaCliente', response.data.data.haveConversa)
            localStorage.setItem('financeiro', response.data.data.haveFinaceiro)
            localStorage.setItem('haveAgenda', response.data.data.haveAgenda)
            if (response.data.data.admin) {
                toast.success(response.data.msg)
                if (response.data.data.haveConfig === false || response.data.data.haveCategoria === false || response.data.data.havePrestador === false
                    || response.data.data.haveContabilidadeConfig === false || response.data.data.haveServicos === false) {
                    localStorage.setItem('haveConfig', response.data.data.haveConfig)
                    localStorage.setItem('havePrestador', response.data.data.havePrestador)
                    localStorage.setItem('haveCategoria', response.data.data.haveCategoria)
                    localStorage.setItem('haveContabilidadeConfig', response.data.data.haveContabilidadeConfig)
                    localStorage.setItem('haveServicos', response.data.data.haveServicos)
                    localStorage.setItem('perfil', response.data.data.perfil_id)
                    setters.setSigned(true)
                    setters.setIsLoading(false)
                    return { status: true, complete: false, prontuario: response.data.data.prontuario }
                } else {
                    await checkConfigCliente(data.token)
                    setters.setSigned(true)
                    setters.setIsLoading(false)
                    return { status: true, complete: true, prontuario: response.data.data.prontuario }
                }
            }
            await checkConfigCliente(data.token)
            setters.setSigned(true)
            setters.setIsLoading(false)
            toast.success(response.data.msg)
            return { status: true, complete: true, prontuario: response.data.data.prontuario }
        } catch (err) {
            console.log(err)
            if (err.response.data.danger) {
                console.log(err.response.data.msg)
                setters.setClienteConfig({})
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
            }
            setters.setIsLoading(false)
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const checkLogin = async () => {
        try {
            const header = {
                headers: {
                    'authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
            await axiosAuth.get('/user/check', header)
            return true;
        } catch (err) {
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
            } else {
                toast.error(err.response.data.msg)
            }
        }
    }


    const logout = async () => {
        setters.setIsLoading(true)
        try {
            localStorage.removeItem('token')
            localStorage.removeItem('conversaCliente')
            localStorage.removeItem('areaCliente')
            localStorage.removeItem('permisssion')
            localStorage.removeItem('usuario')
            localStorage.removeItem('nome')
            localStorage.removeItem('iamPrestador')
            localStorage.removeItem('empresa')
            localStorage.removeItem('haveConfig')
            localStorage.removeItem('havePrestador')
            localStorage.removeItem('haveCategoria')
            localStorage.removeItem('haveContabilidadeConfig')
            localStorage.removeItem('haveServicos')
            localStorage.removeItem('perfil')
            localStorage.removeItem('financeiro')
            await axiosAuth.get('/user/logOut')
            setters.setSigned(false)
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            return false;
        }
    }


    //#region  Configuraccoes do cliente



    const postConfigCliente = async (data, token) => {
        setters.setIsLoading(true)
        try {
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            await axiosAuth.post(`/configuracoes/cliente`, data, header)
            setters.setIsLoading(false)
            toast.success('Configuração salva com sucesso')
            await checkConfigCliente(token)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putConfigCliente = async (data, token) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/cliente/configuracoes`, data)
            setters.setIsLoading(false)
            toast.success('Configuração salva com sucesso')
            await checkConfigCliente(token)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putConfigClienteForm = async (data, token) => {
        setters.setIsLoading(true)
        try {
            const header = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            await axiosAuth.put(`/cliente/configuracoes`, data, header)
            setters.setIsLoading(false)
            toast.success('Configuração salva com sucesso')
            await checkConfigCliente(token)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion


    //#region Categoria e Serviço

    const postConfigCategoriaServico = async (data, token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.post(`/categoria/servico/cliente`, data)
            setters.setCategoriaServico(response.data.data)
            setters.setIsLoading(false)
            toast.success('Categoria e Serviço cadastrado com sucesso')
            await checkConfigCliente(token)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const getConfigCategoriaServico = async (token) => {
        setters.setIsLoading(true)
        try {
            const headers = {
                headers: {
                    'authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
            const response = await axiosAuth.get(`/categoria/servico/cliente`, headers)
            Object.keys(states.categoriaServico).length === 0 && toast.success('Categoria e Serviço cadastrado com sucesso')
            setters.setCategoriaServico(response.data.data)
            setters.setIsLoading(false)
            await checkConfigCliente(token)
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            // toast.error(err.response.data.msg)
            return false;
        }
    }


    //#endregion

    //#region Configuração e Contabilidade

    const postConfigContabilidade = async (data, token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.post(`/configuracaoContabilidade/cliente`, data)
            setters.setConfiguracaoContabilidade(response.data.data)
            setters.setIsLoading(false)
            toast.success('Configuração de contabilidade cadastrado com sucesso')
            await checkConfigCliente(token)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }


    const getConfigContabilidade = async (token) => {
        setters.setIsLoading(true)
        try {
            const headers = {
                headers: {
                    'authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
            const response = await axiosAuth.get(`/cliente/configuracaoContabilidade`, headers)
            Object.keys(states.configuracaoContabilidade).length === 0 && toast.success('Configuração de contabilidade cadastrado com sucesso')
            setters.setConfiguracaoContabilidade(response.data.data)
            setters.setIsLoading(false)
            await checkConfigCliente(token)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }


    const putConfigContabilidade = async (data, token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.put(`/cliente/configuracaoContabilidade`, data)
            setters.setConfiguracaoContabilidade(response.data.data)
            setters.setIsLoading(false)
            toast.success('Configuração de contabilidade atualizada com sucesso')
            await getConfigContabilidade(token)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion

    //#region Configurações do negócio

    /*
    
     const [configuracaoInformacoes, setConfiguracaoInformacoes] = useState({})
    const [modalConfiguracaoInformacoes, setModalConfiguracaoInformacoes] = useState(false)
    */


    const getConfigNegocio = async () => {
        setters.setIsLoading(true)
        try {
            const headers = {
                headers: {
                    'authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
            const response = await axiosAuth.get(`/cliente`, headers)
            Object.keys(states.configuracaoInformacoes).length === 0 && toast.success('Dados do negócio carregados com sucesso')
            setters.setConfiguracaoInformacoes(response.data.data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }


    const putConfigNegocio = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/cliente`, data)
            setters.setIsLoading(false)
            await getConfigNegocio()
            toast.success('Configuração de contabilidade atualizada com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion




    //#region faturasClientes


    const getFaturasDataSistema = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/faturas/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaFaturas(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)

        }
    }

    const getFaturas = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/faturas/${token}/getWithLimit/${states.limiteFaturas}&${states.offsetFaturas}`)
            setters.setIsLoading(false)
            setters.setDataFaturas(response.data.data)
            if (states.dataFaturas.length === 0) toast.success('Faturas carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaFaturas(true)
                if (states.offsetFaturas > 0) {
                    setters.setOffsetFaturas(states.offsetFaturas - states.limiteFaturas)
                }
                if (states.offsetFaturas < 0) {
                    setters.setOffsetCarneLeaoCliente(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    //#endregion



    const getCarneLeaoDataSistema = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/contabilidadeCarneLeao/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaCarneLeao(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getCarneLeao = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/contabilidadeCarneLeao/cliente/getWithLimit/${states.limiteCarneLeao}&${states.offsetCarneLeao}`)
            setters.setIsLoading(false)
            setters.setDataCarneLeao(response.data.data)
            if (states.dataCarneLeao.length === 0) toast.success('Comprovantes carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaCarneLeao(true)
                if (states.offsetCarneLeao > 0) {
                    setters.setOffsetCarneLeao(states.offsetCarneLeao - states.limiteCarneLeao)
                }
                if (states.offsetCarneLeao < 0) {
                    setters.setOffsetCarneLeao(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const envioCarneLeao = async (data) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.post(`/contabilidadeCarneLeao/cliente/envioContador`, data)
            //Caso seja 201, tem que confirmar ao reenvio.
            if (response.status === 201) {
                setters.setIsLoading(false)
                return { status: true, newSend: true }
            }

            setters.setIsLoading(false)
            toast.success('Carnê leão enviados com sucesso')
            await getCarneLeao()
            return { status: true, newSend: false };
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return { status: false };
        }
    }

    //#endregion

    //#region Usuários

    const getUsuariosDataSistema = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/usuarios/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaUsuarios(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getUsuarios = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/user/getWithLimit/${states.limiteUsuarios}&${states.offsetUsuarios}`)
            setters.setIsLoading(false)
            setters.setDataUsuarios(response.data.data)
            if (states.dataUsuarios.length === 0) toast.success('Usuarios carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaUsuarios(true)
                if (states.offsetUsuarios > 0) {
                    setters.setOffsetUsuarios(states.offsetUsuarios - states.limiteUsuarios)
                }
                if (states.offsetUsuarios < 0) {
                    setters.setOffsetUsuarios(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getUsuariosQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/user/${query}/getWithLimit/${states.limiteUsuarios}&${states.offsetUsuarios}`)
            setters.setIsLoading(false)
            setters.setDataUsuarios(response.data.data)
            if (states.dataUsuarios.length === 0) toast.success('Usuarios carregados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaUsuarios(true)
                if (states.offsetUsuarios > 0) {
                    setters.setOffsetUsuarios(states.offsetUsuarios - states.limiteUsuarios)
                }
                if (states.offsetUsuarios < 0) {
                    setters.setOffsetUsuarios(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const postUsuarios = async (data, token) => {
        setters.setOffsetUsuarios(0)
        setters.setIsLoading(true)
        try {
            await axiosAuth.post('/cliente/user/create', data)
            setters.setIsLoading(false)
            toast.success('Usuário cadastrado com sucesso')
            await getUsuariosDataSistema(token)
            await getUsuarios()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putUsuarios = async (data) => {
        setters.setIsLoading(true)
        setters.setOffsetUsuarios(0)
        try {
            await axiosAuth.put(`/cliente/user/${states.Usuarios.id}`, data)
            setters.setIsLoading(false)
            setters.setUsuarios({})
            toast.success('Usuário editado com sucesso')
            await getUsuarios()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const blockUnlockUsuarios = async (id, status) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/user/cliente/blockUnlock/${id}`, {})
            setters.setIsLoading(false)
            setters.setUsuarios({})
            const msg = status ? 'Usuário desbloqueado com sucesso' : 'Usuário bloqueado com sucesso'
            toast.success(msg)
            await getUsuarios()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion

    //#region Recuperação de Senha

    const getRecuperacaoDeSenhaDataSistema = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/recuperacaoSenha/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaRecuperacaoSenha(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getRecuperacaoSenha = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/userForgot/getWithLimit/${states.limiteRecuperacaoSenha}&${states.offsetRecuperacaoSenha}`)
            setters.setIsLoading(false)
            setters.setDataRecuperacaoSenha(response.data.data)
            if (states.dataRecuperacaoSenha.length === 0) toast.success('Recuperações de senhas carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaRecuperacaoSenha(true)
                if (states.offsetRecuperacaoSenha > 0) {
                    setters.setOffsetRecuperacaoSenha(states.offsetRecuperacaoSenha - states.limiteRecuperacaoSenha)
                }
                if (states.offsetRecuperacaoSenha < 0) {
                    setters.setOffsetRecuperacaoSenha(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getRecuperacaoSenhaQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/userForgot/cliente/${query}/getWithLimit/${states.limiteRecuperacaoSenha}&${states.offsetRecuperacaoSenha}`)
            setters.setIsLoading(false)
            setters.setDataRecuperacaoSenha(response.data.data)
            if (states.dataRecuperacaoSenha.length === 0) toast.success('Recuperações de senhas carregados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaRecuperacaoSenha(true)
                if (states.offsetRecuperacaoSenha > 0) {
                    setters.setOffsetRecuperacaoSenha(states.offsetRecuperacaoSenha - states.limiteRecuperacaoSenha)
                }
                if (states.offsetRecuperacaoSenha < 0) {
                    setters.setOffsetRecuperacaoSenha(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    //#endregion


    //#region CategoriasServico cliente

    const getCategoriasDataSistema = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/categoria/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaCategorias(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getCategorias = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/categoria/cliente/getWithLimit/${states.limiteCategorias}&${states.offsetCategorias}`)
            setters.setIsLoading(false)
            setters.setDataCategorias(response.data.data)
            if (states.dataCategorias.length === 0) toast.success('Categorias de serviços carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaCategorias(true)
                if (states.offsetCategorias > 0) {
                    setters.setOffsetCategorias(states.offsetCategorias - states.limiteCategorias)
                }
                if (states.offsetCategorias < 0) {
                    setters.setOffsetCategorias(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getCategoriasQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/categoria/cliente/getWithLimit/${states.limiteCategorias}&${states.offsetCategorias}/${query}`)
            setters.setIsLoading(false)
            setters.setDataCategorias(response.data.data)
            if (states.dataCategorias.length === 0) toast.success('Categorias de serviço carregados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaCategorias(true)
                if (states.offsetCategorias > 0) {
                    setters.setOffsetCategorias(states.offsetCategorias - states.limiteCategorias)
                }
                if (states.offsetCategorias < 0) {
                    setters.setOffsetCategorias(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const postCategoria = async (data) => {
        setters.setIsLoading(true)
        setters.setOffsetCategorias(0)
        try {
            await axiosAuth.post('/categoria/cliente', data)
            setters.setIsLoading(false)
            toast.success('Categoria cadastrada com sucesso')
            await getCategorias()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putCategoria = async (data) => {
        setters.setIsLoading(true)
        setters.setOffsetCategorias(0)
        try {
            await axiosAuth.put(`/categoria/cliente/${states.Categorias.id}`, data)
            setters.setCategorias({})
            setters.setIsLoading(false)
            toast.success('Categoria cadastrada com sucesso')
            await getCategorias()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putCategoriaStatus = async (data) => {
        setters.setIsLoading(true)
        setters.setOffsetCategorias(0)
        try {
            await axiosAuth.put(`/categoria/cliente/${data.id}`, data)
            setters.setCategorias({})
            setters.setIsLoading(false)
            toast.success(`Categoria ${data.status === 1 ? 'ativada' : 'desativada'} com sucesso`)
            await getCategorias()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const deleteCategoria = async (data) => {
        setters.setIsLoading(true)
        setters.setOffsetCategorias(0)
        try {
            await axiosAuth.delete(`/categoria/cliente/${data.id}`)
            setters.setCategorias({})
            setters.setIsLoading(false)
            toast.success(`Categoria deletada com sucesso!`)
            await getCategorias()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }


    const getCategoriasForm = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/categoria/cliente/getWithLimit/${states.limiteCategorias}&${states.offsetCategorias}`)
            setters.setIsLoading(false)
            if (states.dataCategoriasForm.length === 0) {
                setters.setDataCategoriasForm(response.data.data)
            } else {
                const dataOutState = response.data.data.filter(item => !states.dataCategoriasForm.some(other => item.id === other.id))
                if (dataOutState.length > 0) {
                    setters.setDataCategoriasForm([...states.dataCategoriasForm, ...dataOutState])
                } else {
                    setters.setLimiteBuscaCategorias(true)
                }
            }
            if (states.dataCategoriasForm.length > 0) toast.success('Categorias carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaCategorias(true)
                if (states.offsetCategorias > 0) {
                    setters.setOffsetCategorias(states.offsetCategorias - states.limiteCategorias)
                }
                if (states.offsetCategorias < 0) {
                    setters.setOffsetCategorias(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }




    //#endregion


    //#region Servico 

    const getServicosDataSistema = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/servico/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaServicos(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getServicos = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/servicos/cliente/getWithLimit/${states.limiteServicos}&${states.offsetServicos}`)
            setters.setIsLoading(false)
            setters.setDataServicos(response.data.data)
            if (states.dataServicos.length === 0) toast.success('Serviços carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaServicos(true)
                if (states.offsetServicos > 0) {
                    setters.setOffsetServicos(states.offsetServicos - states.limiteServicos)
                }
                if (states.offsetServicos < 0) {
                    setters.setOffsetServicos(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getServicosQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/servicos/cliente/getWithLimit/${states.limiteServicos}&${states.offsetServicos}/${query}`)
            setters.setIsLoading(false)
            setters.setDataServicos(response.data.data)
            if (states.dataServicos.length === 0) toast.success('Serviços carregados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaServicos(true)
                if (states.offsetServicos > 0) {
                    setters.setOffsetServicos(states.offsetServicos - states.limiteServicos)
                }
                if (states.offsetServicos < 0) {
                    setters.setOffsetServicos(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const postServicos = async (data) => {
        setters.setIsLoading(true)
        setters.setOffsetServicos(0)
        try {
            await axiosAuth.post(`/servicos/cliente`, data)
            setters.setServicos({})
            setters.setIsLoading(false)
            toast.success('Serviço cadsatrado com sucesso')
            await getServicos()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putServicos = async (data) => {
        setters.setIsLoading(true)
        setters.setOffsetServicos(0)
        try {
            await axiosAuth.put(`/servicos/cliente/${states.Servicos.id}`, data)
            setters.setServicos({})
            setters.setIsLoading(false)
            toast.success('Serviço editado com sucesso')
            await getServicos()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putServicoStatus = async (data) => {
        setters.setIsLoading(true)
        setters.setOffsetServicos(0)
        try {
            await axiosAuth.put(`/servicos/cliente/${data.id}`, data)
            setters.setServicos({})
            setters.setIsLoading(false)
            toast.success(`Serviço ${data.status === 1 ? 'ativado' : 'desativado'} com sucesso`)
            await getServicos()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const deleteServico = async (data) => {
        setters.setIsLoading(true)
        setters.setOffsetServicos(0)
        try {
            await axiosAuth.delete(`/servicos/cliente/${data.id}`)
            setters.setServicos({})
            setters.setIsLoading(false)
            toast.success(`Serviço deletada com sucesso!`)
            await getServicos()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }


    //#endregion


    //#region Prestador

    const getPrestadoresDataSistema = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/prestador/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaPrestadores(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getPrestadores = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/prestador/cliente/getWithLimit/${states.limitePrestadores}&${states.offsetPrestadores}`)
            setters.setIsLoading(false)
            setters.setDataPrestadores(response.data.data)
            if (states.dataPrestadores.length === 0) toast.success('Prestadores carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaPrestadores(true)
                if (states.offsetPrestadores > 0) {
                    setters.setOffsetPrestadores(states.offsetPrestadores - states.limitePrestadores)
                }
                if (states.offsetPrestadores < 0) {
                    setters.setOffsetPrestadores(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getPrestadoresForm = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/prestador/cliente/getWithLimit/${states.limitePrestadores}&${states.offsetPrestadores}`)
            setters.setIsLoading(false)
            if (states.dataPrestadores.length === 0) {
                setters.setDataPrestadores(response.data.data)
            } else {
                const dataOutState = response.data.data.filter(item => !states.dataPrestadores.some(other => item.id === other.id))
                if (dataOutState.length > 0) {
                    toast.success('Prestadores carregados com sucesso')
                    setters.setDataPrestadores([...states.dataPrestadores, ...dataOutState])
                } else {
                    setters.setLimiteBuscaPrestadores(true)

                }
            }
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaPrestadores(true)
                if (states.offsetPrestadores > 0) {
                    setters.setOffsetPrestadores(states.offsetPrestadores - states.limitePrestadores)
                }
                if (states.offsetPrestadores < 0) {
                    setters.setOffsetPrestadores(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getPrestadorMeusDados = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/prestador/cliente/prestador`)
            setters.setIsLoading(false)
            setters.setPrestadores(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaPrestadores(true)
                if (states.offsetPrestadores > 0) {
                    setters.setOffsetPrestadores(states.offsetPrestadores - states.limitePrestadores)
                }
                if (states.offsetPrestadores < 0) {
                    setters.setOffsetPrestadores(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getPrestadoresQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/prestador/cliente/getWithLimit/${states.limitePrestadores}&${states.offsetPrestadores}/${query}`)
            setters.setIsLoading(false)
            setters.setDataPrestadores(response.data.data)
            if (states.dataPrestadores.length === 0) toast.success('Prestadores carregados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaPrestadores(true)
                if (states.offsetPrestadores > 0) {
                    setters.setOffsetPrestadores(states.offsetPrestadores - states.limitePrestadores)
                }
                if (states.offsetPrestadores < 0) {
                    setters.setOffsetPrestadores(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const postConfigPrestadorMyProfile = async (data, token) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/prestador`, data)
            setters.setIsLoading(false)
            toast.success('Perfil de prestador cadastrado com sucesso')
            await checkConfigCliente(token)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const postPrestadorNewProfile = async (data, token) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/prestador/cliente`, data)
            setters.setIsLoading(false)
            toast.success('Prestador cadastrado com sucesso')
            await checkConfigCliente(token)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const postPrestador = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/prestador/cliente`, data)
            setters.setIsLoading(false)
            toast.success('Prestador cadastrado com sucesso')
            await getPrestadores()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putPrestador = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/prestador/cliente/${states.Prestadores.id}`, data)
            setters.setIsLoading(false)
            setters.setPrestadores({})
            toast.success('Prestador editado com sucesso')
            await getPrestadores()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putPrestadorBloqueioDesbloqueio = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/prestador/cliente/lockUnlock/${data.id}`, {})
            setters.setIsLoading(false)
            setters.setPrestadores({})
            toast.success(`Psicólogo ${data.bloqueado === 1 ? 'ativado' : 'desativado'} com sucesso`)
            await getPrestadores()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion

    //#region Execucao de serviço

    const getExecucaoServicoDataSistema = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/execucaoServico/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaExecucoesServico(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getExecucaoServico = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/execucaoServico/cliente/getWithLimit/${states.limiteExecucoesServico}&${states.offsetExecucoesServico}`)
            setters.setIsLoading(false)
            setters.setDataExecucoesServico(response.data.data)
            if (states.dataExecucoesServico.length === 0) toast.success('Execuções de serviço carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaExecucoesServico(true)
                if (states.offsetExecucoesServico > 0) {
                    setters.setOffsetExecucoesServico(states.offsetExecucoesServico - states.limiteExecucoesServico)
                }
                if (states.offsetExecucoesServico < 0) {
                    setters.setOffsetExecucoesServico(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getExecucaoServicoQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/execucaoServico/cliente/getWithLimit/${states.limiteExecucoesServico}&${states.offsetExecucoesServico}/${query}`)
            setters.setIsLoading(false)
            setters.setDataExecucoesServico(response.data.data)
            if (states.dataExecucoesServico.length === 0) toast.success('Execuções de serviço carregados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaExecucoesServico(true)
                if (states.offsetExecucoesServico > 0) {
                    setters.setOffsetExecucoesServico(states.offsetExecucoesServico - states.limiteExecucoesServico)
                }
                if (states.offsetExecucoesServico < 0) {
                    setters.setOffsetExecucoesServico(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    //#endregion

    //#region Serviço

    const getExecucaoServicoDataSistemaPrestador = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/prestador/execucaoServico/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaExecucoesServico(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getExecucaoServicoPrestador = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/execucaoServico/cliente/getWithLimit/prestador/${states.limiteExecucoesServico}&${states.offsetExecucoesServico}`)
            setters.setIsLoading(false)
            setters.setDataExecucoesServico(response.data.data)
            if (states.dataExecucoesServico.length === 0) toast.success('Execuções de serviço carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaExecucoesServico(true)
                if (states.offsetExecucoesServico > 0) {
                    setters.setOffsetExecucoesServico(states.offsetExecucoesServico - states.limiteExecucoesServico)
                }
                if (states.offsetExecucoesServico < 0) {
                    setters.setOffsetExecucoesServico(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getExecucaoServicoQueryPrestador = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/execucaoServico/cliente/getWithLimit/prestador/${states.limiteExecucoesServico}&${states.offsetExecucoesServico}/${query}`)
            setters.setIsLoading(false)
            setters.setDataExecucoesServico(response.data.data)
            if (states.dataExecucoesServico.length === 0) toast.success('Execuções de serviço carregados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaExecucoesServico(true)
                if (states.offsetExecucoesServico > 0) {
                    setters.setOffsetExecucoesServico(states.offsetExecucoesServico - states.limiteExecucoesServico)
                }
                if (states.offsetExecucoesServico < 0) {
                    setters.setOffsetExecucoesServico(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    //#endregion

    //#region Execucao de serviço


    const getPacientesDataSistema = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/clienteSecundario/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaPacientes(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getPacientes = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clienteSecundario/cliente/getWithLimit/${states.limitePacientes}&${states.offsetPacientes}`)
            setters.setIsLoading(false)
            setters.setDataPacientes(response.data.data)
            if (states.dataPacientes.length === 0) toast.success('Pacientes carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaPacientes(true)
                if (states.offsetPacientes > 0) {
                    setters.setOffsetPacientes(states.offsetPacientes - states.limitePacientes)
                }
                if (states.offsetPacientes < 0) {
                    setters.setOffsetPacientes(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getPacientesCpf = async (cpf) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clienteSecundario/cliente/cpf/prestador/${cpf}`)
            setters.setIsLoading(false)
            setters.setPaciente(response.data.data)
            toast.success('Paciente carregado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaPacientes(true)
                if (states.offsetPacientes > 0) {
                    setters.setOffsetPacientes(states.offsetPacientes - states.limitePacientes)
                }
                if (states.offsetPacientes < 0) {
                    setters.setOffsetPacientes(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const getPacientesClienteCpf = async (cpf) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clienteSecundario/cliente/cpf/cliente/${cpf}`)
            setters.setIsLoading(false)
            setters.setPaciente(response.data.data)
            toast.success('Paciente carregado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaPacientes(true)
                if (states.offsetPacientes > 0) {
                    setters.setOffsetPacientes(states.offsetPacientes - states.limitePacientes)
                }
                if (states.offsetPacientes < 0) {
                    setters.setOffsetPacientes(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const getPacientesQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clienteSecundario/cliente/getWithLimit/${states.limitePacientes}&${states.offsetPacientes}/${query}`)
            setters.setIsLoading(false)
            setters.setDataPacientes(response.data.data)
            if (states.dataPacientes.length === 0) toast.success('Pacientes carregados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaPacientes(true)
                if (states.offsetPacientes > 0) {
                    setters.setOffsetPacientes(states.offsetPacientes - states.limitePacientes)
                }
                if (states.offsetPacientes < 0) {
                    setters.setOffsetPacientes(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const getMeusPacientesDataSistema = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/prestador/clienteSecundario/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaPacientes(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getMeusPacientes = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clienteSecundario/cliente/prestador/getWithLimit/${states.limitePacientes}&${states.offsetPacientes}`)
            setters.setIsLoading(false)
            setters.setDataPacientes(response.data.data)
            if (states.dataPacientes.length === 0) toast.success('Pacientes carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaPacientes(true)
                if (states.offsetPacientes > 0) {
                    setters.setOffsetPacientes(states.offsetPacientes - states.limitePacientes)
                }
                if (states.offsetPacientes < 0) {
                    setters.setOffsetPacientes(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getMeusPacientesQuery = async (query) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clienteSecundario/cliente/prestador/getWithLimit/${states.limitePacientes}&${states.offsetPacientes}/${query}`)
            setters.setIsLoading(false)
            setters.setDataPacientes(response.data.data)
            if (states.dataPacientes.length === 0) toast.success('Pacientes carregados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaPacientes(true)
                if (states.offsetPacientes > 0) {
                    setters.setOffsetPacientes(states.offsetPacientes - states.limitePacientes)
                }
                if (states.offsetPacientes < 0) {
                    setters.setOffsetPacientes(0)
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    const postPaciente = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/clienteSecundario/cliente`, data)
            setters.setIsLoading(false)
            toast.success('Paciente cadastrado com sucesso')
            await getPacientes()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putPaciente = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/clienteSecundario/cliente/${states.Pacientes.id}`, data)
            setters.setIsLoading(false)
            setters.setPacientes({})
            toast.success('Prestador editado com sucesso')
            await getPacientes()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const getPacientePrestador = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clienteSecundario/cliente/prestadores/${id}/getWithLimit/${states.limitePacientePrestador}&${states.offsetPacientePrestador}`)
            setters.setIsLoading(false)
            if (states.dataPacientePrestador.length === 0) {
                setters.setDataPacientePrestador(response.data.data)
            } else {
                const dataOutState = response.data.data.filter(item => !states.dataPacientePrestador.some(other => item.id === other.id))
                if (dataOutState.length > 0) {
                    setters.setDataPacientePrestador([...states.dataPacientePrestador, ...dataOutState])
                } else {
                    setters.setLimiteBuscaPacientePrestador(true)
                }
            }
            if (states.dataPacientePrestador.length === 0) toast.success('Psicólogos vinculados carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaPacientePrestador(true)
                if (states.offsetPacientePrestador > 0) {
                    setters.setOffsetPacientePrestador(states.offsetPacientePrestador - states.limitePacientePrestador)
                }
                if (states.offsetPacientePrestador < 0) {
                    setters.setOffsetPacientePrestador(0)
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    //#endregion

    //#region Paciente 

    const getPacienteDataSistema = async (token, id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/clienteSecundario/${token}/${id}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaPaciente(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getPacienteData = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clienteSecundario/cliente/${id}`)
            setters.setIsLoading(false)
            setters.setPaciente(response.data.data)
            if (states.Pacientes.length === 0) toast.success('Paciente carregado com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
        }
    }

    const getPacienteExecucoes = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clienteSecundario/cliente/execucoes/${id}/getWithLimit/${states.limiteExecucoesServicoPaciente}&${states.offsetExecucoesServicoPaciente}`)
            setters.setIsLoading(false)
            setters.setDataExecucoesServicoPaciente(response.data.data)
            if (states.dataExecucoesServicoPaciente.length === 0) toast.success('Atendimentos carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaExecucoesServicoPaciente(true)
                if (states.offsetExecucoesServicoPaciente > 0) {
                    setters.setOffsetExecucoesServicoPaciente(states.offsetExecucoesServicoPaciente - states.limiteExecucoesServicoPaciente)
                }
                if (states.offsetExecucoesServicoPaciente < 0) {
                    setters.setOffsetExecucoesServicoPaciente(0)
                }
                toast.error(err.response.data.msg)
            }
        }

    }

    const postPrestadorPaciente = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/clienteSecundario/cliente/adicionarPrestador`, data)
            setters.setIsLoading(false)
            toast.success('Paciente vinculado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const postRemoverPrestadorPaciente = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/clienteSecundario/cliente/removerPrestador`, data)
            setters.setIsLoading(false)
            toast.success('Paciente desvinculado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }



    //#endregion

    //#region Paciente prontuarios


    const getPacienteProntuariosData = async (id, prontuario) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clienteSecundario/cliente/prontuario/${id}/${prontuario}`)
            setters.setIsLoading(false)
            setters.setProntuariosPaciente(response.data.data)
            toast.success('Prontuário carregado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const getPacientProntuarios = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/clienteSecundario/cliente/prontuarios/${id}/getWithLimit/${states.limiteProntuariosPaciente}&${states.offsetProntuariosPaciente}`)
            setters.setIsLoading(false)
            setters.setDataProntuariosPaciente(response.data.data)
            if (states.dataProntuariosPaciente.length === 0) toast.success('Prontuários carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaProntuariosPaciente(true)
                if (states.offsetProntuariosPaciente > 0) {
                    setters.setOffsetProntuariosPaciente(states.offsetProntuariosPaciente - states.limiteProntuariosPaciente)
                }
                if (states.offsetProntuariosPaciente < 0) {
                    setters.setOffsetProntuariosPaciente(0)
                }
                toast.error(err.response.data.msg)
            }
        }

    }

    //#endregion

    //#region Conversas Pacientes

    const getPacientConversas = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/comunicacaoCliente/prestador/${id}/getWithLimit/${states.limiteConversasPaciente}&${states.offsetConversasPaciente}`)
            setters.setIsLoading(false)
            if (states.dataConversasPaciente.length === 0) {
                setters.setDataConversasPaciente(response.data.data)
            } else {
                const dataOutArr = response.data.data.filter((item) => {
                    return states.dataConversasPaciente.find((item2) => item2.id === item.id) === undefined
                })
                setters.setDataConversasPaciente([...states.dataConversasPaciente, ...dataOutArr])

            }
            if (states.dataConversasPaciente.length === 0) toast.success('Conversas carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaConversasPaciente(true)
                if (states.offsetConversasPaciente > 0) {
                    setters.setOffsetConversasPaciente(states.offsetConversasPaciente - states.limiteConversasPaciente)
                }
                if (states.offsetConversasPaciente < 0) {
                    setters.setOffsetConversasPaciente(0)
                }
                toast.error(err.response.data.msg)
            }
        }

    }

    const getPacientConversasLoad = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/comunicacaoCliente/prestador/${id}/getWithLimit/${states.limiteConversasPaciente}&${states.offsetConversasPaciente}`)
            setters.setIsLoading(false)
            setters.setDataConversasPaciente(response.data.data)
            if (states.dataConversasPaciente.length === 0) toast.success('Conversas carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaConversasPaciente(true)
                if (states.offsetConversasPaciente > 0) {
                    setters.setOffsetConversasPaciente(states.offsetConversasPaciente - states.limiteConversasPaciente)
                }
                if (states.offsetConversasPaciente < 0) {
                    setters.setOffsetConversasPaciente(0)
                }
                toast.error(err.response.data.msg)
            }
        }

    }

    const getPacientConversa = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/comunicacaoCliente/prestador/${id}`)
            setters.setIsLoading(false)
            setters.setConversaPaciente(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
        }

    }

    const getPacientConversaMensagens = async (id, paciente) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/comunicacaoConversa/prestador/getWithLimit/${paciente}/${id}/${states.limiteConversaPaciente}&${states.offsetConversaPaciente}`)
            setters.setIsLoading(false)
            if (states.dataConversaPaciente.length === 0) setters.setDataConversaPaciente(response.data.data)
            else {
                const dataOutArr = response.data.data.filter((item) => {
                    return states.dataConversaPaciente.find((item2) => item2.id === item.id) === undefined
                })
                setters.setDataConversaPaciente([...states.dataConversaPaciente, ...dataOutArr])
            }
            toast.success('Mensagens carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaConversaPaciente(true)
                if (states.offsetConversaPaciente > 0) {
                    setters.setOffsetConversaPaciente(states.offsetConversaPaciente - states.limiteConversaPaciente)
                }
                if (states.offsetConversaPaciente < 0) {
                    setters.setOffsetConversaPaciente(0)
                }
            }
        }

    }

    const postMensagemConversa = async (data, id, paciente) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/comunicacaoConversa/prestador`, data)
            setters.setIsLoading(false)
            toast.success('Mensagem enviada com sucesso')
            await getPacientConversaMensagens(id, paciente)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const encerrarConversa = async (id, paciente) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/comunicacaoCliente/prestador/encerar/${id}`)
            setters.setIsLoading(false)
            toast.success('Conversa encerrada com sucesso')
            await getPacientConversasLoad(paciente)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const excluirtConversa = async (id, paciente) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.delete(`/comunicacaoCliente/prestador/${id}`)
            setters.setIsLoading(false)
            toast.success('Conversa excluída com sucesso')
            await getPacientConversasLoad(paciente)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const addConversa = async (data, paciente) => {
        setters.setIsLoading(true)
        try {
            const req = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            }
            const response = await axiosAuth.post(`/comunicacaoCliente/prestador`, data, req)
            console.log(response.data.data)
            setters.setIsLoading(false)
            toast.success('Mensagem enviada com sucesso')
            await getPacientConversasLoad(paciente)
            return { status: true, data: response.data.data }
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return { status: false };
        }

    }

    //#endregion


    //#region Execucao de servico 



    const getServicosPrestadores = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/servicos/cliente/getWithLimit/${states.limiteCategoriaPrestador}&${states.offsetCategoriaPrestador}`)
            setters.setIsLoading(false)
            if (states.dataCategoriaPrestador.length === 0) setters.setDataCategoriaPrestador(response.data.data)
            else {
                const dataOutArr = response.data.data.filter((item) => {
                    return states.dataCategoriaPrestador.find((item2) => item2.id === item.id) === undefined
                })
                setters.setDataCategoriaPrestador([...states.dataCategoriaPrestador, ...dataOutArr])
            }
            if (states.dataCategoriaPrestador.length > 0) toast.success('Serviços carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            setters.setLimiteBuscaCategoriaPrestador(true)
            if (states.offsetCategoriaPrestador > 0) {
                setters.setOffsetCategoriaPrestador(states.offsetCategoriaPrestador - states.limiteCategoriaPrestador)
            }
            if (states.offsetCategoriaPrestador < 0) {
                setters.setOffsetCategoriaPrestador(0)
            }
            toast.error(err.response.data.msg)
        }
    }

    const getServicosPrestador = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/servicos/prestador/getWithLimit/${states.limiteCategoriaPrestador}&${states.offsetCategoriaPrestador}`)
            setters.setIsLoading(false)
            if (states.dataCategoriaPrestador.length === 0) setters.setDataCategoriaPrestador(response.data.data)
            else {
                const dataOutArr = response.data.data.filter((item) => {
                    return states.dataCategoriaPrestador.find((item2) => item2.id === item.id) === undefined
                })
                setters.setDataCategoriaPrestador([...states.dataCategoriaPrestador, ...dataOutArr])
            }
            if (states.dataCategoriaPrestador.length > 0) toast.success('Serviços carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            setters.setLimiteBuscaCategoriaPrestador(true)
            if (states.offsetCategoriaPrestador > 0) {
                setters.setOffsetCategoriaPrestador(states.offsetCategoriaPrestador - states.limiteCategoriaPrestador)
            }
            if (states.offsetCategoriaPrestador < 0) {
                setters.setOffsetCategoriaPrestador(0)
            }
            toast.error(err.response.data.msg)
        }
    }

    const iniciarServico = async (data) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.post(`/execucaoServico/prestador`, data)
            setters.setIsLoading(false)
            if (response.data.confirmCancel) {
                return { status: true, confirm: true, msg: response.data.msg };
            }
            toast.success('Serviço iniciado com sucesso')
            // await getExecucaoServico()
            return { status: true, confirm: false, prontuario: response.data.data.prontuario };
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return { status: false };
        }
    }

    const atualizarProntuario = async (data, id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.put(`/prontuario/prestador/servico/${id}`, data)
            setters.setIsLoading(false)
            setters.setProntuariosPaciente({ ...states.ProntuariosPaciente, ...response.data.data })
            toast.success('Prontuário atualizado com sucesso')
            return { status: true, data: response.data.data };
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return { status: false };
        }
    }

    const atualizarProntuarioEncerrado = async (data, id, paciente) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/prontuario/prestador/finalizado/${id}`, data)
            setters.setIsLoading(false)
            await getPacienteProntuariosData(paciente, id)
            toast.success('Prontuário atualizado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false
        }
    }

    const encerrarServico = async (id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/execucaoServico/prestador/finalizar/${id}`)
            setters.setIsLoading(false)
            toast.success('Serviço encerrado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion

    //#region Agenda 

    const getAgendaMonth = async (data) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.post(`/agenda/cliente/getMonth`, { data: data })
            setters.setIsLoading(false)
            setters.setDataAgenda(response.data.data)
            if (states.dataAgenda.length === 0) toast.success('Agenda carregada com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const getDayCompromissos = async (data) => {
        setters.setIsLoading(true)
        try {
            const body = {
                data: data,
                limit: states.limiteTarefas,
                offset: 0
            }
            const response = await axiosAuth.post(`/agenda/cliente/getDay`, body)
            setters.setIsLoading(false)
            if (response.data.noData) {
                setters.setLimiteBuscaTarefas(true)
                if (states.offsetTarefas > 0) {
                    setters.setOffsetTarefas(states.offsetTarefas - states.limiteTarefas)
                }
                if (states.offsetTarefas < 0) {
                    setters.setOffsetTarefas(0)
                }
                setters.setDataTarefas([])
                toast.info('Nenhum compromisso encontrado')
                return;
            }
            setters.setDataTarefas(response.data.data)
            toast.success('Compromissos carragados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const getDayCompromissosMore = async (data) => {
        setters.setIsLoading(true)
        try {
            const body = {
                data: data,
                limit: states.limiteTarefas,
                offset: states.offsetTarefas
            }
            const response = await axiosAuth.post(`/agenda/cliente/getDay`, body)
            setters.setIsLoading(false)
            if (response.data.noData) {
                setters.setLimiteBuscaTarefas(true)
                if (states.offsetTarefas > 0) {
                    setters.setOffsetTarefas(states.offsetTarefas - states.limiteTarefas)
                }
                if (states.offsetTarefas < 0) {
                    setters.setOffsetTarefas(0)
                }
                toast.info('Nenhum compromisso encontrado')
                return;
            }
            if (states.dataTarefas.length === 0) setters.setDataTarefas(response.data.data)
            else {
                const isSameDataInStates = response.data.data.filter((item) => {
                    return states.dataTarefas.find((item2) => moment(item2.data).isSame(item.data)) !== undefined
                })
                if (isSameDataInStates.length > 0) setters.setDataTarefas([...states.dataTarefas, ...isSameDataInStates])
                else setters.setDataTarefas(response.data.data)
            }
            toast.success('Compromissos carragados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const postCompromisso = async (data) => {
        setters.setIsLoading(true)
        try {
            //Confirmacao caso tenha um evento ja marcado
            await axiosAuth.post(`/agenda/cliente`, data)
            setters.setIsLoading(false)
            await getAgendaMonth(moment().format('YYYY-MM'))
            await getDayCompromissos(moment().format('YYYY-MM-DD'))
            toast.success('Compromisso criado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putCompromisso = async (data, id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/agenda/cliente/${id}`, data)
            setters.setIsLoading(false)
            await getAgendaMonth(moment().format('YYYY-MM'))
            await getDayCompromissos(moment().format('YYYY-MM-DD'))
            toast.success('Compromisso criado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const cancelCompromisso = async (id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.delete(`/agenda/cliente/cancelar/${id}`)
            setters.setIsLoading(false)
            await getAgendaMonth(moment().format('YYYY-MM'))
            await getDayCompromissos(moment().format('YYYY-MM-DD'))
            toast.success('Compromisso cancelado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const deletarCompromisso = async (id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.delete(`/agenda/cliente/${id}`)
            setters.setIsLoading(false)
            await getAgendaMonth(moment().format('YYYY-MM'))
            await getDayCompromissos(moment().format('YYYY-MM-DD'))
            toast.success('Compromisso deletado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    //#endregion

    //#region Agenda 

    const getMinhaAgendaMonth = async (data) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.post(`/agenda/cliente/prestador/getMonth`, { data: data })
            setters.setIsLoading(false)
            setters.setDataAgenda(response.data.data)
            if (states.dataAgenda.length === 0) toast.success('Agenda carregada com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const getMeuDayCompromissos = async (data) => {
        setters.setIsLoading(true)
        try {
            const body = {
                data: data,
                limit: states.limiteTarefas,
                offset: 0
            }
            const response = await axiosAuth.post(`/agenda/cliente/prestador/getDay`, body)
            setters.setIsLoading(false)
            if (response.data.noData) {
                setters.setLimiteBuscaTarefas(true)
                if (states.offsetTarefas > 0) {
                    setters.setOffsetTarefas(states.offsetTarefas - states.limiteTarefas)
                }
                if (states.offsetTarefas < 0) {
                    setters.setOffsetTarefas(0)
                }
                setters.setDataTarefas([])
                toast.info('Nenhum compromisso encontrado')
                return;
            }
            setters.setDataTarefas(response.data.data)
            toast.success('Compromissos carragados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const getMeuDayCompromissosMore = async (data) => {
        setters.setIsLoading(true)
        try {
            const body = {
                data: data,
                limit: states.limiteTarefas,
                offset: states.offsetTarefas
            }
            const response = await axiosAuth.post(`/agenda/cliente/prestador/getDay`, body)
            setters.setIsLoading(false)
            if (response.data.noData) {
                setters.setLimiteBuscaTarefas(true)
                if (states.offsetTarefas > 0) {
                    setters.setOffsetTarefas(states.offsetTarefas - states.limiteTarefas)
                }
                if (states.offsetTarefas < 0) {
                    setters.setOffsetTarefas(0)
                }
                toast.info('Nenhum compromisso encontrado')
                return;
            }
            if (states.dataTarefas.length === 0) setters.setDataTarefas(response.data.data)
            else {
                const isSameDataInStates = response.data.data.filter((item) => {
                    return states.dataTarefas.find((item2) => moment(item2.data).isSame(item.data)) !== undefined
                })
                if (isSameDataInStates.length > 0) setters.setDataTarefas([...states.dataTarefas, ...isSameDataInStates])
                else setters.setDataTarefas(response.data.data)
            }
            toast.success('Compromissos carragados com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const postMeuCompromisso = async (data) => {
        setters.setIsLoading(true)
        try {
            //Confirmacao caso tenha um evento ja marcado
            await axiosAuth.post(`/agenda/prestador`, data)
            setters.setIsLoading(false)
            await getMinhaAgendaMonth(moment().format('YYYY-MM'))
            await getMeuDayCompromissos(moment().format('YYYY-MM-DD'))
            toast.success('Compromisso criado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const putMeuCompromisso = async (data, id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/agenda/prestador/${id}`, data)
            setters.setIsLoading(false)
            await getMinhaAgendaMonth(moment().format('YYYY-MM'))
            await getMeuDayCompromissos(moment().format('YYYY-MM-DD'))
            toast.success('Compromisso criado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const cancelMeuCompromisso = async (id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.delete(`/agenda/prestador/cancelar/${id}`)
            setters.setIsLoading(false)
            await getMinhaAgendaMonth(moment().format('YYYY-MM'))
            await getMeuDayCompromissos(moment().format('YYYY-MM-DD'))
            toast.success('Compromisso cancelado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }

    const deletarMeuCompromisso = async (id) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.delete(`/agenda/prestador/${id}`)
            setters.setIsLoading(false)
            await getMinhaAgendaMonth(moment().format('YYYY-MM'))
            await getMeuDayCompromissos(moment().format('YYYY-MM-DD'))
            toast.success('Compromisso deletado com sucesso')
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return false;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return false;
        }
    }


    const iniciarMeuServico = async (data) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.post(`/execucaoServico/prestador/agenda`, data)
            setters.setIsLoading(false)
            if (response.data.confirmCancel) {
                return { status: true, confirm: true, msg: response.data.msg };
            }
            toast.success('Serviço iniciado com sucesso')
            return { status: true, confirm: false, prontuario: response.data.data.prontuario };
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error(err.response.data.msg)
                return;
            }
            if (err.response.status === 402) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                setters.setErrorAcessoCliente(true)
                setters.setIsLoading(false)
                setters.setMsgErrorAcessoCliente(err.response.data.msg)
                return false;
            }
            toast.error(err.response.data.msg)
            return { status: false };
        }
    }


    //#endregion

    //#region dashBoardCliente

    const getMyDashBoard = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/usuario/dashBoard/${token}`)
            setters.setIsLoading(false)
            setters.setMeuDashboard(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    //#endregion

    //#region perfilCliente

    const getMyPerfil = async () => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/meuPerfil/perfilClientes`)
            setters.setIsLoading(false)
            setters.setMeuPerfil(response.data.data)
            if (Object.keys(states.meuPerfil).length > 0) toast.info('Perfil carregado com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const updateMyPerfil = async (data) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.put(`/perfilClientes`, data)
            setters.setIsLoading(false)
            setters.setMeuPerfil(response.data.data)
            toast.success('Perfil atualizado com sucesso')
            await getMyPerfil()
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }


    const profileChangeSenha = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/user/changePassword/cliente`, data)
            const saveSenha = localStorage.getItem('userPassword')
            if (saveSenha) {
                localStorage.setItem('userPassword', data.novaSenha)
            }
            setters.setIsLoading(false)
            toast.success('Senha alterado com sucesso.')
            return true;
        } catch (err) {
            console.log(err)
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const profileChangeEmail = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/user/changeemail/cliente`, data)
            setters.setIsLoading(false)
            const saveEmail = localStorage.getItem('userEmail')
            if (saveEmail) {
                localStorage.setItem('userEmail', data.email)
            }
            toast.success('Email alterado com sucesso. Confirme a alteração no seu e-mail atual')
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const profileChangeEmailConfirm = async (token, body) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/user/changeemail/cliente/${token}`, body)
            setters.setIsLoading(false)
            toast.success('Email alterado com sucesso.')
            return true;
        } catch (err) {
            console.log(err.response)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    const profileChangeEmailConfirmCheckLink = async (token) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.get(`/user/checkLink/${token}`)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            console.log(err.response)
            setters.setIsLoading(false)
            localStorage.removeItem('token')
            localStorage.removeItem('permisssion')
            localStorage.removeItem('cliente')
            localStorage.removeItem('usuario')
            setters.setSigned(false)
            toast.error(err.response.data.msg)

            return false;
        }
    }


    const forgotPass = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosNoAuth.post('/user/forgotPassword/cliente', data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            setters.setIsLoading(false)
            return false;
        }
    }

    const forgotChangePass = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosNoAuth.post('/user/forgotChangePass/cliente', data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            return false;
        }
    }

    const profileAutenticationEmail = async (token, body) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.put(`/user/authEmail/cliente/${token}`, body)
            setters.setIsLoading(false)
            toast.success('Email autenticado com sucesso.')
            return true;
        } catch (err) {
            console.log(err.response)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }



    //#endregion

    //#region Financeiro 


    const financeiroGetInfo = async (data) => {
        setters.setIsLoading(true)
        try {
            const resp = await axiosAuth.post(`/financeiro`, data)
            console.log(resp.data)
            setters.setDataFinanceiro(resp.data.dataR.card)
            setters.setDataFinanceiroGrafico(resp.data.dataR.data)
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }

    const financeiroGenerateRelatorio = async (data) => {
        setters.setIsLoading(true)
        try {
            await axiosAuth.post(`/relatorioExecucoes`, data)
            toast.info('Relatório gerado com sucesso. Assim que pronto, chegará em seu e-mail')
            setters.setIsLoading(false)
            return true;
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
            return false;
        }
    }


    //#endregion

    //#region Relatorios 



    const getRelatorioDataSistema = async (token) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/cliente/dadosSistema/financeiro/${token}`)
            setters.setIsLoading(false)
            setters.setDataEstatisticaRelatorio(response.data.data)
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
        }
    }

    const getRelatoriosData = async (id) => {
        setters.setIsLoading(true)
        try {
            const response = await axiosAuth.get(`/relatorioExecucoes/getWithLimit/${states.limiteRelatorio}&${states.offsetRelatorio}`)
            setters.setIsLoading(false)
            setters.setDataRelatorio(response.data.data)
            if (states.dataRelatorio.length === 0) toast.success('Relatórios carregados com sucesso')
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('conversaCliente')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('usuario')
                localStorage.removeItem('nome')
                localStorage.removeItem('iamPrestador')
                localStorage.removeItem('empresa')
                localStorage.removeItem('haveConfig')
                localStorage.removeItem('havePrestador')
                localStorage.removeItem('haveCategoria')
                localStorage.removeItem('haveContabilidadeConfig')
                localStorage.removeItem('haveServicos')
                localStorage.removeItem('perfil')
                localStorage.removeItem('financeiro')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                setters.setLimiteBuscaRelatorio(true)
                if (states.offsetRelatorio > 0) {
                    setters.setOffsetRelatorio(states.offsetRelatorio - states.limiteRelatorio)
                }
                if (states.offsetRelatorio < 0) {
                    setters.setOffsetRelatorio(0)
                }
                toast.error(err.response.data.msg)
            }
        }

    }

    const RelatorioEnviarEmail = async (data) => {
        setters.setIsLoading(true)
        try {
            const resp = await axiosAuth.post(`/relatorioExecucoes/enviarEmail`, data)
            setters.setIsLoading(false)
            if (resp.status === 201) {
                return { status: true, confirm: true, msg: resp.data.msg }
            } else {
                toast.info('Relatório enviado por e-mail com sucesso')
                return { status: true, confirm: false }
            }
        } catch (err) {
            console.log(err)
            setters.setIsLoading(false)
            if (err.response.status === 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('areaCliente')
                localStorage.removeItem('permisssion')
                localStorage.removeItem('cliente')
                localStorage.removeItem('usuario')
                setters.setSigned(false)
                toast.error('Faça login novamente')
                return;
            } else {
                if (err.response.status === 402) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('permisssion')
                    localStorage.removeItem('usuario')
                    localStorage.removeItem('nome')
                    localStorage.removeItem('iamPrestador')
                    localStorage.removeItem('empresa')
                    localStorage.removeItem('haveConfig')
                    localStorage.removeItem('havePrestador')
                    localStorage.removeItem('haveCategoria')
                    localStorage.removeItem('haveContabilidadeConfig')
                    localStorage.removeItem('haveServicos')
                    localStorage.removeItem('perfil')
                    setters.setSigned(false)
                    setters.setErrorAcessoCliente(true)
                    setters.setIsLoading(false)
                    setters.setMsgErrorAcessoCliente(err.response.data.msg)
                    return false;
                }
                toast.error(err.response.data.msg)
            }
            return { status: false };
        }
    }



    //#endregion


    return (
        <AuthContextApi.Provider
            value={{
                RelatorioEnviarEmail,
                getRelatorioDataSistema,
                getRelatoriosData,
                financeiroGenerateRelatorio,
                financeiroGetInfo,
                getConfigNegocio,
                putConfigNegocio,
                getPacientesClienteCpf,
                profileAutenticationEmail,
                profileChangeEmail,
                profileChangeSenha,
                profileChangeEmailConfirm,
                profileChangeEmailConfirmCheckLink,
                updateMyPerfil,
                getMyPerfil,
                getMyDashBoard,
                iniciarMeuServico,
                getMinhaAgendaMonth,
                getMeuDayCompromissos,
                getMeuDayCompromissosMore,
                postMeuCompromisso,
                cancelMeuCompromisso,
                deletarMeuCompromisso,
                putMeuCompromisso,
                cancelCompromisso,
                deletarCompromisso,
                putCompromisso,
                postCompromisso,
                getDayCompromissosMore,
                getDayCompromissos,
                getAgendaMonth,
                postPrestadorPaciente,
                postRemoverPrestadorPaciente,
                getPrestadoresForm,
                getPacientePrestador,
                getPacientesCpf,
                getPrestadorMeusDados,
                getExecucaoServicoDataSistemaPrestador,
                getExecucaoServicoPrestador,
                getExecucaoServicoQueryPrestador,
                getMeusPacientesDataSistema,
                getMeusPacientes,
                getMeusPacientesQuery,
                atualizarProntuarioEncerrado,
                encerrarServico,
                atualizarProntuario,
                iniciarServico,
                getServicosPrestador,
                getServicosPrestadores,
                addConversa,
                excluirtConversa,
                encerrarConversa,
                postMensagemConversa,
                getPacientConversa,
                getPacientConversaMensagens,
                getPacientConversas,
                getPacientProntuarios,
                getPacienteProntuariosData,
                getPacienteExecucoes,
                getPacienteData,
                getPacienteDataSistema,
                postPaciente,
                putPaciente,
                getPacientesDataSistema,
                getPacientes,
                getPacientesQuery,
                getExecucaoServicoDataSistema,
                getExecucaoServico,
                getExecucaoServicoQuery,
                putPrestadorBloqueioDesbloqueio,
                postPrestador,
                putPrestador,
                getPrestadoresDataSistema,
                getPrestadores,
                getPrestadoresQuery,
                postServicos,
                putServicoStatus,
                deleteServico,
                putServicos,
                getCategoriasForm,
                deleteCategoria,
                putCategoriaStatus,
                getServicosDataSistema,
                getServicos,
                getServicosQuery,
                putCategoria,
                postCategoria,
                getCategoriasDataSistema,
                getCategorias,
                getCategoriasQuery,
                getRecuperacaoSenhaQuery,
                getRecuperacaoSenha,
                getRecuperacaoDeSenhaDataSistema,
                blockUnlockUsuarios,
                getUsuariosDataSistema,
                getUsuarios,
                getUsuariosQuery,
                postUsuarios,
                putUsuarios,
                envioCarneLeao,
                getCarneLeaoDataSistema,
                getCarneLeao,
                putConfigContabilidade,
                putConfigClienteForm,
                postPrestadorNewProfile,
                postConfigPrestadorMyProfile,
                postConfigContabilidade,
                getConfigContabilidade,
                getConfigCategoriaServico,
                postConfigCategoriaServico,
                checkConfigCliente,
                postConfigCliente,
                putConfigCliente,
                getFaturasDataSistema,
                getFaturas,
                login,
                checkLogin,
                forgotPass,
                forgotChangePass,
                logout

            }}>
            {children}
        </AuthContextApi.Provider>
    )
}

export default AuthProvider;
