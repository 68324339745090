import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Context';
import { AuthContextApi } from '../../Context/api';
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Table from '../../Components/Table/MeusServicos';
import ModalShow from '../../Components/Modal/ExecucaoServico'
import Modal from '../../Components/Modal/ExecucaoServicoMeusServicos'



const ExecucoesServico = () => {
    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getExecucaoServicoDataSistemaPrestador,
        getExecucaoServicoPrestador,
        getExecucaoServicoQueryPrestador,
        getPrestadorMeusDados,
        getServicosPrestador,
        getPacientesCpf,
        iniciarServico
    } = useContext(AuthContextApi)
    const [query, setQuery] = useState('')
    const { token } = useParams()
    const navigate = useNavigate()




    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }
    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }


    const primeiroLoad = async () => {
        await checkLogin()
        await getPrestadorMeusDados()
        await getExecucaoServicoDataSistemaPrestador(token)
        await getExecucaoServicoPrestador()
    }

    const loadUsuarios = async () => {
        if (query !== '') {
            await getExecucaoServicoQueryPrestador(query)
        } else {
            await getExecucaoServicoPrestador()
        }
    }

    const next = async () => {
        if (states.limiteBuscaExecucoesServico) {
            if (states.offsetExecucoesServico + states.limiteExecucoesServico > 0) return
            setters.setLimiteBuscaExecucoesServico(true)
        }
        setters.setOffsetExecucoesServico(states.offsetExecucoesServico + states.limiteExecucoesServico)
    }

    const previous = async () => {
        if (states.offsetExecucoesServico - states.limiteExecucoesServico < 0) return
        if (states.limiteBuscaExecucoesServico) {
            setters.setLimiteBuscaExecucoesServico(false)
        }
        setters.setOffsetExecucoesServico(states.offsetExecucoesServico - states.limiteExecucoesServico)
    }

    const search = async () => {
        setters.setOffsetExecucoesServico(0)
        if (query === '') {
            await getExecucaoServicoPrestador()
            return
        }
        const response = await getExecucaoServicoQueryPrestador(query)
        if (response === false) {
            setQuery('')
            await getExecucaoServicoPrestador()
        }
    }

    const clear = async () => {
        setters.setOffsetExecucoesServico(0)
        setQuery('')
        await getExecucaoServicoPrestador()
    }

    const showServico = async (data) => {
        setters.setExecucoesServico(data)
        setters.setModalExecucoesServicoShow(true)
    }


    const dowloadComprovante = data => {
        setters.setModalUsuarios(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja baixar o comprovante de execução do serviço?`)
        setters.setDescModalMsg(`Após a confirmação o comprovante será baixado`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const link = document.createElement("a");
            link.href = `data:application/pdf;base64,${data.path}`;
            link.download = data.nomeArquivo;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setters.setModalMsg(false)
        })
    }


    const loadMoreCategoriaPrestador = async () => {
        await getServicosPrestador()
    }

    const addServico = async () => {
        await getServicosPrestador()
        setters.setModalExecucoesServicoPaciente(true)
    }

    const iniciarServicoAction = async (data, servico) => {
        setters.setModalExecucoesServicoPaciente(false)
        setters.setModalMsg2(true)
        setters.setPerigoModalMsg2(false)
        setters.setMsgModalMsg2(`Deseja iniciar o serviço ${servico.nome}?`)
        setters.setDescModalMsg2(`Após a confirmação o serviço será iniciado`)
        setters.setTitleButtonModalMsg2('Confirmar')
        setters.setFunctionModalMsg2(() => async () => {
            const response = await iniciarServico(data)
            setters.setModalMsg2(false)
            if (response.status === true && response.confirm) {
                setters.setModalMsg2(true)
                setters.setPerigoModalMsg2(true)
                setters.setMsgModalMsg2(response.msg)
                setters.setDescModalMsg2(response.msg)
                setters.setTitleButtonModalMsg2('Confirmar início do serviço')
                setters.setFunctionModalMsg2(() => async () => {
                    const response = await iniciarServico({ ...data, confirmCancel: "true" })
                    setters.setModalMsg2(false)
                    if (response.status === true) {
                        navigate(`/servicos/${token}/paciente/${states.Paciente.id}/atendimento/${response.prontuario}`)
                    }
                })
                setters.setFunctionBtnModalMsg2(() => async () => {
                    await iniciarServico({ ...data, confirmCancel: "false" })
                    setters.setModalMsg2(false)
                })
            }
            if (response.status === true && !response.confirm) {
                navigate(`/servicos/${token}/paciente/${states.Paciente.id}/atendimento/${response.prontuario}`)
            }
        })

        setters.setFunctionBtnModalMsg2(() => async () => {
            setters.setModalMsg2(false)
        })
    }





    useEffect(() => {
        primeiroLoad()

        return () => {
            setQuery('')
            setters.setDataEstatisticaExecucoesServico([])
            setters.setDataExecucoesServico([])
            setters.setDataCategoriasForm([])
            setters.setExecucoesServico({})
            setters.setOffsetExecucoesServico(0)
            setters.setLimiteBuscaExecucoesServico(false)
            setters.setDataCategoriaPrestador([])
            setters.setCategoriaPrestador({})
            setters.setOffsetCategoriaPrestador(0)
            setters.setLimiteBuscaCategoriaPrestador(false)
            setters.setPaciente({})

        }

    }, [])


    useEffect(() => {
        if (states.dataCategoriaPrestador.length > 0) {
            loadMoreCategoriaPrestador()
        }
    }, [states.offsetCategoriaPrestador])


    useEffect(() => {
        if (states.dataExecucoesServico?.length === 0) return
        loadUsuarios()
    }, [states.offsetExecucoesServico])




    const headers = ['', 'Status', 'Nome do serviço', 'Nome do paciente', 'Cpf do paciente', 'Início', 'Fim', 'Fim previsto', 'Data atualização', 'Data de criação']
    return (
        <>
            <div className="flex flex-col">
                <div className="flex-1 p-4 border rounded-[10px]  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Painel de serviços</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações sobre seus serviços executados
                    </p>
                </div>
                <div className="flex-1 p-6 border  hover:shadow-md mt-5">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Meus Dados</h1>
                    <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                        <div className='flex flex-col   p-1'>
                            <p className="text-sm text-gray-700">{states.Prestadores?.nome} - {formatToCpfOrCnpj(states.Prestadores?.cpf)} <br /> {formatToPhoneBrl(states.Prestadores?.telefone)}</p>
                        </div>
                        <div className='flex flex-col   p-1'>
                            <p className="text-sm text-gray-700 ">{states.Prestadores?.rua} - {states.Prestadores?.numero}, {states.Prestadores?.complemento} <br /> {states.Prestadores?.bairro} - {formatCep(states.Prestadores?.cep)}</p>
                        </div>
                        <div className='flex flex-col   p-1'>
                            <p className="text-sm text-gray-700 "> {states.Prestadores?.cidade} - {states.Prestadores?.estado} </p>
                        </div>
                        <div className='flex flex-col   p-1'>
                            <p className="text-sm text-gray-700 ">Código de registro: <br /> {states.Prestadores?.entidadeRegistro} - {states.Prestadores?.codigoEntidadeRegistro}</p>
                        </div>
                        <div className='flex flex-col   p-1'>
                            <p className="text-sm text-gray-700 ">Categoria: <br /> {states.Prestadores?.nome_categoria} </p>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
                    {states.dataEstatisticaExecucoesServico?.length > 0 && states.dataEstatisticaExecucoesServico.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


                <>
                    <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Pesquise por serviço</h1>
                        <div className='flex flex-col mt-4'>
                            <p className="text-sm text-gray-700">Pesquise por nome do paciente ou e-mail</p>
                            <div className='flex flex-col sm:flex-row mt-2'>
                                <input type="text" className=" flex w-full sm:w-[80%] mt-1 p-2 border-2 border-gray-200 rounded-md"
                                    value={query} onChange={(e) => setQuery(e.target.value)} onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            search()
                                        }
                                    }}
                                />
                                <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={search}>Pesquisar</button>
                                <button className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-green-600 text-white rounded-md hover:bg-green-500" onClick={clear}>Limpar</button>
                            </div>
                        </div>
                    </div>
                    <div className=" p-4 border hover:shadow-md mt-5">
                        <Table title='Serviços' description='Registro dos serviços'
                            next={next} show={showServico} dowload={dowloadComprovante} add={addServico}
                            previous={previous} states={states} setters={setters} headers={headers}
                            infoTable={{ offset: states.offsetExecucoesServico, limit: states.limiteExecucoesServico }} dataTable={states.dataExecucoesServico} query={query} />
                    </div>
                </>


            </div>
            <ModalShow states={states} setters={setters} />
            <Modal states={states} setters={setters} action={iniciarServicoAction} get={getPacientesCpf} />
        </>
    )

}



export default ExecucoesServico;