import { useState, useEffect } from 'react'
import { MagnifyingGlassIcon, PencilIcon, TrashIcon, XCircleIcon, CheckBadgeIcon } from '@heroicons/react/20/solid'
import moment from 'moment'
import { jsonConfig } from '../../Config'

const ListaDeTarefas = ({ states, setters, data, show, edit, cancel, del, prestador, exec }) => {


    const [tarefas, setTarefas] = useState([])

    const loadMore = () => {
        setters.setOffsetTarefas(states.offsetTarefas + states.limiteTarefas)
    }

    const truncat = (str, n) => {
        return str?.length > n ? str?.substr(0, n - 1) + '...' : str;
    }

    useEffect(() => {
        setTarefas(data)
    }, [states.dataTarefas])

    return (
        <>
            <div className="sm:flex flex-col w-full h-full">
                <div className='flex-col border-b border-gray-900/10'>
                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Lista de Tarefas</h3>
                    <div style={{ maxHeight: '650px', overflowY: 'auto' }} className="border w-full rounded-lg shadow-md">
                        {
                            tarefas.length > 0 ? tarefas.map((tarefa, index) => {
                                console.log(tarefa)
                                return (
                                    <div key={index} className="flex flex-col p-4 border-b border-gray-200">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <div>
                                                    <p className="text-lg font-semibold text-gray-800">{moment(tarefa.inicio).format('HH:mm:ss')}</p>
                                                </div>
                                            </div>
                                            <div className="flex items-center flex-col items-center justify-center">
                                                <p className="text-sm text-gray-500">{truncat(tarefa.titulo, 15)}</p>
                                                <p className="text-sm text-gray-500">{moment(tarefa.inicio).format('DD/MM/YYYY')}</p>
                                            </div>
                                        </div>
                                        <div className="flex  justify-between mt-2 ">
                                            <button
                                                onClick={() => show(tarefa)}
                                                className="px-3 py-1 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                                <MagnifyingGlassIcon className="h-5 w-5" />
                                            </button>
                                            {
                                                (tarefa.status === "0" || tarefa.status === "3") &&
                                                <>
                                                    <button
                                                        onClick={() => edit(tarefa)}
                                                        className="px-3 py-1 text-sm font-medium text-white bg-green-600 rounded hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                                                        <PencilIcon className="h-5 w-5" />
                                                    </button>
                                                    <button
                                                        onClick={() => cancel(tarefa)}
                                                        className="px-3 py-1 text-sm font-medium text-white bg-purple-600 rounded hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                                                        <XCircleIcon className="h-5 w-5" />
                                                    </button>
                                                </>

                                            }
                                            {
                                                (prestador && tarefa.tipo === '0' && (tarefa.status === '0' || tarefa.status === "3")) &&
                                                <button
                                                    onClick={() => exec(tarefa)}
                                                    className="px-3 py-1 text-sm font-medium text-white bg-orange-600 rounded hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2">
                                                    <CheckBadgeIcon className="h-5 w-5" />
                                                </button>
                                            }
                                            {
                                                (tarefa.status === "1" && localStorage.getItem("permisssion") === jsonConfig.adminPermission) &&
                                                <button
                                                    onClick={() => del(tarefa)}
                                                    className="px-3 py-1 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                                                    <TrashIcon className="h-5 w-5" />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                );
                            }) : <p className="text-sm text-gray-500 text-center p-4">Nenhuma tarefa cadastrada</p>
                        }
                    </div>
                    {
                        (!states.limiteBuscaTarefas && tarefas.length >= states.limiteTarefas) &&
                        <button
                            className="w-full p-2 text-center text-sm text-gray-500 hover:bg-gray-100"
                            onClick={loadMore}
                        >
                            Ver mais
                        </button>
                    }
                </div>
            </div>
        </>
    )

}


export default ListaDeTarefas