import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Context";
import { AuthContextApi } from "../../Context/api";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  BanknotesIcon,
  UsersIcon,
  ClipboardDocumentListIcon,
  IdentificationIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/20/solid";
import moment from "moment";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { jsonConfig } from "../../Config";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);



const Dashboard = () => {
  const id = null;
  const { states, setters } = useContext(AuthContext);
  const { checkLogin, getMyDashBoard } = useContext(AuthContextApi);
  const [emailUsuario, setEmailUsuario] = useState("");
  const [cumprimento, setCumprimento] = useState("");
  const [cheron, setCheron] = useState(true);
  const { token } = useParams();

  let stats = [];

  if (localStorage.getItem('permisssion') === jsonConfig.adminPermission) {
    stats = [
      { id: 1, name: "Clientes", icon: UsersIcon, href: `/pacientes/${token}` },
      { id: 1, name: "Faturas", icon: BanknotesIcon, href: `/financeiroIndex/${token}/faturas` },
      {
        id: 1,
        name: "Serviços Prestados",
        icon: IdentificationIcon,
        href: `/administrativoIndex/${token}/execucoesServico`,
      },
      { id: 2, name: "Usuários", icon: ClipboardDocumentListIcon, href: `/usuariosIndex/${token}` },
    ];
  } else if (localStorage.getItem('permisssion') === jsonConfig.prestadorPermission) {
    stats = [
      { id: 1, name: "Pacientes", icon: UsersIcon, href: `/meusPacientes/${token}` },
      {
        id: 1,
        name: "Serviços Prestados",
        icon: IdentificationIcon,
        href: `/servicos/${token}`,
      },
      { id: 2, name: "Agenda", icon: ClipboardDocumentListIcon, href: `/minhaAgenda/${token}` },
    ];

  } else {
    stats = [
      { id: 1, name: "Pacientes", icon: UsersIcon, href: `/pacientes/${token}` },
      { id: 2, name: "Agenda", icon: ClipboardDocumentListIcon, href: `/agenda/${token}` }
    ];

  }

  const getGreeting = () => {
    const hour = moment().hour(); // Pega a hora atual
    if (hour < 12) {
      setCumprimento("Bom dia");
    } else if (hour < 18) {
      setCumprimento("Boa tarde");
    } else {
      setCumprimento("Boa noite");
    }
  };

  const navigate = useNavigate();

  const clickButton = (href) => {
    navigate(href);
  };

  const primeiroLoad = async () => {
    await checkLogin();
    await getMyDashBoard(token);
  };

  const truncatFunction = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const renderChart = (dataPoints) => {

    const reverseDataPoints = [...dataPoints].reverse();
    const labels = reverseDataPoints.map(item => item.month);
    const values = reverseDataPoints.map(item => item.total);

    // Configurar o gráfico de linhas
    const data = {
      labels: labels, // Meses
      datasets: [
        {
          label: "Total por Mês",
          data: values, // Totais
          fill: false,
          backgroundColor: '#36A2EB',
          borderColor: '#36A2EB',
          tension: 0.1, // Suaviza a linha
          pointBackgroundColor: '#36A2EB', // Cor dos pontos
          pointBorderColor: '#36A2EB', // Cor da borda dos pontos
          pointRadius: 5, // Tamanho dos pontos
        }
      ]
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true, // Garante que o gráfico comece no valor zero
          suggestedMax: 5, // Ajuste a escala máxima para melhorar a visibilidade
        }
      },
      plugins: {
        legend: {
          display: true, // Exibe a legenda
        }
      }
    };
    return (
      <Line
        data={data}
        options={options}
      />
    );
  }

  useEffect(() => {
    primeiroLoad();
    getGreeting();
    const email = localStorage.getItem("usuario");
    setEmailUsuario(email);

    return () => {
      setters.setDataEstatisticaDashBoard([]);
    };
  }, []);


  return (
    <div className="flex flex-col">
      <div className="flex-1 p-4 border  hover:shadow-md">
        <h1 className="hidden sm:flex text-2xl font-bold leading-7 text-gray-900 sm:leading-9">
          {cumprimento}, {emailUsuario}
        </h1>
        <h1 className="flex sm:hidden text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
          {cumprimento}, {truncatFunction(emailUsuario, 18)}
        </h1>
        <CheckCircleIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
          aria-hidden="true"
        />
      </div>
      {/* Acesso rápido tem que ser aqui, antes das estatisticas */}

      <div className="flex flex-1 flex-col items-center  w-full rounded-[10px]">
        <div className="p-4 w-[95%]">
          <dl className=" grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((item) => (
              <button
                key={item.id}
                onClick={() => clickButton(item.href)}
                className="relative overflow-hidden rounded-lg bg-white px-4 pb-1 pt-1  sm:px-5 sm:pt-1 hover:shadow-md border"
              >
                <dt>
                  <div className="absolute rounded-md bg-blue-500 p-3 mt-2">
                    <item.icon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </dt>
                <dd className="ml-16 flex flex-col items-start pb-6 sm:pb-7">
                  <p className="text-mlg font-semibold text-gray-900">
                    {item.name}
                  </p>
                  <p className="ml-2 text-md  text-gray-900">
                    Clique para ver mais
                  </p>
                </dd>
              </button>
            ))}
          </dl>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mt-5">
        {states.meuDashboard?.cards?.length > 0 &&
          states.meuDashboard.cards.map((card, index) => (
            <div
              key={card.title}
              className={
                card.value > 0 && index === -1
                  ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                  : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
              }
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    {card.value > 0 && index === -1 ? (
                      <InformationCircleIcon
                        className="w-6 h-6 text-red-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <InformationCircleIcon
                        className="w-6 h-6 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      {card.value > 0 && index === -1 ? (
                        <dt className="truncate text-sm font-medium text-red-500">
                          {card.title}
                        </dt>
                      ) : (
                        <dt className="truncate text-sm font-medium text-gray-500">
                          {card.title}
                        </dt>
                      )}

                      <dd>
                        {card.value > 0 && index === -1 ? (
                          <div className="text-lg font-medium text-red-500">
                            {card.value}
                          </div>
                        ) : (
                          <div className="text-lg font-medium text-gray-900">
                            {card.value}
                          </div>
                        )}
                      </dd>
                      {/* Parte do link como botao */}
                      <div className="mt-2 flex justify-end">
                        <Link
                          to={card.link}
                          className="text-sm font-medium text-blue-600 hover:text-blue-500"
                        >
                          Ver mais
                        </Link>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      {
        states.meuDashboard?.graficos && states.meuDashboard.graficos.length > 0 &&
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2 mt-4">
          {states.meuDashboard.graficos.map((grafico, index) => (
            <div key={index} className="flex flex-col p-4 border hover:shadow-md">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                {grafico.title}
              </h1>
              <div className="border-t border-1 border-gray-300 border-dashed my-4"></div>
              <div className="flex flex-col w-full">
                <div className="flex flex-col items-start border p-3 hover:shadow">
                  {renderChart(grafico.data)}
                </div>
              </div>
            </div>
          ))}
        </div>
      }

    </div>
  );
};

export default Dashboard;
