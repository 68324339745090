import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'



const ExecucoesServico = ({ states, setters, action, get }) => {


    const [servico, setServico] = useState('')
    const [cpf, setCpf] = useState('')
    const [error, setError] = useState(false)
    const [errorCpf, setErrorCpf] = useState(false)
    const [selectService, setSelectService] = useState({})
    const [select, setSelect] = useState(false)


    const formatToMoney = value => {
        if (!value) return
        const valorFloat = value / 100;
        return valorFloat.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }


    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }


    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }



    const handleServico = async (data) => {
        if (data === 'outro') {
            setters.setOffsetCategoriaPrestador(states.offsetCategoriaPrestador + states.limiteCategoriaPrestador)
            return
        }
        const findServico = states.dataCategoriaPrestador.find(servico => servico.id === data)
        setSelectService(findServico)
        setServico(data)
        setSelect(true)
        setError(false)
    }

    const removeServico = () => {
        setSelectService({})
        setServico('')
        setSelect(false)
    }

    const actionServico = async () => {
        let error = false;
        if (servico === '') {
            error = true
            setError(true)
        } else setError(false)

        if (Object.keys(states.Paciente).length === 0) {
            error = true
            setErrorCpf(true)
        } else setErrorCpf(false)

        if (error === true) return

        action({
            servico_id: servico,
            clienteSecundario_id: states.Paciente.id,
        }, selectService)
    }

    const getPaciente = async (cpf) => {
        if (cpf === '') {
            setErrorCpf(true)
            return
        }
        if (cpf.length < 11) return
        const resp = await get(cpf)
        if (!resp) {
            setCpf('')
        }
    }



    useEffect(() => {
        if (states.modalExecucoesServicoPaciente === false) {
            setters.setDataCategoriaPrestador([])
            setters.setLimiteBuscaCategoriaPrestador(false)
            setters.setOffsetCategoriaPrestador(0)
            setServico('')
            setError(false)
            setSelectService({})
            setters.setPaciente({})
            setSelect(false)
        }

    }, [states.modalExecucoesServicoPaciente])


    return (
        <>
            <Transition.Root show={states.modalExecucoesServicoPaciente} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setters.setModalExecucoesServicoPaciente(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {/* Mexer na altura, só colocar o height na classname abaixo */}
                                <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => setters.setModalExecucoesServicoPaciente(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className='flex-col border-b border-gray-900/10'>
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Execução de Serviço</h3>
                                        <div className='flex justify-center flex-col items-center gap-x-4 pb-4'>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                Preencha os dados para iniciar o servico
                                            </p>
                                            {
                                                Object.keys(states.Paciente).length > 0 &&
                                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                                    Paciente: <span className="font-semibold">{states.Paciente.nome}</span>
                                                </p>
                                            }
                                        </div>
                                    </div>
                                    <div className='pt-10 pl-10 pr-10'>
                                        <div className="space-y-12">

                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações do atendimento</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do atendimento</p>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    {/* COLOCAR OS DADOS DO SERVIÇO AO SELECIONAR -> limiteBuscaCategoriaPrestador */}
                                                    <div className="grid grid-cols-1 gap-y-6  lg:grid-cols-3 gap-x-6 ">
                                                        <div className="sm:col-span-3">
                                                            {
                                                                (Object.keys(states.Paciente).length === 0) ? (
                                                                    <>
                                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Digite o CPF do paciente</label>
                                                                        <div className='flex flex-col sm:flex-row items-start sm:items-center gap-x-4 w-full'>
                                                                            <div className="flex border rounded-[15px] p-3 hover:shadow mt-4 w-full">
                                                                                <input
                                                                                    type="text"
                                                                                    value={formatToCpfOrCnpj(cpf)}
                                                                                    maxLength={14}
                                                                                    onChange={(e) => {
                                                                                        const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                                                                        if (onlyNumbers !== '') setErrorCpf(false)
                                                                                        setCpf(onlyNumbers)
                                                                                    }}
                                                                                    className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                                />
                                                                            </div>
                                                                            <button type="button" className="text-sm mt-4 font-semibold leading-5 text-white bg-blue-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                                                                                onClick={() => getPaciente(cpf)}
                                                                            >
                                                                                <MagnifyingGlassCircleIcon className="h-5 w-5" aria-hidden="true" />
                                                                            </button>
                                                                        </div>
                                                                        {
                                                                            errorCpf && <span className="text-red-500 text-sm">Selecione um paciente</span>
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    //Nome, cpf, email e telefone
                                                                    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-4">
                                                                        <div className="flex flex-col">
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{states.Paciente.nome}</p>
                                                                        </div>
                                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Cpf</label>
                                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{formatToCpfOrCnpj(states.Paciente.cpf)}</p>
                                                                        </div>
                                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">E-mail</label>
                                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{states.Paciente.email}</p>
                                                                        </div>
                                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{formatToPhoneBrl(states.Paciente.telefone)}</p>
                                                                        </div>
                                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                            <button type="button" className="text-sm font-semibold leading-5 text-white bg-red-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-red-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-100"
                                                                                onClick={() => {
                                                                                    setters.setPaciente({})
                                                                                    setCpf('')
                                                                                }}
                                                                            >
                                                                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-3 mt-5">
                                                            {
                                                                !select ? (
                                                                    <>
                                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Selecione o serviço</label>
                                                                        <div className="flex border rounded-[15px] p-3 hover:shadow mt-4">
                                                                            <select
                                                                                value={servico}
                                                                                onChange={(e) => handleServico(e.target.value)}
                                                                                className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                            >
                                                                                <option value="">Selecione o serviço</option>
                                                                                {states.dataCategoriaPrestador.map((servico, index) => (
                                                                                    <option key={index} value={servico.id}>{servico.nome} - {servico.duracao}</option>
                                                                                ))}
                                                                                {
                                                                                    (!states.limiteBuscaCategoriaPrestador && states.dataCategoriaPrestador.length >= states.limiteCategoriaPrestador) &&
                                                                                    <option value="outro">Carregar mais</option>
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        {
                                                                            error && <span className="text-red-500 text-sm">Selecione um serviço</span>
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-4">
                                                                        <div className="flex flex-col">
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Serviço</label>
                                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{selectService.nome}</p>
                                                                        </div>
                                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Duração</label>
                                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{selectService.duracao}</p>
                                                                        </div>
                                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Preço</label>
                                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{formatToMoney(selectService.preco)}</p>
                                                                        </div>
                                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Categoria</label>
                                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{selectService.categoria_nome}</p>
                                                                        </div>
                                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                            <button type="button" className="text-sm font-semibold leading-5 text-white bg-red-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-red-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-100"
                                                                                onClick={removeServico}
                                                                            >
                                                                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 flex flex-col sm:flex-row items-center justify-end gap-x-6">
                                            <button type="button" className="text-sm font-semibold leading-5 text-white bg-blue-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                                                onClick={actionServico}
                                            >
                                                Iniciar Atendimento
                                            </button>
                                            <button type="button" className="text-sm mt-3 sm:mt-0 font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                                onClick={() => setters.setModalExecucoesServicoPaciente(false)}>
                                                Fechar
                                            </button>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}


export default ExecucoesServico;