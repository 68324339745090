import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Context";
import { AuthContextApi } from "../../../Context/api";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";
import Table from "../../../Components/Table/RecuperacaoSenha";
import NavMenu from "../../../Components/NavMenu/Usuarios";

const RecuperacaoDeSenha = () => {
  const { states, setters } = useContext(AuthContext);
  const {
    checkLogin,
    getRecuperacaoDeSenhaDataSistema,
    getRecuperacaoSenha,
    getRecuperacaoSenhaQuery,
  } = useContext(AuthContextApi);
  const [query, setQuery] = useState("");
  const { token } = useParams();

  const primeiroLoad = async () => {
    await getRecuperacaoDeSenhaDataSistema(token);
    await checkLogin();
    await getRecuperacaoSenha();
  };

  const loadRecuperacao = async () => {
    if (query !== "") {
      await getRecuperacaoSenhaQuery(query);
    } else {
      await getRecuperacaoSenha();
    }
  };

  const next = async () => {
    if (states.limiteBuscaRecuperacaoSenha) {
      if (states.offsetRecuperacaoSenha + states.limiteRecuperacaoSenha > 0)
        return;
      setters.setLimiteBuscaRecuperacaoSenha(true);
    }
    setters.setOffsetRecuperacaoSenha(
      states.offsetRecuperacaoSenha + states.limiteRecuperacaoSenha
    );
  };

  const previous = async () => {
    if (states.offsetRecuperacaoSenha - states.limiteRecuperacaoSenha < 0)
      return;
    if (states.limiteBuscaRecuperacaoSenha) {
      setters.setLimiteBuscaRecuperacaoSenha(false);
    }
    setters.setOffsetRecuperacaoSenha(
      states.offsetRecuperacaoSenha - states.limiteRecuperacaoSenha
    );
  };

  const search = async () => {
    setters.setOffsetRecuperacaoSenha(0);
    if (query === "") {
      await getRecuperacaoSenha();
      return;
    }
    const response = await getRecuperacaoSenhaQuery(query);
    if (response === false) {
      setQuery("");
      await getRecuperacaoSenha();
    }
  };

  const clear = async () => {
    setters.setOffsetRecuperacaoSenha(0);
    setQuery("");
    await getRecuperacaoSenha();
  };

  // const [dataEstatisticaRecuperacaoSenha, setDataEstatisticaRecuperacaoSenha] = useState([])
  // const [dataRecuperacaoSenha, setDataRecuperacaoSenha] = useState([])
  // const [RecuperacaoSenha, setRecuperacaoSenha] = useState([])
  // const [offsetRecuperacaoSenha, setOffsetRecuperacaoSenha] = useState(0)
  // const [limiteRecuperacaoSenha, setLimiteRecuperacaoSenha] = useState(jsonConfig.limitDefault)
  // const [limiteBuscaRecuperacaoSenha, setLimiteBuscaRecuperacaoSenha] = useState(false)

  useEffect(() => {
    primeiroLoad();

    return () => {
      setQuery("");
      setters.setDataEstatisticaRecuperacaoSenha([]);
      setters.setDataRecuperacaoSenha([]);
      setters.setRecuperacaoSenha({});
      setters.setOffsetRecuperacaoSenha(0);
      setters.setLimiteBuscaRecuperacaoSenha(false);
    };
  }, []);

  useEffect(() => {
    if (states.dataRecuperacaoSenha?.length === 0) return;
    loadRecuperacao();
  }, [states.offsetRecuperacaoSenha]);

  const headers = [
    "E-mail",
    "Status",
    "Data de Criação",
    "Data de Atualização",
    "",
  ];
  return (
    <>
      <div className="flex flex-col">
        <NavMenu />

        <div className="flex-1 p-4 border  hover:shadow-md">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Recuperação de senha
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Informações sobre as solicitaçîes de recuperação de senha
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-3 mt-4">
          {states.dataEstatisticaRecuperacaoSenha?.length > 0 &&
            states.dataEstatisticaRecuperacaoSenha.map((card, index) => (
              <div
                key={card.title}
                className={
                  card.value > 0 && index === -1
                    ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                    : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                }
              >
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      {card.value > 0 && index === -1 ? (
                        <InformationCircleIcon
                          className="w-6 h-6 text-red-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <InformationCircleIcon
                          className="w-6 h-6 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        {card.value > 0 && index === -1 ? (
                          <dt className="truncate text-sm font-medium text-red-500">
                            {card.title}
                          </dt>
                        ) : (
                          <dt className="truncate text-sm font-medium text-gray-500">
                            {card.title}
                          </dt>
                        )}

                        <dd>
                          {card.value > 0 && index === -1 ? (
                            <div className="text-lg font-medium text-red-500">
                              {card.value}
                            </div>
                          ) : (
                            <div className="text-lg font-medium text-gray-900">
                              {card.value}
                            </div>
                          )}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="flex-1 p-12 border  hover:shadow-md mt-5">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Pesquisa por recuperação
          </h1>
          <div className="flex flex-col mt-4">
            <p className="text-sm text-gray-700">Pesquise por e-mail</p>
            <div className="flex flex-col sm:flex-row mt-2">
              <input
                type="text"
                className=" flex w-full sm:w-[80%] mt-1 p-2 border-2 border-gray-200 rounded-md"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search();
                  }
                }}
              />
              <button
                className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                onClick={search}
              >
                Pesquisar
              </button>
              <button
                className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-green-600 text-white rounded-md hover:bg-green-500"
                onClick={clear}
              >
                Limpar
              </button>
            </div>
          </div>
        </div>
        {states.dataRecuperacaoSenha?.length > 0 ? (
          <div className=" p-4 border hover:shadow-md mt-5">
            {states.dataRecuperacaoSenha?.length > 0 && (
              <Table
                title="Recuperação de senha"
                description="Registro de recuperação de senha"
                next={next}
                previous={previous}
                states={states}
                setters={setters}
                headers={headers}
                infoTable={{
                  offset: states.offsetRecuperacaoSenha,
                  limit: states.limiteRecuperacaoSenha,
                }}
                dataTable={states.dataRecuperacaoSenha}
                query={query}
              />
            )}
          </div>
        ) : (
          <div className="flex-1 p-12 border  hover:shadow-md mt-5">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Sem registro de recuperação de senha
            </h1>
          </div>
        )}
      </div>
    </>
  );
};

export default RecuperacaoDeSenha;
