import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../Context";
import { AuthContextApi } from "../../../Context/api";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import NavMenu from "../../../Components/NavMenu/Financeiro";
import Table from "../../../Components/Table/CarneLeao";
import moment from "moment";

const CarneLeao = () => {
  const { states, setters } = useContext(AuthContext);
  const { checkLogin, getCarneLeaoDataSistema, getCarneLeao, envioCarneLeao } =
    useContext(AuthContextApi);
  const { token } = useParams();

  const primeiroLoad = async () => {
    await getCarneLeaoDataSistema(token);
    await getCarneLeao();
    await checkLogin();
  };

  const loadUsuarios = async () => {
    await getCarneLeao();
  };

  const next = async () => {
    if (states.limiteBuscaCarneLeao) {
      if (states.offsetCarneLeao + states.limiteCarneLeao > 0) return;
      setters.setLimiteBuscaCarneLeao(true);
    }
    setters.setOffsetCarneLeao(states.offsetCarneLeao + states.limiteCarneLeao);
  };

  const previous = async () => {
    if (states.offsetCarneLeao - states.limiteCarneLeao < 0) return;
    if (states.limiteBuscaCarneLeao) {
      setters.setLimiteBuscaCarneLeao(false);
    }
    setters.setOffsetCarneLeao(states.offsetCarneLeao - states.limiteCarneLeao);
  };

  const dowloadCarne = async (data) => {
    setters.setModalMsg(true);
    setters.setPerigoModalMsg(false);
    setters.setMsgModalMsg(
      `Deseja realizar o dowload do carne gerado no dia ${moment(
        data.data_geracao
      ).format("DD/MM/YYYY")}?`
    );
    setters.setDescModalMsg("Após a confirmação o arquivo será baixado.");
    setters.setTitleButtonModalMsg("Confirmar dowload");
    setters.setFunctionModalMsg(() => async () => {
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${data.path_arquivo}`;
      link.download = data.nome_arquivo;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setters.setModalMsg(false);
    });
  };

  const carneEnvioContabilidade = async (data) => {
    setters.setModalMsg(true);
    setters.setPerigoModalMsg(false);
    setters.setMsgModalMsg(
      `Deseja realizar o envio do carnê para seu contador cadastrado?`
    );
    setters.setDescModalMsg("Após a confirmação o arquivo será baixado.");
    setters.setTitleButtonModalMsg("Confirmar envio");
    setters.setFunctionModalMsg(() => async () => {
      const resp = await envioCarneLeao(data);
      setters.setModalMsg(false);
      if (resp.newSend === true) {
        setters.setModalMsg(true);
        setters.setPerigoModalMsg(false);
        setters.setMsgModalMsg(
          `Carnê leão já enviado, deseja enviar novamente?`
        );
        setters.setDescModalMsg("Após a confirmação o arquivo será baixado.");
        setters.setTitleButtonModalMsg("Confirmar reenvio");
        setters.setFunctionModalMsg(() => async () => {
          await envioCarneLeao({ ...data, confirmNewSend: "true" });
          setters.setModalMsg(false);
        });
      }
    });
  };

  useEffect(() => {
    primeiroLoad();

    return () => {
      setters.setDataEstatisticaCarneLeao([]);
      setters.setDataCarneLeao([]);
      setters.setCarneLeao({});
      setters.setOffsetCarneLeao(0);
      setters.setLimiteBuscaCarneLeao(false);
    };
  }, []);

  useEffect(() => {
    if (states.dataCarneLeao?.length === 0) return;
    loadUsuarios();
  }, [states.offsetCarneLeao]);

  const headers = [
    "",
    "Status de Processamento",
    "Envio para o contador",
    "Data de período",
    "Data de geração",
    "Data de processamento",
    "Data de Envio",
    "Data de Criação",
    "Data de Atualização",
  ];

  return (
    <>
      <div className="flex flex-col">
        <NavMenu />
        <div className="flex-1 p-4 border rounded-[10px]  hover:shadow-md">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Carnê Leão
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Informações dos carnês leão gerados pelo sistema
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
          {states.dataEstatisticaCarneLeao?.length > 0 &&
            states.dataEstatisticaCarneLeao.map((card, index) => (
              <div
                key={card.title}
                className={
                  card.value > 0 && index === -1
                    ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                    : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                }
              >
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      {card.value > 0 && index === -1 ? (
                        <InformationCircleIcon
                          className="w-6 h-6 text-red-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <InformationCircleIcon
                          className="w-6 h-6 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        {card.value > 0 && index === -1 ? (
                          <dt className="truncate text-sm font-medium text-red-500">
                            {card.title}
                          </dt>
                        ) : (
                          <dt className="truncate text-sm font-medium text-gray-500">
                            {card.title}
                          </dt>
                        )}

                        <dd>
                          {card.value > 0 && index === -1 ? (
                            <div className="text-lg font-medium text-red-500">
                              {card.value}
                            </div>
                          ) : (
                            <div className="text-lg font-medium text-gray-900">
                              {card.value}
                            </div>
                          )}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {states.dataCarneLeao?.length > 0 ? (
          <>
            <div className=" p-4 border hover:shadow-md mt-5">
              {states.dataCarneLeao?.length > 0 && (
                <Table
                  title="Carnê Leão"
                  description="Registro das carnê leão cadastrados"
                  next={next}
                  previous={previous}
                  states={states}
                  setters={setters}
                  headers={headers}
                  infoTable={{
                    offset: states.offsetCarneLeao,
                    limit: states.limiteCarneLeao,
                  }}
                  dataTable={states.dataCarneLeao}
                  dowload={dowloadCarne}
                  enviarCarne={carneEnvioContabilidade}
                />
              )}
            </div>
          </>
        ) : (
          <div className="flex-1 p-12 border  hover:shadow-md mt-5">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Sem registros de carnê leão
            </h1>
          </div>
        )}
      </div>
    </>
  );
};

export default CarneLeao;
