import { useContext, useEffect, useState } from "react";
import { AuthContextApi } from "../../Context/api";
import { Link, useParams } from "react-router-dom";
import {
  ArchiveBoxIcon,
  CheckBadgeIcon,
  BriefcaseIcon,
  IdentificationIcon,
} from "@heroicons/react/24/outline";

const FinancieroIndex = () => {
  const { checkLogin } = useContext(AuthContextApi);
  const { token } = useParams();

  const primeiroLoad = async () => {
    await checkLogin();
  };

  useEffect(() => {
    primeiroLoad();
    return () => {
      primeiroLoad();
    };
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-[10px] overflow-hidden bg-white hover:shadow-md">
        <h1 className="text-3xl text-center font-bold">
          Painel Administrativo
        </h1>
        <p className="text-[18px] mt-1 text-center">
          Aqui você terá acesso ao painel administrativo do seu sistema
        </p>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        <div className="flex items-center justify-center">
          <Link
            to={`/administrativoIndex/${token}/categorias`}
            className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-[15px] overflow-hidden bg-white hover:shadow-md"
          >
            <ArchiveBoxIcon className="h-[30%] w-[30%] text-blue-600" />
            <h2 className="text-xl font-bold text-center">
              Categorias de Serviço
            </h2>
            <p className="text-center">
              Clique nesse cartão para acessar suas categorias de serviço.
            </p>
          </Link>
        </div>
        <div className="flex items-center justify-center">
          <Link
            to={`/administrativoIndex/${token}/servicos`}
            className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-[15px] overflow-hidden bg-white hover:shadow-md"
          >
            <BriefcaseIcon className="h-[30%] w-[30%] text-blue-600" />
            <h2 className="text-xl text-center font-bold">Serviços</h2>
            <p className="text-center">
              Clique nesse cartão para acessar os serviços que você oferta
            </p>
          </Link>
        </div>
        {localStorage.getItem("empresa") === "true" && (
          <div className="flex items-center justify-center">
            <Link
              to={`/administrativoIndex/${token}/prestadores`}
              className="w-full h-[100%] text-black p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-[15px] overflow-hidden bg-white hover:shadow-md"
            >
              <IdentificationIcon className="h-[30%] w-[30%] text-blue-600" />
              <h2 className="text-xl font-bold text-center">Psicologos</h2>
              <p className="text-center">
                Clique nesse cartão para ver os cadastros de psicologos do seu
                sistema.
              </p>
            </Link>
          </div>
        )}
        <div className="flex items-center justify-center">
          <Link
            to={`/administrativoIndex/${token}/execucoesServico`}
            className="w-full h-[100%] text-black p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-[15px] overflow-hidden bg-white hover:shadow-md"
          >
            <CheckBadgeIcon className="h-[30%] w-[30%] text-blue-600" />
            <h2 className="text-xl text-center font-bold">
              Serviços Executados
            </h2>
            <p className="text-center">
              Clique nesse cartão para acessar os serviços executados
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FinancieroIndex;
