import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Context";
import { AuthContextApi } from "../../../Context/api";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import Table from "../../../Components/Table/Usuarios";
import NavMenu from "../../../Components/NavMenu/Usuarios";
import ModalShow from "../../../Components/Modal/Usuarios/UsuarioShow";
import Modal from "../../../Components/Modal/Usuarios/Usuario";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const Usuarios = () => {
  const { states, setters } = useContext(AuthContext);
  const {
    checkLogin,
    getUsuariosDataSistema,
    getUsuarios,
    getUsuariosQuery,
    postUsuarios,
    putUsuarios,
    forgotPass,
    blockUnlockUsuarios,
  } = useContext(AuthContextApi);
  const { token } = useParams();
  const [query, setQuery] = useState("");

  const primeiroLoad = async () => {
    await getUsuariosDataSistema(token);
    await getUsuarios();
    await checkLogin();
  };

  const loadUsuarios = async () => {
    if (query !== "") {
      await getUsuariosQuery(query);
    } else {
      await getUsuarios();
    }
  };

  const next = async () => {
    if (states.limiteBuscaUsuarios) {
      if (states.offsetUsuarios + states.limiteUsuarios > 0) return;
      setters.setLimiteBuscaUsuarios(true);
    }
    setters.setOffsetUsuarios(states.offsetUsuarios + states.limiteUsuarios);
  };

  const previous = async () => {
    if (states.offsetUsuarios - states.limiteUsuarios < 0) return;
    if (states.limiteBuscaUsuarios) {
      setters.setLimiteBuscaUsuarios(false);
    }
    setters.setOffsetUsuarios(states.offsetUsuarios - states.limiteUsuarios);
  };

  const search = async () => {
    setters.setOffsetUsuarios(0);
    if (query === "") {
      await getUsuarios();
      return;
    }
    const response = await getUsuariosQuery(query);
    if (response === false) {
      setQuery("");
      await getUsuarios();
    }
  };

  const clear = async () => {
    setters.setOffsetUsuarios(0);
    setQuery("");
    await getUsuarios();
  };

  const addUsuarios = () => {
    setters.setIsEdit(false);
    setters.setUsuarios({});
    setters.setModalUsuarios(true);
  };

  const editUsuarios = (data) => {
    setters.setIsEdit(true);
    setters.setUsuarios(data);
    setters.setModalUsuarios(true);
  };

  const actionModal = async (data) => {
    setters.setModalUsuarios(false);
    if (states.isEdit) {
      setters.setIsEdit(false);
      await putUsuarios(data);
    } else {
      await postUsuarios(data, token);
    }
  };

  const blockUnlock = async (data) => {
    setters.setModalUsuarios(false);
    setters.setModalMsg(true);
    setters.setPerigoModalMsg(data.bloqueado);
    const msg = data.bloqueado ? "desbloquear" : "bloquear";
    setters.setMsgModalMsg(`Deseja mesmo ${msg} o usuário ${data.email}?`);
    setters.setDescModalMsg(
      `Após a confirmação o usuário será ${
        data.bloqueado ? "bloqueado" : "desbloqueado"
      }`
    );
    setters.setTitleButtonModalMsg("Confirmar");
    setters.setFunctionModalMsg(() => async () => {
      await blockUnlockUsuarios(data.id, data.bloqueado);
      setters.setModalMsg(false);
    });
  };

  const generateForgot = async (data) => {
    setters.setModalUsuarios(false);
    setters.setModalMsg(true);
    setters.setPerigoModalMsg(true);
    setters.setMsgModalMsg(
      `Deseja mesmo gerar link de recuperação para o usuário ${data.email}?`
    );
    setters.setDescModalMsg(
      `Após a confirmação o usuário receberá um e-mail com o link de recuperação`
    );
    setters.setTitleButtonModalMsg("Confirmar");
    setters.setFunctionModalMsg(() => async () => {
      const response = await forgotPass({ email: data.email });
      if (response === false) {
        toast.error("Erro ao gerar link de recuperação");
      } else {
        toast.success("Link de recuperação gerado com sucesso");
      }
      setters.setModalMsg(false);
    });
  };

  const showUsuarios = async (data) => {
    setters.setModalUsuariosShow(true);
    setters.setUsuarios(data);
  };

  // const [dataEstatisticaUsuarios, setDataEstatisticaUsuarios] = useState([])
  // const [dataUsuarios, setDataUsuarios] = useState([])
  // const [Usuarios, setUsuarios] = useState([])
  // const [offsetUsuarios, setOffsetUsuarios] = useState(0)
  // const [limiteUsuarios, setLimiteUsuarios] = useState(jsonConfig.limitDefault)
  // const [limiteBuscaUsuarios, setLimiteBuscaUsuarios] = useState(false)
  // const [modalUsuarios, setModalUsuarios] = useState(false)

  useEffect(() => {
    primeiroLoad();

    return () => {
      setQuery("");
      setters.setDataEstatisticaUsuarios([]);
      setters.setDataUsuarios([]);
      setters.setUsuarios({});
      setters.setOffsetUsuarios(0);
      setters.setLimiteBuscaUsuarios(false);
      setters.setModalUsuarios(false);
    };
  }, []);

  useEffect(() => {
    if (states.dataUsuarios?.length === 0) return;
    loadUsuarios();
  }, [states.offsetUsuarios]);

  //Nome, endereco, cidade, estado, cpf, telefone
  const headers = [
    "",
    "Nome",
    "Cpf",
    "Telefone",
    "E-mail",
    "Endereço",
    "Cep",
    "Cidade",
    "Estado",
    "E-mail varificado",
    "Bloqueado",
    "Permissão",
    "Data de Criação",
    "Data de Atualização",
  ];
  return (
    <>
      <div className="flex flex-col">
        <NavMenu />

        <div className="flex-1 p-4 border  hover:shadow-md">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Usuários
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Informações sobre os usuários do sistema
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 lg:grid-cols-4 mt-4">
          {states.dataEstatisticaUsuarios?.length > 0 &&
            states.dataEstatisticaUsuarios.map((card, index) => (
              <div
                key={card.title}
                className={
                  card.value > 0 && index === -1
                    ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                    : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                }
              >
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      {card.value > 0 && index === -1 ? (
                        <InformationCircleIcon
                          className="w-6 h-6 text-red-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <InformationCircleIcon
                          className="w-6 h-6 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        {card.value > 0 && index === -1 ? (
                          <dt className="truncate text-sm font-medium text-red-500">
                            {card.title}
                          </dt>
                        ) : (
                          <dt className="truncate text-sm font-medium text-gray-500">
                            {card.title}
                          </dt>
                        )}

                        <dd>
                          {card.value > 0 && index === -1 ? (
                            <div className="text-lg font-medium text-red-500">
                              {card.value}
                            </div>
                          ) : (
                            <div className="text-lg font-medium text-gray-900">
                              {card.value}
                            </div>
                          )}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="flex-1 p-12 border  hover:shadow-md mt-5">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Pesquisa por usuário
          </h1>
          <div className="flex flex-col mt-4">
            <p className="text-sm text-gray-700">Pesquise por e-mail</p>
            <div className="flex flex-col sm:flex-row mt-2">
              <input
                type="text"
                className=" flex w-full sm:w-[80%] mt-1 p-2 border-2 border-gray-200 rounded-md"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search();
                  }
                }}
              />
              <button
                className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                onClick={search}
              >
                Pesquisar
              </button>
              <button
                className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-green-600 text-white rounded-md hover:bg-green-500"
                onClick={clear}
              >
                Limpar
              </button>
            </div>
          </div>
        </div>
        {states.dataUsuarios?.length > 0 ? (
          <div className=" p-4 border hover:shadow-md mt-5">
            {states.dataUsuarios?.length > 0 && (
              <Table
                title="Usuários"
                description="Registro de usuários cadastrados no sistema"
                next={next}
                add={addUsuarios}
                blockUnlock={blockUnlock}
                forgot={generateForgot}
                show={showUsuarios}
                previous={previous}
                states={states}
                setters={setters}
                headers={headers}
                edit={editUsuarios}
                infoTable={{
                  offset: states.offsetUsuarios,
                  limit: states.limiteUsuarios,
                }}
                dataTable={states.dataUsuarios}
                query={query}
              />
            )}
          </div>
        ) : (
          <div className="flex-1 p-12 border  hover:shadow-md mt-5">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Sem registro de usuários
            </h1>
          </div>
        )}
      </div>
      <ModalShow states={states} setters={setters} />
      <Modal states={states} setters={setters} action={actionModal} />
    </>
  );
};

export default Usuarios;
