import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContextApi } from '../../Context/api.jsx';
import { AuthContext } from '../../Context/index.jsx';
import Financeiro from '../../Components/Financeiro/index.jsx'
import FinanceiroPlano from '../../Components/Financeiro/Plano/plano.jsx'
import moment from 'moment'

const FinancieroIndex = () => {
    const { checkLogin, financeiroGetInfo, financeiroGenerateRelatorio } = useContext(AuthContextApi)
    const { states, setters } = useContext(AuthContext)
    const { token } = useParams()



    const primeiroLoad = async () => {
        await checkLogin()
    }

    useEffect(() => {
        primeiroLoad()

        return () => {
            primeiroLoad()
        }

    }, [])
    if (localStorage.getItem('financeiro') === 'true') {
        return <FinanceiroPlano token={token} states={states} setters={setters} load={financeiroGetInfo} relatorio={financeiroGenerateRelatorio}/>
    } else {
        return <Financeiro token={token}  />
    }


}



export default FinancieroIndex;