import NavMenu from "../../../Components/NavMenu/Ajuda";

import administrativo from "../../../Assets/Administrativo/administrativo.png";
import addCategoria from "../../../Assets/Administrativo/addCategoria.png";
import addPsicologoAdm from "../../../Assets/Administrativo/addPsicologoAdm.png";
import addServicoAdm from "../../../Assets/Administrativo/addServicoAdm.png";
import servico from "../../../Assets/Administrativo/servicos.png";
import categoriasAdm from "../../../Assets/Administrativo/categoriasAdministrativo.png";
import desativarCategoriaAdm from "../../../Assets/Administrativo/desativarCategoriaAdm.png";
import desativarPsicologoAdm from "../../../Assets/Administrativo/desativarPsicologoAdm.png";
import desativarServicoAdm from "../../../Assets/Administrativo/desativarServicoAdm.png";
import editarCategoriaAdm from "../../../Assets/Administrativo/editarCategoriaAdm.png";
import editarPsicologoAdm from "../../../Assets/Administrativo/editarPsicologoAdm.png";
import editarServicoAdm from "../../../Assets/Administrativo/editarServicoAdm.png";
import psicologosAdm from "../../../Assets/Administrativo/psicologosAdm.png";
import visualizarCategoria from "../../../Assets/Administrativo/visualisarCategoriaAdm.png";
import visualizarPsicologo from "../../../Assets/Administrativo/visualisarPsicologoAdm.png";
import visualizarServico from "../../../Assets/Administrativo/visualisarServicoAdm.png";

const AjudaAdministrativo = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <NavMenu />
      <div className="mb-4 border flex-col flwx-w-full md:w-[100%] items-center justify-center p-4 border-1 border-gray-300 rounded-[10px] overflow-hidden bg-white hover:shadow-sm">
        <h1 className="text-3xl text-center font-bold">
          Painel de Ajuda: Administrativo
        </h1>
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela do painel administrativo do sistema, possui quatro cartões.
        </p>
        <hr />
        <img
          src={administrativo}
          alt="tela administrativo"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela de categorias de serviços.
        </p>
        <hr />
        <img
          src={categoriasAdm}
          alt="tela categorias de serviço"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">Adicionar Categoria.</p>
        <hr />
        <img
          src={addCategoria}
          alt="tela categorias de serviço"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela de visualizar os dados da categoria em questão.
        </p>
        <hr />
        <img
          src={visualizarCategoria}
          alt="tela categorias de serviço"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela de edição da categoria escolhida.
        </p>
        <hr />
        <img
          src={editarCategoriaAdm}
          alt="tela categorias de serviço"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela de desativar a categoria escolhida.
        </p>
        <hr />
        <img
          src={desativarCategoriaAdm}
          alt="tela desativar categorias de serviço"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      {/* Serviços */}
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">Tela de serviços.</p>
        <hr />
        <img
          src={servico}
          alt="tela serviço"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela de adicionar serviço.
        </p>
        <hr />
        <img
          src={addServicoAdm}
          alt="tela de adicionar serviço"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela de editar serviço.
        </p>
        <hr />
        <img
          src={editarServicoAdm}
          alt="tela de editar serviço"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela de desativar serviço.
        </p>
        <hr />
        <img
          src={desativarServicoAdm}
          alt="tela de desativar serviço"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      {/* Psicologos */}
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">Tela de Psicólogos.</p>
        <hr />
        <img
          src={psicologosAdm}
          alt="tela psicologo"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela de adicionar psicologo.
        </p>
        <hr />
        <img
          src={addPsicologoAdm}
          alt="tela de adicionar psicologo"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela de editar psicologo.
        </p>
        <hr />
        <img
          src={editarPsicologoAdm}
          alt="tela de editar psicologo"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela de desativar psicologo.
        </p>
        <hr />
        <img
          src={desativarPsicologoAdm}
          alt="tela de desativar psicologo"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

export default AjudaAdministrativo;
