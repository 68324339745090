import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import UploadFile from '../../Upload/ConversaArquivo'
import UploadImage from '../../Upload/ConversaImagem'




const Categoria = ({ states, setters, action }) => {

    const [titulo, setTitulo] = useState('')
    const [descricao, setDescricao] = useState('')
    const [haveLink, setHaveLink] = useState('')
    const [haveImg, setHaveImg] = useState('')
    const [haveFile, setHaveFile] = useState('')
    const [link, setLink] = useState('')
    const [file, setFile] = useState(null)
    const [image, setImage] = useState(null)

    const [errorTitulo, setErrorTitulo] = useState(false)
    const [errorDescricao, setErrorDescricao] = useState(false)
    const [errorHaveLink, setErrorHaveLink] = useState(false)
    const [errorHaveImg, setErrorHaveImg] = useState(false)
    const [errorHaveFile, setErrorHaveFile] = useState(false)
    const [errorLink, setErrorLink] = useState(false)
    const [errorFile, setErrorFile] = useState(false)
    const [errorImage, setErrorImage] = useState(false)

    const [errorImageMsg, setErrorImageMsg] = useState('Campo obrigatório')
    const [errorFileMsg, setErrorFileMsg] = useState('Campo obrigatório')


    const handleSave = () => {
        let error = false;

        if (titulo === '') {
            setErrorTitulo(true)
            error = true
        } else setErrorTitulo(false)

        if (descricao === '') {
            setErrorDescricao(true)
            error = true
        } else setErrorDescricao(false)

        if (haveLink === '') {
            setErrorHaveLink(true)
            error = true
        } else setErrorHaveLink(false)

        if (haveImg === '') {
            setErrorHaveImg(true)
            error = true
        } else setErrorHaveImg(false)

        if (haveFile === '') {
            setErrorHaveFile(true)
            error = true
        } else setErrorHaveFile(false)

        if (haveLink && link === '') {
            setErrorLink(true)
            error = true
        } else setErrorLink(false)

        if (haveImg && image === null) {
            setErrorImage(true)
            error = true
        } else {
            setErrorImage(false)
            setErrorImageMsg('Campo obrigatório')
        }

        if (haveFile && file === null) {
            setErrorFile(true)
            error = true
        } else {
            setErrorFile(false)
            setErrorFileMsg('Campo obrigatório')
        }





        if (error) {
            error = false
            return
        }
        const form = new FormData()
        form.append('titulo', titulo)
        form.append('descricao', descricao)
        form.append('file', haveFile ? file : undefined)
        form.append('image', haveImg ? image : undefined)
        form.append('link', haveLink ? link : undefined)
        form.append('clienteSecundario_id', states.Paciente.id)
        action(form)
    }


    useEffect(() => {
        if (states.modalConversasPaciente == false) {
            setTitulo('')
            setDescricao('')
            setLink('')
            setFile(null)
            setImage(null)
            setHaveLink('')
            setHaveImg('')
            setHaveFile('')
            setErrorTitulo(false)
            setErrorDescricao(false)
            setErrorHaveLink(false)
            setErrorHaveImg(false)
            setErrorHaveFile(false)
            setErrorLink(false)
            setErrorFile(false)
            setErrorImage(false)
            setErrorImageMsg('Campo obrigatório')
            setErrorFileMsg('Campo obrigatório')

        }
    }, [states.modalConversasPaciente])

    useEffect(() => {
        if (haveImg !== true) setImage(null)
        if (haveFile !== true) setFile(null)
        if (haveLink !== true) setLink('')
    }, [haveImg, haveLink, haveFile])


    return (
        <>
            <Transition.Root show={states.modalConversasPaciente} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setters.setModalConversasPaciente(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {/* Mexer na altura, só colocar o height na classname abaixo */}
                                <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => setters.setModalConversasPaciente(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className='flex-col border-b border-gray-900/10'>
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Conversa</h3>
                                        <div className='flex justify-center flex-col items-center gap-x-4 pb-4'>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                Adicione uma conversa com o paciente {states.Paciente.nome}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='pt-10 pl-10 pr-10'>
                                        <div className="space-y-12">

                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais da Conversa.</p>
                                                </div>
                                                <div className="sm:col-span-2">

                                                    <div className="grid grid-cols-1 gap-y-6 gap-x-6 ">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Título</label>
                                                            <input
                                                                type="text"
                                                                id="title"
                                                                name="title"
                                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                value={titulo}
                                                                maxLength={255}
                                                                onChange={(e) => setTitulo(e.target.value)}
                                                            />
                                                            {
                                                                errorTitulo && <p className="text-red-500 text-sm mt-1">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Descrição</label>
                                                            <textarea
                                                                id="title"
                                                                name="title"
                                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                value={descricao}
                                                                onChange={(e) => setDescricao(e.target.value)}
                                                            />
                                                            {
                                                                errorDescricao && <p className="text-red-500 text-sm mt-1">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Dados adicionais</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados extras para a conversa.</p>
                                                </div>
                                                <div className="sm:col-span-2">

                                                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-6 gap-x-6 ">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Deseja cadastrar algum link?</label>
                                                            <select
                                                                id="title"
                                                                name="title"
                                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                onChange={(e) => {
                                                                    const bool = e.target.value === 'true' ? true : e.target.value === 'false' ? false : ''
                                                                    setHaveLink(bool)
                                                                }}
                                                            >
                                                                <option value=''>Selecione</option>
                                                                <option value={false}>Não</option>
                                                                <option value={true}>Sim</option>
                                                            </select>
                                                            {
                                                                errorHaveLink && <p className="text-red-500 text-sm mt-1">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        {
                                                            haveLink &&
                                                            <div className="sm:col-span-2">
                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Digite o link que deseja</label>
                                                                <input
                                                                    type="text"
                                                                    id="title"
                                                                    name="title"
                                                                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                    value={link}
                                                                    onChange={(e) => setLink(e.target.value)}
                                                                />
                                                                {
                                                                    errorLink && <p className="text-red-500 text-sm mt-1">Campo obrigatório</p>
                                                                }
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-6 gap-x-6 mt-5">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Deseja cadastrar alguma imagem?</label>
                                                            <select
                                                                id="title"
                                                                name="title"
                                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                onChange={(e) => {
                                                                    const bool = e.target.value === 'true' ? true : e.target.value === 'false' ? false : ''
                                                                    setHaveImg(bool)
                                                                }}
                                                            >
                                                                <option value=''>Selecione</option>
                                                                <option value={false}>Não</option>
                                                                <option value={true}>Sim</option>
                                                            </select>
                                                            {
                                                                errorHaveImg && <p className="text-red-500 text-sm mt-1">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        {
                                                            haveImg &&
                                                            <div className="sm:col-span-2">
                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Insira a imagem que deseja</label>
                                                                <UploadImage
                                                                    setError={setErrorImage} setErrorFileMessage={setErrorImageMsg} setFile={setImage} file={image} />
                                                                {
                                                                    errorImage && <div className="text-red-500 text-sm mt-1">{errorImageMsg}</div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-6 gap-x-6 mt-5">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Deseja cadastrar alguma arquivo?</label>
                                                            <select
                                                                id="title"
                                                                name="title"
                                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                onChange={(e) => {
                                                                    const bool = e.target.value === 'true' ? true : e.target.value === 'false' ? false : ''
                                                                    setHaveFile(bool)
                                                                }}
                                                            >
                                                                <option value=''>Selecione</option>
                                                                <option value={false}>Não</option>
                                                                <option value={true}>Sim</option>
                                                            </select>
                                                            {
                                                                errorHaveFile && <p className="text-red-500 text-sm mt-1">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        {
                                                            haveFile &&
                                                            <div className="sm:col-span-2">
                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Insira o arquivo que deseja</label>
                                                                <UploadFile
                                                                    setError={setErrorFile} setErrorFileMessage={setErrorFileMsg} setFile={setFile} file={file} />
                                                                {
                                                                    errorFile && <p className="text-red-500 text-sm mt-1">{errorFileMsg}</p>
                                                                }
                                                            </div>
                                                        }
                                                    </div>




                                                </div>
                                            </div>

                                        </div>
                                        <div className="mt-6 flex items-center justify-end gap-x-6">
                                            <button type="button" className="text-sm font-semibold leading-5 text-white bg-blue-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                                                onClick={handleSave}
                                            >
                                                Salvar
                                            </button>
                                            <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                                onClick={() => setters.setModalConversasPaciente(false)}>
                                                Fechar
                                            </button>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}


export default Categoria;