import NavMenu from "../../../Components/NavMenu/Ajuda";

import financeiro from "../../../Assets/Financeiro/financeiro.png";
import comprovanteFatura from "../../../Assets/Financeiro/baixarComprovanteFaturaFinanceiro.png";
import baixarFatura from "../../../Assets/Financeiro/baixarFaturaFinanceiro.png";
import fatura from "../../../Assets/Financeiro/faturasFinanceiro.png";

const AjudaFinanceiro = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <NavMenu />
      <div className="mb-4 border flex-col flwx-w-full md:w-[100%] items-center justify-center p-4 border-1 border-gray-300 rounded-[10px] overflow-hidden bg-white hover:shadow-sm">
        <h1 className="text-3xl text-center font-bold">
          Painel de Ajuda: Financeiro
        </h1>
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Essa é a tela do painel financeiro, nela temos dois cartões.
        </p>
        <hr />
        <img
          src={financeiro}
          alt="tela dashboard"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          O primeiro cartão é de faturas.
        </p>
        <hr />
        <img
          src={fatura}
          alt="tela dashboard"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Nessa tela de faturas temos como baixar a fatura. O icone é o da nuvem
          com a setinha.
        </p>
        <hr />
        <img
          src={baixarFatura}
          alt="tela dashboard"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Nessa tela de faturas temos como baixar o comprovante de pagamento da
          fatura. O icone é o da nuvem.
        </p>
        <hr />
        <img
          src={comprovanteFatura}
          alt="tela dashboard"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      {/* falta a parte do carnê leão */}
    </div>
  );
};

export default AjudaFinanceiro;
