import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import './index.css'



const Prestador = ({ states, setters, action }) => {


    const [nome, setNome] = useState('')
    const [cpf, setCpf] = useState('')
    const [telefone, setTelefone] = useState('')
    const [email, setEmail] = useState('')
    const [codigoEntidadeRegistro, setCodigoEntidadeRegistro] = useState('')
    const [entidadeRegistro] = useState('CRP')
    const [categoria_id, setCategoria_id] = useState('')
    const [cep, setCep] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')

    const [errorNome, setErrorNome] = useState(false)
    const [errorCpf, setErrorCpf] = useState(false)
    const [errorTelefone, setErrorTelefone] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorCodigoEntidadeRegistro, setErrorCodigoEntidadeRegistro] = useState(false)
    const [errorCategoria_id, setErrorCategoria_id] = useState(false)
    const [errorCep, setErrorCep] = useState(false)
    const [errorNumero, setErrorNumero] = useState(false)
    const [errorComplemento, setErrorComplemento] = useState(false)


    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }

    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }

    const handleAction = () => {
        let error = false

        if (nome === '') {
            setErrorNome(true)
            error = true
        } else setErrorNome(false)

        if (email === '') {
            setErrorEmail(true)
            error = true
        } else setErrorEmail(false)

        if (cpf === '') {
            setErrorCpf(true)
            error = true
        } else setErrorCpf(false)

        if (telefone === '') {
            setErrorTelefone(true)
            error = true
        } else setErrorTelefone(false)

        if (codigoEntidadeRegistro === '') {
            setErrorCodigoEntidadeRegistro(true)
            error = true
        } else setErrorCodigoEntidadeRegistro(false)

        if (categoria_id === '') {
            setErrorCategoria_id(true)
            error = true
        } else setErrorCategoria_id(false)

        if (cep === '') {
            setErrorCep(true)
            error = true
        } else setErrorCep(false)

        if (numero === '') {
            setErrorNumero(true)
            error = true
        } else setErrorNumero(false)

        if (complemento === '') {
            setErrorComplemento(true)
            error = true
        } else setErrorComplemento(false)

        if (error) return;

        action({
            nome,
            cpf,
            telefone,
            email,
            codigoEntidadeRegistro,
            entidadeRegistro,
            categoria_id,
            cep,
            numero,
            complemento
        })



    }




    useEffect(() => {
        if (states.modalPrestadores === false) {
            setters.setPrestadores({})
            setters.setDataCategoriasForm([])
            setters.setOffsetCategorias(0)
            setNome('')
            setCpf('')
            setTelefone('')
            setEmail('')
            setCodigoEntidadeRegistro('')
            setCategoria_id('')
            setCep('')
            setNumero('')
            setComplemento('')
            setErrorNome(false)
            setErrorCpf(false)
            setErrorTelefone(false)
            setErrorEmail(false)
            setErrorCodigoEntidadeRegistro(false)
            setErrorCategoria_id(false)
            setErrorCep(false)
            setErrorNumero(false)
            setErrorComplemento(false)
        } else {
            if (states.isEdit) {
                const haveCategoria = states.dataCategoriasForm.find(categoria => categoria.id === states.Prestadores.categoria_id)
                if (!haveCategoria) {
                    setters.setDataCategoriasForm([...states.dataCategoriasForm, { id: states.Prestadores.categoria_id, nome: states.Prestadores.nome_categoria }])
                }
                setNome(states.Prestadores.nome)
                setCpf(states.Prestadores.cpf)
                setTelefone(states.Prestadores.telefone)
                setEmail(states.Prestadores.email)
                setCodigoEntidadeRegistro(states.Prestadores.codigoEntidadeRegistro)
                setCategoria_id(states.Prestadores.categoria_id)
                setCep(states.Prestadores.cep)
                setNumero(states.Prestadores.numero)
                setComplemento(states.Prestadores.complemento)
            } else {
                setters.setPrestadores({})
                setNome('')
                setCpf('')
                setTelefone('')
                setEmail('')
                setCodigoEntidadeRegistro('')
                setCategoria_id('')
                setCep('')
                setNumero('')
                setComplemento('')
                setErrorNome(false)
                setErrorCpf(false)
                setErrorTelefone(false)
                setErrorEmail(false)
                setErrorCodigoEntidadeRegistro(false)
                setErrorCategoria_id(false)
                setErrorCep(false)
                setErrorNumero(false)
                setErrorComplemento(false)
            }
        }

    }, [states.modalPrestadores])

    useEffect(() => {
        if (categoria_id === 'more') {
            setCategoria_id('')
            setters.setOffsetCategorias(states.offsetCategorias + states.limiteCategorias)
        }
    }, [categoria_id])


    return (
        <>
            <Transition.Root show={states.modalPrestadores} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setters.setModalPrestadores(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {/* Mexer na altura, só colocar o height na classname abaixo */}
                                <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => setters.setModalPrestadores(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className='flex-col border-b border-gray-900/10'>
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Psicólogo</h3>
                                        <div className='flex justify-center flex-col items-center gap-x-4 pb-4'>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                {
                                                    states.isEdit ? `Atualize os dados do psicólogo ${states.Prestadores.nome}` : 'Adicione um novo psicólogo'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className='pt-10 pl-10 pr-10'>
                                        <div className="space-y-12">

                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do psicólogo.</p>
                                                </div>
                                                <div className="sm:col-span-2">

                                                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-4 l gap-x-6 ">
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                                                                <input
                                                                    type="text"
                                                                    value={nome}
                                                                    onChange={(e) => setNome(e.target.value)}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorNome && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>

                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">E-mail</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    value={email}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorEmail && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">CPF</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    value={formatToCpfOrCnpj(cpf)}
                                                                    onChange={(e) => setCpf(e.target.value.replace(/\D/g, ''))}
                                                                    maxLength={14}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorCpf && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    value={formatToPhoneBrl(telefone)}
                                                                    onChange={(e) => setTelefone(e.target.value.replace(/\D/g, ''))}
                                                                    maxLength={15}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorTelefone && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Registro</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <span className="mt-1 text-sm leading-6 text-gray-600 mr-2">{entidadeRegistro}</span>
                                                                <input
                                                                    type="text"
                                                                    value={codigoEntidadeRegistro}
                                                                    onChange={(e) => setCodigoEntidadeRegistro(e.target.value)}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorCodigoEntidadeRegistro && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Categoria</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <select
                                                                    value={categoria_id}
                                                                    onChange={(e) => setCategoria_id(e.target.value)}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                >
                                                                    <option value="">Selecione</option>
                                                                    {
                                                                        states.dataCategoriasForm.map(categoria => (
                                                                            <option key={categoria.id} value={categoria.id}>{categoria.nome}</option>
                                                                        ))
                                                                    }
                                                                    {
                                                                        (states.dataCategoriasForm.length >= states.limiteCategorias && !states.limiteBuscaCategorias) &&
                                                                        <option value="more">Carregar mais</option>
                                                                    }
                                                                </select>
                                                            </div>
                                                            {
                                                                errorCategoria_id && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações de localização</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados de localização.</p>
                                                </div>
                                                <div className="sm:col-span-2">

                                                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 l gap-x-6 ">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Cep</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    value={formatCep(cep)}
                                                                    onChange={(e) => setCep(e.target.value.replace(/\D/g, ''))}
                                                                    maxLength={9}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorCep && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Número</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    value={numero}
                                                                    onChange={(e) => setNumero(e.target.value.replace(/\D/g, ''))}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorNumero && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Complemento</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    value={complemento}
                                                                    onChange={(e) => setComplemento(e.target.value)}
                                                                    className="mt-1 text-sm leading-6 text-gray-600 w-full input-no-focus"
                                                                />
                                                            </div>
                                                            {
                                                                errorComplemento && <p className="text-red-500 text-xs mt-2">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 flex items-center justify-end gap-x-6">
                                            <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                                onClick={() => setters.setModalPrestadores(false)}>
                                                Fechar
                                            </button>
                                            <button type="button" className="text-sm font-semibold leading-5 text-white bg-blue-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                                                onClick={handleAction}>
                                                {
                                                    states.isEdit ? 'Atualizar' : 'Adicionar'
                                                }
                                            </button>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}


export default Prestador;