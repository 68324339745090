import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { jsonConfig } from '../../../Config'
import moment from 'moment'

const ModalConcluirConfiguracao = ({ states, setters, action, out, empresa }) => {




    const [nome, setNome] = useState('')
    const [cpf_cnpj, setCpfCnpj] = useState('')
    const [cep, setCep] = useState('')
    const [telefone, setTelefone] = useState('')
    const [email, setEmail] = useState('')
    const [complemento, setComplemento] = useState('')
    const [numero, setNumero] = useState('')


    const [errorNome, setErrorNome] = useState(false)
    const [errorCpfCnpj, setErrorCpfCnpj] = useState(false)
    const [errorCep, setErrorCep] = useState(false)
    const [errorTelefone, setErrorTelefone] = useState(false)
    const [errorEmail, setErrorEmail] = useState(false)
    const [errorComplemento, setErrorComplemento] = useState(false)
    const [errorNumero, setErrorNumero] = useState(false)

    const [criacaoPerfil, setCriacaoPerfil] = useState(false)
    const [meuPerfil, setMeuPerfil] = useState(false)
    const [errorSelecaoPerfil, serErrorSelecaoPerfil] = useState(false)

    const [crp, setCrp] = useState('')
    const [errorCrp, setErrorCrp] = useState(false)




    const handleSubmit = async () => {
        let error = false
        let myProfile = false

        let obj = {};

        if (!empresa) {
            if (crp === '') {
                setErrorCrp(true)
                error = true
            } else setErrorCrp(false)

            obj = {
                codigoEntidadeRegistro: crp,
                entidadeRegistro: jsonConfig.entidadeRegistro,
                categoria_id: states.categoriaServico?.categoria.id,
                perfil_id: localStorage.getItem('perfil')
            }
            myProfile = true;
        } else {
            if (crp === '') {
                setErrorCrp(true)
                error = true
            } else setErrorCrp(false)

            if (meuPerfil === false && criacaoPerfil === false) {
                serErrorSelecaoPerfil(true)
                error = true
            } else serErrorSelecaoPerfil(false)

            if (criacaoPerfil) {
                myProfile = false;
                if (nome === '') {
                    setErrorNome(true)
                    error = true
                } else setErrorNome(false)

                if (cpf_cnpj === '') {
                    setErrorCpfCnpj(true)
                    error = true
                } else setErrorCpfCnpj(false)

                if (cep === '') {
                    setErrorCep(true)
                    error = true
                } else setErrorCep(false)

                if (telefone === '') {
                    setErrorTelefone(true)
                    error = true
                } else setErrorTelefone(false)

                if (email === '') {
                    setErrorEmail(true)
                    error = true
                } else setErrorEmail(false)

                if (complemento === '') {
                    setErrorComplemento(true)
                    error = true
                } else setErrorComplemento(false)

                if (numero === '') {
                    setErrorNumero(true)
                    error = true
                } else setErrorNumero(false)

                obj = {
                    nome: nome,
                    cpf: cpf_cnpj,
                    cep: cep,
                    telefone: telefone,
                    email: email,
                    complemento: complemento,
                    numero: numero,
                    categoria_id: states.categoriaServico?.categoria.id,
                    codigoEntidadeRegistro: crp,
                    entidadeRegistro: jsonConfig.entidadeRegistro,
                }
            } else {
                myProfile = true;
                obj = {
                    codigoEntidadeRegistro: crp,
                    entidadeRegistro: jsonConfig.entidadeRegistro,
                    categoria_id: states.categoriaServico?.categoria.id,
                    perfil_id: localStorage.getItem('perfil')
                }

            }
        }


        if (error) {
            error = false
            myProfile = false
            return
        }
        action(obj, myProfile)

    }


    const formatTelefone = (value) => {
        if (value?.length === 11) {
            return value?.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
        } else {
            return value?.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
        }
    }

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }


    useEffect(() => {
        if (states.modalConfiguracao === false) {
            setCrp('')
            setNome('')
            setCpfCnpj('')
            setCep('')
            setTelefone('')
            setEmail('')
            setComplemento('')
            setNumero('')
            setErrorCep(false)
            setErrorCpfCnpj(false)
            setErrorEmail(false)
            setErrorNome(false)
            setErrorComplemento(false)
            setErrorNumero(false)
            setErrorTelefone(false)
            setErrorCrp(false)
            setMeuPerfil(false)
            setCriacaoPerfil(false)
        }

    }, [states.modalConfiguracao])




    useEffect(() => {
        if (meuPerfil) {
            setCriacaoPerfil(false)
            setNome('')
            setCpfCnpj('')
            setCep('')
            setTelefone('')
            setEmail('')
            setComplemento('')
            setNumero('')
            setErrorCep(false)
            setErrorCpfCnpj(false)
            setErrorEmail(false)
            setErrorNome(false)
            setErrorComplemento(false)
            setErrorNumero(false)
            setErrorTelefone(false)
        } else {
            setMeuPerfil(false)
        }
    }, [meuPerfil]);

    useEffect(() => {
        if (criacaoPerfil) {
            setMeuPerfil(false)
        } else {
            setCriacaoPerfil(false)
        }
    }, [criacaoPerfil]);



    return (
        <Transition.Root show={states.modalConfiguracao} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => null}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* Mexer na altura, só colocar o height na classname abaixo */}
                            <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                        onClick={() => out()}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className='flex-col border-b border-gray-900/10'>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900 text-center">Configuração de prestador</h3>
                                    <p className="flex mt-1 text-sm text-gray-500 justify-center mb-5">
                                        {
                                            empresa ? 'Preencha os campos com seus dados de presatação de serviço ou adicione um novo prestador' : 'Preencha os campos com os seus dados de prestação de serviço'
                                        }
                                    </p>
                                </div>


                                <div className='pt-10 pl-10 pr-10 '>
                                    <div className="space-y-12">
                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                            <div>
                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais de registro do prestaor.</p>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-4">
                                                    <div className="sm:col-span-1">
                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Entidade de registo</label>
                                                        <input
                                                            type="text"
                                                            className="mt-1 block px-3 w-full py-2 border bg-gray-200 border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            disabled
                                                            value={jsonConfig.entidadeRegistro}
                                                        />
                                                    </div>
                                                    <div className="sm:col-span-3">
                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Digite o {jsonConfig.entidadeRegistro}</label>
                                                        <input
                                                            type="text"
                                                            id="descricao"
                                                            name="descricao"
                                                            value={crp}
                                                            maxLength={255}
                                                            onChange={e => setCrp(e.target.value)}
                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            placeholder={`Digite o ${jsonConfig.entidadeRegistro}`}
                                                        />
                                                        <span className="text-red-500 text-sm">{errorCrp && 'Campo obrigatório'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* AQUI E APENAS PARA EMPRESA, POREM JÁ SERÁ REALIZADO */}
                                    {/* PEGAR AS 2 LINHAS DO MODAL DE CLIENTES QUE RESOLVE A PARADA */}
                                    {
                                        empresa && (<>
                                            <div className="space-y-12 mt-5">
                                                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                    <div>
                                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Selecione a opção de cadastro</h2>
                                                        <p className="mt-1 text-sm leading-6 text-gray-600">Selecione a opção de cadastro.</p>
                                                    </div>

                                                    <div className="sm:col-span-2">
                                                        <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-4">
                                                            <div className="sm:col-span-1 ml-4">
                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Utilizar meu perfil</label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="title"
                                                                    name="title"
                                                                    onChange={e => {
                                                                        setMeuPerfil(true)
                                                                    }}
                                                                    checked={meuPerfil}
                                                                    className="mt-3  block px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm scale-150"
                                                                />
                                                            </div>
                                                            <div className="sm:col-span-1 sm:ml-0 ml-4">
                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Cadastrar novo perfil</label>
                                                                <input
                                                                    type="checkbox"
                                                                    id="title"
                                                                    name="title"
                                                                    onChange={e => {
                                                                        setCriacaoPerfil(true)
                                                                    }}
                                                                    checked={criacaoPerfil}
                                                                    className="mt-3   block px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm scale-150"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mt-4 ml-3">
                                                            {
                                                                errorSelecaoPerfil && <span className="text-red-500 text-sm ">Selecione uma das opções</span>
                                                            }
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                            {
                                                criacaoPerfil && (<>
                                                    <div className="space-y-12 mt-5">
                                                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                            <div>
                                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações do prestador</h2>
                                                                <p className="mt-1 text-sm leading-6 text-gray-600">Dados de cadastro do prestador.</p>
                                                            </div>
                                                            <div className="sm:col-span-2">
                                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-4">
                                                                    <div className="sm:col-span-1">
                                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                                        <input
                                                                            type="text"
                                                                            id="title"
                                                                            name="title"
                                                                            value={nome}
                                                                            maxLength={255}
                                                                            onChange={e => {
                                                                                if (e.target.value === '') setErrorNome(true)
                                                                                else setErrorNome(false)
                                                                                setNome(e.target.value)
                                                                            }}
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            placeholder="Digite o nome da entidade"
                                                                        />
                                                                        <span className="text-red-500 text-sm">{errorNome && 'Campo obrigatório'}</span>
                                                                    </div>
                                                                    <div className="sm:col-span-1">
                                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Cpf</label>
                                                                        <input
                                                                            type="text"
                                                                            id="descricao"
                                                                            name="descricao"
                                                                            value={formatToCpfOrCnpj(cpf_cnpj)}
                                                                            maxLength={11}
                                                                            onChange={e => {
                                                                                if (e.target.value === '') setErrorCpfCnpj(true)
                                                                                else setErrorCpfCnpj(false)
                                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                                setCpfCnpj(onlyNumber)
                                                                            }}
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            placeholder="Digite o cpf"
                                                                        />
                                                                        <span className="text-red-500 text-sm">{errorCpfCnpj && 'Campo obrigatório'}</span>
                                                                    </div>
                                                                    <div className="sm:col-span-1">
                                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Cep</label>
                                                                        <input
                                                                            type="text"
                                                                            id="descricao"
                                                                            name="descricao"
                                                                            value={formatCep(cep)}
                                                                            maxLength={8}
                                                                            onChange={e => {
                                                                                if (e.target.value === '') setErrorCep(true)
                                                                                else setErrorCep(false)
                                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                                setCep(onlyNumber)
                                                                            }}
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            placeholder="Digite o cep"
                                                                        />
                                                                        <span className="text-red-500 text-sm">{errorCep && 'Campo obrigatório'}</span>
                                                                    </div>
                                                                    <div className="sm:col-span-1">
                                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                                                        <input
                                                                            type="text"
                                                                            id="descricao"
                                                                            name="descricao"
                                                                            value={formatTelefone(telefone)}
                                                                            maxLength={15}
                                                                            onChange={e => {
                                                                                if (e.target.value === '') setErrorTelefone(true)
                                                                                else setErrorTelefone(false)
                                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                                setTelefone(onlyNumber)
                                                                            }}
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            placeholder="Digite o telefone"
                                                                        />
                                                                        <span className="text-red-500 text-sm">{errorTelefone && 'Campo obrigatório'}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-3 mt-8">
                                                                    <div className="sm:col-span-1">
                                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">E-mail</label>
                                                                        <input
                                                                            type="text"
                                                                            id="title"
                                                                            name="title"
                                                                            value={email}
                                                                            maxLength={255}
                                                                            onChange={e => {
                                                                                if (e.target.value === '') setErrorEmail(true)
                                                                                else setErrorEmail(false)
                                                                                setEmail(e.target.value)
                                                                            }}
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            placeholder="Digite o e-mail do responsável"
                                                                        />
                                                                        <span className="text-red-500 text-sm">{errorEmail && 'Campo obrigatório'}</span>
                                                                    </div>
                                                                    <div className="sm:col-span-1">
                                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Complemento</label>
                                                                        <input
                                                                            type="text"
                                                                            id="descricao"
                                                                            name="descricao"
                                                                            value={complemento}
                                                                            maxLength={255}
                                                                            onChange={e => {
                                                                                if (e.target.value === '') setErrorComplemento(true)
                                                                                else setErrorComplemento(false)
                                                                                setComplemento(e.target.value)
                                                                            }}
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            placeholder="Digite o complemento"
                                                                        />
                                                                        <span className="text-red-500 text-sm">{errorComplemento && 'Campo obrigatório'}</span>
                                                                    </div>
                                                                    <div className="sm:col-span-1">
                                                                        <label htmlFor="descricao" className="block text-sm font-semibold leading-5 text-gray-700">Número do local</label>
                                                                        <input
                                                                            type="text"
                                                                            id="descricao"
                                                                            name="descricao"
                                                                            value={numero}
                                                                            maxLength={255}
                                                                            onChange={e => {
                                                                                if (e.target.value === '') setErrorNumero(true)
                                                                                else setErrorNumero(false)
                                                                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                                setNumero(onlyNumber)
                                                                            }}
                                                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                            placeholder="Digite o número do local"
                                                                        />
                                                                        <span className="text-red-500 text-sm">{errorNumero && 'Campo obrigatório'}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)
                                            }
                                        </>)
                                    }






                                    <div className="mt-6 flex items-center justify-end gap-x-6">
                                        <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                            onClick={() => out()}>
                                            Cancelar
                                        </button>
                                        <button
                                            onClick={handleSubmit}
                                            type="button"
                                            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                        >
                                            Finalizar configuração
                                        </button>
                                    </div>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalConcluirConfiguracao
