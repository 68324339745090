import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const Navbar = () => {
  const { token } = useParams();
  const nav = [
    { name: "Painel", href: `/administrativoIndex/${token}`, current: false },
    {
      name: "Categorias",
      href: `/administrativoIndex/${token}/categorias`,
      current: false,
    },
    {
      name: "Serviços",
      href: `/administrativoIndex/${token}/servicos`,
      current: false,
    },
    {
      name: "Psicólogos",
      href: `/administrativoIndex/${token}/prestadores`,
      current: false,
    },
    {
      name: "Execuções de serviço",
      href: `/administrativoIndex/${token}/execucoesServico`,
      current: false,
    },
  ];
  const [navigation, setNavigation] = useState(nav);

  const currentItemSecondary = () => {
    const updated = navigation.map((item) => {
      if (item.href === window.location.pathname) {
        return { ...item, current: true };
      } else {
        return { ...item, current: false };
      }
    });

    setNavigation(updated);
  };

  useEffect(() => {
    currentItemSecondary();
  }, []);

  return (
    <>
      <div className="hidden lg:flex flex-col w-[100%] flex items-center justify-center mb-4">
        <nav className="bg-white w-[100%] border rounded-md">
          <div className="container mx-auto px-4">
            <div className="flex items-center justify-center h-16">
              <div className="flex space-x-1 items-center justify-center">
                {navigation?.map((item, index) => {
                  if (
                    localStorage.getItem("empresa") === "true" &&
                    item.name === "Psicólogos"
                  ) {
                    return (
                      <Link
                        key={index}
                        to={item.href}
                        className={`${
                          item.current
                            ? "bg-gray-200 text-gray-900"
                            : "text-gray-500 hover:bg-gray-300"
                        } px-3 py-2 rounded-md text-sm font-semibold`}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    );
                  } else if (
                    localStorage.getItem("empresa") === "false" &&
                    item.name === "Psicólogos"
                  ) {
                    return <></>;
                  } else {
                    return (
                      <Link
                        key={index}
                        to={item.href}
                        className={`${
                          item.current
                            ? "bg-gray-200 text-gray-900"
                            : "text-gray-500 hover:bg-gray-300"
                        } px-3 py-2 rounded-md text-sm font-semibold`}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </nav>
      </div>

      <Popover className="relative lg:hidden lg:flex flex-col w-[100%] px-4 sm:px-6 lg:px-8 mb-4 flex items-center justify-center">
        <Popover.Button className="inline-flex items-center text-sm font-semibold text-gray-900">
          <div className="flex items-center justify-center">
            <div className="bg-white border justify-center items-center flex border-gray-300 rounded-md p-5 h-[2em] w-[23rem]">
              <div className="flex items-center justify-center">
                <h2 className="text-lg font-semibold ">
                  Clique para abrir o menu
                </h2>
                <ChevronDownIcon
                  className="ml-6 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel>
            <div className="w-screen max-w-sm flex-auto rounded-3xl p-4 text-sm leading-6 shadow-md bg-gray-50 border border-gray-200">
              {navigation?.map((item) => (
                <div
                  key={item.name}
                  className="relative rounded-lg p-4 hover:bg-gray-50"
                >
                  <Link to={item.href} className="font-semibold text-gray-900">
                    {item.name}
                    <span className="absolute inset-0" />
                  </Link>
                  <p className="mt-1 text-gray-600">
                    Acesse a área de {item.name}
                  </p>
                </div>
              ))}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
};

export default Navbar;
