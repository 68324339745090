import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import RouterWrapper from "./auth";

//NO AUTH
import Login from "../Views/Login";
import RecuperarSenha from "../Views/RecuperacaoSenha";
import Page404 from "../Views/404";
import TrocaEmail from "../Views/TrocaEmail";
import Autenticar from "../Views/AutenticarEmail";

import CompletarConfiguracao from "../Views/CompletarConfiguracao";

//AUTH
import Dashboard from "../Views/Dashboard";

import Perfil from "../Views/Perfil";

import ConfiguracoesIndex from "../Views/Configuracoes";
import ConfiguracoesNegocio from "../Views/Configuracoes/Negocio";
import ConfiguracoesContabilidade from "../Views/Configuracoes/Contabilidade";
import ConfiguracoesInformacoes from "../Views/Configuracoes/Configuracoes";

import FinanceiroIndex from "../Views/Financeiro";
import FinanceiroFaturas from "../Views/Financeiro/Faturas";
import FinanceiroCarnes from "../Views/Financeiro/Carnes";
import FinanceiroRelatorios from "../Views/Financeiro/Relatorios";

import UsuariosIndex from "../Views/Usuario";
import Usuarios from "../Views/Usuario/Usuarios";
import UsuariosRecuperacao from "../Views/Usuario/RecuperacaoSenha";

import AdministrativoIndex from "../Views/Adminstrativo";
import AdministrativoCategorias from "../Views/Adminstrativo/Categorias";
import AdministrativoServicos from "../Views/Adminstrativo/Servicos";
import AdministrativoPrestadores from "../Views/Adminstrativo/Prestadores";
import AdministrativoExecucoesServicos from "../Views/Adminstrativo/ExecucoesServicos";

import PacientesIndex from "../Views/ClientesSecundario";
import Paciente from "../Views/ClientesSecundario/Cliente";
import PacienteProntuario from "../Views/ClientesSecundario/Cliente/Prontuario";
import PacienteConversas from "../Views/ClientesSecundario/Cliente/Conversas";
import PacienteConversa from "../Views/ClientesSecundario/Cliente/Conversa";
import PacienteProntuarioAtendimento from "../Views/ClientesSecundario/Cliente/ProntuarioAtendimento";

import MeusPacientesIndex from "../Views/MeusClientes";
import MeuPaciente from "../Views/MeusClientes/Cliente";
import MeuPacienteProntuario from "../Views/MeusClientes/Cliente/Prontuario";
import MeuPacienteConversas from "../Views/MeusClientes/Cliente/Conversas";
import MeuPacienteConversa from "../Views/MeusClientes/Cliente/Conversa";
import MeuPacienteProntuarioAtendimento from "../Views/MeusClientes/Cliente/ProntuarioAtendimento";

import Servicos from "../Views/Servicos";
import ServicoAtendimento from "../Views/Servicos/ProntuarioAtendimento";

import Agenda from "../Views/Agenda";
import MinhaAgenda from "../Views/MinnhaAgenda";

import AjudaIndex from "../Views/Ajuda";
import AjudaDashboard from "../Views/Ajuda/Dashboard";
import AjudaAgenda from "../Views/Ajuda/Agenda";
import AjudaPacientes from "../Views/Ajuda/Pacientes";
import AjudaAdministrativo from "../Views/Ajuda/Administrativo";
import AjudaFinanceiro from "../Views/Ajuda/Financeiro";
import AjudaUsuarios from "../Views/Ajuda/Usuarios";
import AjudaConfiguracoes from "../Views/Ajuda/Configuracoes";
import AjudaPerfil from "../Views/Ajuda/Perfil";

const Router = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const RouterMeusPacientes = () => {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <RouterWrapper component={MeusPacientesIndex} isPrivate={false} />
          }
        />
        <Route
          path="/paciente/:id"
          element={<RouterWrapper component={MeuPaciente} isPrivate={false} />}
        />
        <Route
          path="/paciente/:id/prontuario/:prontuario"
          element={
            <RouterWrapper
              component={MeuPacienteProntuario}
              isPrivate={false}
            />
          }
        />
        <Route
          path="/paciente/:id/conversas"
          element={
            <RouterWrapper component={MeuPacienteConversas} isPrivate={false} />
          }
        />
        <Route
          path="/paciente/:id/conversa/:idConversa"
          element={
            <RouterWrapper component={MeuPacienteConversa} isPrivate={false} />
          }
        />
        <Route
          path="/paciente/:id/atendimento/:prontuario"
          element={
            <RouterWrapper
              component={MeuPacienteProntuarioAtendimento}
              isPrivate={false}
            />
          }
        />
      </Routes>
    );
  };

  const RouterConfiguracoes = () => {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <RouterWrapper component={ConfiguracoesIndex} isPrivate={false} />
          }
        />
        <Route
          path="/negocio"
          element={
            <RouterWrapper component={ConfiguracoesNegocio} isPrivate={false} />
          }
        />
        <Route
          path="/contabilidade"
          element={
            <RouterWrapper
              component={ConfiguracoesContabilidade}
              isPrivate={false}
            />
          }
        />
        <Route
          path="/informacoes"
          element={
            <RouterWrapper
              component={ConfiguracoesInformacoes}
              isPrivate={false}
            />
          }
        />
      </Routes>
    );
  };

  const RouterFinanceiro = () => {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <RouterWrapper component={FinanceiroIndex} isPrivate={false} />
          }
        />
        <Route
          path="/faturas"
          element={
            <RouterWrapper component={FinanceiroFaturas} isPrivate={false} />
          }
        />
        <Route
          path="/carnes"
          element={
            <RouterWrapper component={FinanceiroCarnes} isPrivate={false} />
          }
        />
        <Route
          path="/relatorios"
          element={
            <RouterWrapper component={FinanceiroRelatorios} isPrivate={false} />
          }
        />
      </Routes>
    );
  };

  const RouterUsuarios = () => {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <RouterWrapper component={UsuariosIndex} isPrivate={false} />
          }
        />
        <Route
          path="/usuarios"
          element={<RouterWrapper component={Usuarios} isPrivate={false} />}
        />
        <Route
          path="/recuperacaoSenha"
          element={
            <RouterWrapper component={UsuariosRecuperacao} isPrivate={false} />
          }
        />
      </Routes>
    );
  };

  const RouterAdministrativo = () => {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <RouterWrapper component={AdministrativoIndex} isPrivate={false} />
          }
        />
        <Route
          path="/categorias"
          element={
            <RouterWrapper
              component={AdministrativoCategorias}
              isPrivate={false}
            />
          }
        />
        <Route
          path="/servicos"
          element={
            <RouterWrapper
              component={AdministrativoServicos}
              isPrivate={false}
            />
          }
        />
        <Route
          path="/prestadores"
          element={
            <RouterWrapper
              component={AdministrativoPrestadores}
              isPrivate={false}
            />
          }
        />
        <Route
          path="/execucoesServico"
          element={
            <RouterWrapper
              component={AdministrativoExecucoesServicos}
              isPrivate={false}
            />
          }
        />
      </Routes>
    );
  };

  const RouterPacientes = () => {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <RouterWrapper component={PacientesIndex} isPrivate={false} />
          }
        />
        <Route
          path="/paciente/:id"
          element={<RouterWrapper component={Paciente} isPrivate={false} />}
        />
        <Route
          path="/paciente/:id/prontuario/:prontuario"
          element={
            <RouterWrapper component={PacienteProntuario} isPrivate={false} />
          }
        />
        <Route
          path="/paciente/:id/conversas"
          element={
            <RouterWrapper component={PacienteConversas} isPrivate={false} />
          }
        />
        <Route
          path="/paciente/:id/conversa/:idConversa"
          element={
            <RouterWrapper component={PacienteConversa} isPrivate={false} />
          }
        />
        <Route
          path="/paciente/:id/atendimento/:prontuario"
          element={
            <RouterWrapper
              component={PacienteProntuarioAtendimento}
              isPrivate={false}
            />
          }
        />
      </Routes>
    );
  };

  const RouterAjuda = () => {
    return (
      <Routes>
        <Route
          path="*"
          element={<RouterWrapper component={AjudaIndex} isPrivate={false} />}
        />
        <Route
          path="/dashboard"
          element={
            <RouterWrapper component={AjudaDashboard} isPrivate={false} />
          }
        />
        <Route
          path="/agenda"
          element={<RouterWrapper component={AjudaAgenda} isPrivate={false} />}
        />
        <Route
          path="/pacientes"
          element={
            <RouterWrapper component={AjudaPacientes} isPrivate={false} />
          }
        />
        <Route
          path="/administrativo"
          element={
            <RouterWrapper component={AjudaAdministrativo} isPrivate={false} />
          }
        />
        <Route
          path="/financeiro"
          element={
            <RouterWrapper component={AjudaFinanceiro} isPrivate={false} />
          }
        />
        <Route
          path="/usuarios"
          element={
            <RouterWrapper component={AjudaUsuarios} isPrivate={false} />
          }
        />
        <Route
          path="/configuracoes"
          element={
            <RouterWrapper component={AjudaConfiguracoes} isPrivate={false} />
          }
        />
        <Route
          path="/perfil"
          element={<RouterWrapper component={AjudaPerfil} isPrivate={false} />}
        />
      </Routes>
    );
  };

  return (
    <Routes>
      <Route
        path="/meusPacientes/:token/*"
        element={
          <RouterWrapper component={RouterMeusPacientes} isPrivate={true} />
        }
      />
      <Route
        path="/pacientes/:token/*"
        element={<RouterWrapper component={RouterPacientes} isPrivate={true} />}
      />
      <Route
        path="/administrativoIndex/:token/*"
        element={
          <RouterWrapper component={RouterAdministrativo} isPrivate={true} />
        }
      />
      <Route
        path="/usuariosIndex/:token/*"
        element={<RouterWrapper component={RouterUsuarios} isPrivate={true} />}
      />
      <Route
        path="/financeiroIndex/:token/*"
        element={
          <RouterWrapper component={RouterFinanceiro} isPrivate={true} />
        }
      />
      <Route
        path="/configuracoesIndex/:token/*"
        element={
          <RouterWrapper component={RouterConfiguracoes} isPrivate={true} />
        }
      />
      <Route
        path="/servicos/:token/paciente/:id/atendimento/:prontuario"
        element={
          <RouterWrapper component={ServicoAtendimento} isPrivate={true} />
        }
      />
      <Route
        path="/servicos/:token"
        element={<RouterWrapper component={Servicos} isPrivate={true} />}
      />
      <Route
        path="/agenda/:token"
        element={<RouterWrapper component={Agenda} isPrivate={true} />}
      />
      <Route
        path="/minhaAgenda/:token"
        element={<RouterWrapper component={MinhaAgenda} isPrivate={true} />}
      />

      <Route
        path="/autenticarEmail/:cliente/:token"
        element={<RouterWrapper component={Autenticar} isPrivate={false} />}
      />
      <Route
        path="/recuperarSenha/:cliente/:token"
        element={<RouterWrapper component={RecuperarSenha} isPrivate={false} />}
      />
      <Route
        path="/trocarEmail/:cliente/:token"
        element={<RouterWrapper component={TrocaEmail} isPrivate={false} />}
      />

      <Route
        path="/perfil/:token"
        element={<RouterWrapper component={Perfil} isPrivate={true} />}
      />
      <Route
        path="/dashboard/:token"
        element={<RouterWrapper component={Dashboard} isPrivate={true} />}
      />
      <Route
        path="/completarConfiguracao/:token"
        element={
          <RouterWrapper component={CompletarConfiguracao} isPrivate={true} />
        }
      />
      <Route
        path="/plataforma/:token"
        element={<RouterWrapper component={Login} isPrivate={false} />}
      />
      <Route path="*" element={<Page404 />} />
      <Route
        path="/ajuda/:token/*"
        element={<RouterWrapper component={RouterAjuda} isPrivate={true} />}
      />
    </Routes>
  );
};

export default Router;
