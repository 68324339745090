import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'





const Categoria = ({ states, setters, action }) => {

    const [nomeCategoria, setNomeCategoria] = useState('')
    const [descricaoCategoria, setDescricaoCategoria] = useState('')
    const [statusCategoria, setStatusCategoria] = useState('')

    const [errorNomeCategoria, setErrorNomeCategoria] = useState(false)
    const [errorDescricaoCategoria, setErrorDescricaoCategoria] = useState(false)

    const handleSave = () => {
        let error = false;
        if (nomeCategoria === '') {
            setErrorNomeCategoria(true)
            error = true
        } else setErrorNomeCategoria(false)

        if (descricaoCategoria === '') {
            setErrorDescricaoCategoria(true)
            error = true
        } else setErrorDescricaoCategoria(false)

        if (error) {
            error = false
            return
        }

        action({
            nome: nomeCategoria,
            descricao: descricaoCategoria,
            status: states.isEdit ? states.Categorias.status === 1 ? 'true' : 'false' : undefined
        })



    }


    useEffect(() => {
        if (states.modalCategorias == false) {
            setNomeCategoria('')
            setDescricaoCategoria('')
            setErrorDescricaoCategoria(false)
            setErrorNomeCategoria(false)
        } else {
            if (states.isEdit) {
                setNomeCategoria(states.Categorias.nome)
                setDescricaoCategoria(states.Categorias.descricao)
            }
        }

    }, [states.modalCategorias])


    return (
        <>
            <Transition.Root show={states.modalCategorias} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setters.setModalCategorias(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {/* Mexer na altura, só colocar o height na classname abaixo */}
                                <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => setters.setModalCategorias(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className='flex-col border-b border-gray-900/10'>
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Categoria</h3>
                                        <div className='flex justify-center flex-col items-center gap-x-4 pb-4'>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                {
                                                    states.isEdit ? `Edite os campos abaixo para alterar a categoria ${states.Categorias.nome}` : 'Preencha os campos abaixo para cadastrar uma nova categoria de serviço.'
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className='pt-10 pl-10 pr-10'>
                                        <div className="space-y-12">
                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais da Categoria.</p>
                                                </div>
                                                <div className="sm:col-span-2">

                                                    <div className="grid grid-cols-1 gap-y-6 gap-x-6 ">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                            <input
                                                                type="text"
                                                                id="title"
                                                                name="title"
                                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                value={nomeCategoria}
                                                                onChange={(e) => setNomeCategoria(e.target.value)}
                                                            />
                                                            {
                                                                errorNomeCategoria && <p className="text-red-500 text-sm mt-1">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Descrição</label>
                                                            <textarea
                                                                id="title"
                                                                name="title"
                                                                className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                                value={descricaoCategoria}
                                                                onChange={(e) => setDescricaoCategoria(e.target.value)}
                                                            />
                                                            {
                                                                errorDescricaoCategoria && <p className="text-red-500 text-sm mt-1">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 flex items-center justify-end gap-x-6">
                                            <button type="button" className="text-sm font-semibold leading-5 text-white bg-blue-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                                                onClick={handleSave}
                                            >
                                                {
                                                    states.isEdit ? 'Editar' : 'Cadastrar'
                                                }
                                            </button>
                                            <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                                onClick={() => setters.setModalCategorias(false)}>
                                                Fechar
                                            </button>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}


export default Categoria;