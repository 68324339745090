import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../Context';
import { AuthContextApi } from '../../../../Context/api';
import { ArrowLeftIcon, PencilIcon, CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import ModalShow from '../../../../Components/Modal/ExecucaoServico'
import moment from 'moment'




const Prontuario = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getPacienteData,
        getPacienteProntuariosData,
        atualizarProntuarioEncerrado
    } = useContext(AuthContextApi)
    const { token, id, prontuario } = useParams()
    const navigate = useNavigate()
    const [edit, setEdit] = useState(false)

    const [anotacoes, setAnotacoes] = useState('')
    const [anotacaoImportante, setAnotacaoImportante] = useState('')
    const [notasComplementares, setNotasComplementares] = useState('')
    const [errorAnotacoes, setErrorAnotacoes] = useState(false)
    const [errorAnotacaoImportante, setErrorAnotacaoImportante] = useState(false)
    const [errorNotasComplementares, setErrorNotasComplementares] = useState(false)

    const [sameData, setSameData] = useState(true)

    const updateProntuario = async () => {
        let error = false
        if (anotacoes === '') {
            setErrorAnotacoes(true)
            error = true
        } else setErrorAnotacoes(false)

        if (anotacaoImportante === '') {
            setErrorAnotacaoImportante(true)
            error = true
        } else setErrorAnotacaoImportante(false)


        if (error) return

        const response = await atualizarProntuarioEncerrado({ anotacoes, anotacaoImportante }, prontuario, id)
        if (response) {
            setEdit(false)
            setSameData(true)
        }
    }



    const primeiroLoad = async () => {
        await getPacienteData(id)
        await checkLogin()
        const response = await getPacienteProntuariosData(id, prontuario)
        if (!response) {
            setters.setProntuariosPaciente({})
            navigate(`/meusPacientes/${token}/paciente/${id}`)
        }
    }

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }

    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }

    // console.log(states.ProntuariosPaciente)

    useEffect(() => {
        primeiroLoad()

        return () => {
            setters.setPaciente({})
            setters.setProntuariosPaciente({})
            setAnotacoes('')
            setAnotacaoImportante('')
            setNotasComplementares('')
            setErrorAnotacoes(false)
            setErrorAnotacaoImportante(false)
            setErrorNotasComplementares(false)
            setSameData(true)
        }

    }, [])


    useEffect(() => {
        setAnotacoes(states.ProntuariosPaciente.anotacoes)
        setAnotacaoImportante(states.ProntuariosPaciente.anotacaoImportante)
        setNotasComplementares(states.ProntuariosPaciente.notasComplementares)
    }, [states.ProntuariosPaciente])

    useEffect(() => {
        if (anotacoes === states.ProntuariosPaciente.anotacoes && anotacaoImportante === states.ProntuariosPaciente.anotacaoImportante && notasComplementares === states.ProntuariosPaciente.notasComplementares) {
            setSameData(true)
        } else {
            setSameData(false)
        }
    }, [anotacaoImportante, anotacoes, notasComplementares, states.ProntuariosPaciente])

    return (
        <>
            <div className="flex flex-col">


                <div className="flex flex-col sm:flex-row p-4 border hover:shadow-md rounded-md">
                    <div className='flex flex items-center justify-between'>
                        <button onClick={() => navigate(`/meusPacientes/${token}/paciente/${id}`)} className="flex h-[50%] items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                            <ArrowLeftIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                        </button>
                    </div>
                    <div className='mt-5 ml-5'>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Paciente</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {states.Paciente.nome}
                        </p>
                    </div>
                </div>
                <div className="flex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Dados de paciente</h1>
                    <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Dados de cadastro: </p>
                            <p className="text-md text-gray-700 mt-2">{states.Paciente.nome} - {formatToCpfOrCnpj(states.Paciente.cpf)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Contato:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.Paciente.email} - {formatToPhoneBrl(states.Paciente.telefone)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Endereço:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.Paciente.rua}, {states.Paciente.numero} - {states.Paciente.bairro} - {formatCep(states.Paciente.cep)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Localização:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.Paciente.cidade} - {states.Paciente.estado}</p>
                        </div>
                    </div>
                </div>

                <div className="flex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Dados do prontuário - {states.ProntuariosPaciente.titulo}</h1>
                    <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                        <button onClick={() => setEdit(!edit)} className="flex items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px] mt-2">
                            {
                                !edit ? (
                                    <>
                                        <PencilIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                        <p>Editar Prontuário</p>
                                    </>
                                ) : (<>
                                    <XMarkIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                    <p>Cancelar Edição</p>
                                </>)
                            }
                        </button>
                        {
                            edit && (
                                <>
                                    {
                                        !sameData && (
                                            <button onClick={updateProntuario} className="flex items-center jutify-center text-sm font-medium hover:shadow text-green-500 hover:text-green-700 border p-2 rounded-[15px] mt-2">
                                                <CheckCircleIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                <p>Salvar</p>
                                            </button>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                    <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Início: </p>
                            <p className="text-md text-gray-700 mt-2">{moment(states.ProntuariosPaciente.inicio).format('DD/MM/YYYY HH:mm')}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Fim:</p>
                            <p className="text-md text-gray-700  mt-2">{moment(states.ProntuariosPaciente.fim).format('DD/MM/YYYY HH:mm')}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Serviço:</p>
                            <p className="text-md text-gray-700  mt-2">{states.ProntuariosPaciente.servico_nome}</p>
                        </div>
                    </div>
                    <div className='flex flex-col  items-between justify-between p-3 rounded-md border mt-2'>
                        {
                            !edit ? (
                                <>
                                    <div className='flex flex-col  mt-2 p-1'>
                                        <p className="text-md text-gray-700">Anotações: </p>
                                        <p className="text-md text-gray-700 mt-2">{states.ProntuariosPaciente.anotacoes}</p>
                                    </div>
                                    <div className='flex flex-col  mt-2 p-1'>
                                        <p className="text-md text-gray-700">Anotações importantes:</p>
                                        <p className="text-md text-gray-700  mt-2">{states.ProntuariosPaciente.anotacaoImportante}</p>
                                    </div>
                                    <div className='flex flex-col  mt-2 p-1'>
                                        <p className="text-md text-gray-700">Notas:</p>
                                        <p className="text-md text-gray-700  mt-2">{states.ProntuariosPaciente.notasComplementares}</p>
                                    </div>
                                </>
                            ) : (<>
                                <div className='flex flex-col  mt-2 p-1'>
                                    <p className="text-md text-gray-700">Anotações: </p>
                                    <textarea
                                        value={anotacoes}
                                        onChange={(e) => setAnotacoes(e.target.value)}
                                        className="mt-2 p-2 border w-full rounded-md"
                                        placeholder="Anotações"
                                    />
                                    {
                                        errorAnotacoes && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                                    }
                                </div>
                                <div className='flex flex-col  mt-2 p-1'>
                                    <p className="text-md text-gray-700">Anotações importantes:</p>
                                    <textarea
                                        value={anotacaoImportante}
                                        onChange={(e) => setAnotacaoImportante(e.target.value)}
                                        className="mt-2 p-2 border w-full rounded-md"
                                        placeholder="Anotações importantes"
                                    />
                                    {
                                        errorAnotacaoImportante && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                                    }
                                </div>
                                <div className='flex flex-col  mt-2 p-1'>
                                    <p className="text-md text-gray-700">Notas:</p>
                                    <p className="text-md text-gray-700  mt-2">{states.ProntuariosPaciente.notasComplementares}</p>
                                </div>
                            </>)
                        }
                    </div>
                </div>



            </div>
            <ModalShow states={states} setters={setters} />
        </>
    )

}



export default Prontuario;