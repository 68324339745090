import NavMenu from "../../../Components/NavMenu/Ajuda";

import configuracoes from "../../../Assets/Configuracoes/configuracoes.png";
import configContabilidade from "../../../Assets/Configuracoes/contabilidadeConfig.png";
import editConfigContabilidade from "../../../Assets/Configuracoes/editarContabilidadeConfig.png";
import editInfoConfig from "../../../Assets/Configuracoes/editarInfoConfig.png";
import infoConfig from "../../../Assets/Configuracoes/informacoesConfig.png";
import configNegocio from "../../../Assets/Configuracoes/negocioConfiguracoes.png";
import editNegocio from "../../../Assets/Configuracoes/editarNegocio.png";

const AjudaConfiguracoes = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <NavMenu />
      <div className="mb-4 border flex-col flwx-w-full md:w-[100%] items-center justify-center p-4 border-1 border-gray-300 rounded-[10px] overflow-hidden bg-white hover:shadow-sm">
        <h1 className="text-3xl text-center font-bold">
          Painel de Ajuda: Configurações
        </h1>
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Essa é a tela de configurações, nela temos três cartões.
        </p>
        <hr />
        <img
          src={configuracoes}
          alt="tela configuracoes"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Ao clicar no primeiro cartão temos as configurações do negócio. Nessa
          tela é possivel visualizar os dados do negócio, como vemos na imagem
          abaixo.
        </p>
        <hr />
        <img
          src={configNegocio}
          alt="tela negocio"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Ao clicar no botão de editar é possivel fazer as alterações desejadas.
        </p>
        <hr />
        <img
          src={editNegocio}
          alt="tela editar negocio"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Acessando agora o segundo cartão do painel de configurações temos a
          seguinte tela, onde temos as informações do seu negócio
        </p>
        <hr />
        <img
          src={infoConfig}
          alt="tela informações do negocio"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Ao clicar no botão de editar é possivel fazer as alterações desejadas.
        </p>
        <hr />
        <img
          src={editInfoConfig}
          alt="tela editar informacoes do negocio"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Agora clicando no terceiro cartão do painel de configurações temos a
          parte da contabilidade.
        </p>
        <hr />
        <img
          src={configContabilidade}
          alt="tela contabilidade"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Ao clicar no botão de editar é possivel fazer as alterações desejadas.
        </p>
        <hr />
        <img
          src={editConfigContabilidade}
          alt="tela editar configuracoes de contabilidade"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

export default AjudaConfiguracoes;
