import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../Context';
import { AuthContextApi } from '../../../../Context/api';
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify';
import ModalShow from '../../../../Components/Modal/ExecucaoServico'
import moment from 'moment'




const ClienteSecundario = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getPacienteData,
        getPacienteProntuariosData,
        atualizarProntuario,
        encerrarServico
    } = useContext(AuthContextApi)
    const { token, id, prontuario } = useParams()
    const navigate = useNavigate()

    const [anotacoes, setAnotacoes] = useState('')
    const [anotacaoImportante, setAnotacaoImportante] = useState('')
    const [notasComplementares, setNotasComplementares] = useState('')
    const [errorAnotacoes, setErrorAnotacoes] = useState(false)
    const [errorAnotacaoImportante, setErrorAnotacaoImportante] = useState(false)
    const [errorNotasComplementares, setErrorNotasComplementares] = useState(false)

    const [sameData, setSameData] = useState(true)

    const [initialTime, setIinitialTime] = useState(moment().format('YYYY-MM-DDTHH:mm'))
    const [time, setTime] = useState(moment().format('YYYY-MM-DDTHH:mm'))
    const [maxSegundos] = useState(20)






    const primeiroLoad = async () => {
        await getPacienteData(id)
        await checkLogin()
        const response = await getPacienteProntuariosData(id, prontuario)
        if (!response) {
            setters.setProntuariosPaciente({})
            navigate(`/pacientes/${token}/paciente/${id}`)
        }
    }

    const updateProntuario = async () => {
        let error = false
        if (anotacoes === '') {
            setErrorAnotacoes(true)
            error = true
        } else setErrorAnotacoes(false)

        if (anotacaoImportante === '') {
            setErrorAnotacaoImportante(true)
            error = true
        } else setErrorAnotacaoImportante(false)

        if (notasComplementares === '') {
            setErrorNotasComplementares(true)
            error = true
        } else setErrorNotasComplementares(false)

        if (error) return

        await atualizarProntuario({ anotacoes, anotacaoImportante, notasComplementares }, prontuario)
    }

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }

    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }

    const encerar = async () => {
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg('Deseja encerrar o atendimento?')
        setters.setDescModalMsg('Ao encerrar o atendimento, não será possível realizar alterações no atendimento.')
        setters.setTitleButtonModalMsg('Encerrar')
        setters.setFunctionModalMsg(() => async () => {
            const response = await encerrarServico(states.ProntuariosPaciente.execucao_servico_id)
            setters.setModalMsg(false)
            if (response) {
                navigate(`/pacientes/${token}/paciente/${id}`)
            }
        })

    }


    setInterval(() => {
        setTime(moment().format('YYYY-MM-DDTHH:mm'))
    }, 1000)


    // console.log(states.ProntuariosPaciente)


    useEffect(() => {
        const diff = moment(time).diff(moment(initialTime), 'seconds')
        if (diff >= maxSegundos) {
            if (!sameData) {
                setIinitialTime(moment().format('YYYY-MM-DDTHH:mm'))
                updateProntuario()
            }
        }
    }, [time])

    useEffect(() => {
        primeiroLoad()

        return () => {
            setters.setPaciente({})
            setters.setProntuariosPaciente({})
            setAnotacoes('')
            setAnotacaoImportante('')
            setNotasComplementares('')
            setErrorAnotacoes(false)
            setErrorAnotacaoImportante(false)
            setErrorNotasComplementares(false)
            setSameData(true)
        }

    }, [])


    useEffect(() => {
        if (states.ProntuariosPaciente.status === 2) {
            navigate(`/pacientes/${token}/paciente/${id}`)
            toast.error('Atendimento encerrado')
            return;
        }
        setAnotacoes(states.ProntuariosPaciente.anotacoes)
        setAnotacaoImportante(states.ProntuariosPaciente.anotacaoImportante)
        setNotasComplementares(states.ProntuariosPaciente.notasComplementares)
    }, [states.ProntuariosPaciente])

    useEffect(() => {
        if (anotacoes === states.ProntuariosPaciente.anotacoes && anotacaoImportante === states.ProntuariosPaciente.anotacaoImportante && notasComplementares === states.ProntuariosPaciente.notasComplementares) {
            setSameData(true)
        } else {
            setSameData(false)
        }
    }, [anotacaoImportante, anotacoes, notasComplementares, states.ProntuariosPaciente])



    return (
        <>
            <div className="flex flex-col">


                <div className="flex flex-col sm:flex-row p-4 border hover:shadow-md rounded-md">
                    <div className='mt-5 ml-5'>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Paciente</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {states.Paciente.nome}
                        </p>
                    </div>
                </div>
                <div className="flex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Dados de paciente</h1>
                    <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Dados de cadastro: </p>
                            <p className="text-md text-gray-700 mt-2">{states.Paciente.nome} - {formatToCpfOrCnpj(states.Paciente.cpf)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Contato:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.Paciente.email} - {formatToPhoneBrl(states.Paciente.telefone)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Endereço:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.Paciente.rua}, {states.Paciente.numero} - {states.Paciente.bairro} - {formatCep(states.Paciente.cep)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Localização:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.Paciente.cidade} - {states.Paciente.estado}</p>
                        </div>
                    </div>
                </div>

                <div className="flex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Dados do prontuário - {states.ProntuariosPaciente.titulo}</h1>
                    <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                        <button onClick={encerar} className='flex items-center justify-center bg-green-600 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md mt-2'>
                            <InformationCircleIcon className='h-5 w-5 mr-2' />
                            Encerrar atendimento
                        </button>
                        {
                            (sameData === false) &&
                            <button onClick={updateProntuario} className='flex items-center justify-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md mt-2'>
                                <InformationCircleIcon className='h-5 w-5 mr-2' />
                                Salvar Alterações
                            </button>
                        }
                    </div>
                    <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Início: </p>
                            <p className="text-md text-gray-700 mt-2">{moment(states.ProntuariosPaciente.inicio).format('DD/MM/YYYY HH:mm')}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Fim:</p>
                            <p className="text-md text-gray-700  mt-2">{
                                states.ProntuariosPaciente.fim === null ? 'Em andamento' : moment(states.ProntuariosPaciente.fim).format('DD/MM/YYYY HH:mm')
                            }</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Serviço:</p>
                            <p className="text-md text-gray-700  mt-2">{states.ProntuariosPaciente.servico_nome}</p>
                        </div>
                    </div>
                    <div className='flex flex-col  items-between justify-between p-3 rounded-md border mt-2'>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Anotações: </p>
                            <textarea
                                value={anotacoes}
                                onChange={(e) => setAnotacoes(e.target.value)}
                                className="mt-2 p-2 border w-full rounded-md"
                                placeholder="Anotações"
                            />
                            {
                                errorAnotacoes && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                            }
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Anotações importantes:</p>
                            <textarea
                                value={anotacaoImportante}
                                onChange={(e) => setAnotacaoImportante(e.target.value)}
                                className="mt-2 p-2 border w-full rounded-md"
                                placeholder="Anotações importantes"
                            />
                            {
                                errorAnotacaoImportante && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                            }
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Notas:</p>
                            <textarea
                                value={notasComplementares}
                                onChange={(e) => setNotasComplementares(e.target.value)}
                                className="mt-2 p-2 border w-full rounded-md"
                                placeholder="Notas complementares"
                            />
                            {
                                errorNotasComplementares && <p className="text-red-500 text-sm mt-2">Campo obrigatório</p>
                            }
                        </div>
                    </div>
                </div>



            </div>
            <ModalShow states={states} setters={setters} />
        </>
    )

}



export default ClienteSecundario;