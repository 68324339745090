import NavMenu from "../../../Components/NavMenu/Ajuda";

import agenda from "../../../Assets/Agenda/eventoAgenda.png";
import addEvento from "../../../Assets/Agenda/addEventoAgenda.png";
import editarEvento from "../../../Assets/Agenda/editarEventoAgenda.png";
import detalhesEvento from "../../../Assets/Agenda/visualisarEventoAgenda.png";
import cancelarEvento from "../../../Assets/Agenda/cancelarEventoAgenda.png";
import deletarEvento from "../../../Assets/Agenda/deletarEventoAgenda.png";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

const AjudaAgenda = () => {
  const [showImagem, setShowImagem] = useState(false);
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <NavMenu />
      <div className="mb-4 border flex-col flwx-w-full md:w-[100%] items-center justify-center p-4 border-1 border-gray-300 rounded-[10px] overflow-hidden bg-white hover:shadow-sm">
        <h1 className="text-3xl text-center font-bold">
          Painel de Ajuda: Agenda
        </h1>
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Essa é a tela da agenda nela temos o botao de adicionar evento, um
          calendário com os dias do mês atual, do lado do botao de adicionar
          temos o mês que está sendo mostrado no calendário com os respectivos
          eventos de cada dia, podemos alterar o mês clicando nas setas do lado
          do nome do mês. Temos também a lista de tarefas do dia escolhido, se
          não houver eventos no dia selecionado não terá nada na lista de
          tarefas.
        </p>
        {/* <button
          onClick={() => setShowImagem(!showImagem)}
          className="bg-blue-500 text-white p-4 rounded"
        >
          {showImagem ? (
            <MinusIcon className="h-8 w-8" />
          ) : (
            <PlusIcon className="h-8 w-8" />
          )}
        </button>
        {showImagem && (
          <img
            src={agenda}
            alt="tela agenda"
            className="mx-auto rounded-lg shadow-lg"
          />
        )} */}
        <img
          src={agenda}
          alt="tela agenda"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4 ">
        <p className="font-semibold text-justify p-4">
          Ao clicar no botão de adicionar evento temos a seguinte tela a seguir
        </p>
        {/* <button
          onClick={() => setShowImagem(!showImagem)}
          className="bg-blue-500 text-white p-4 rounded"
        >
          {showImagem ? (
            <MinusIcon className="h-8 w-8" />
          ) : (
            <PlusIcon className="h-8 w-8" />
          )}
        </button>
        {showImagem && (
          <img
            src={addEvento}
            alt="tela adicionar evento"
            className="mx-auto rounded-lg shadow-lg"
          />
        )} */}
        <img
          src={addEvento}
          alt="tela adicionar evento"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4 ">
        <p className="font-semibold text-justify p-4">
          Ao clicar no botão azul com a lupa é possível visualizar detalhes do
          evento em questão
        </p>
        {/* <button
          onClick={() => setShowImagem(!showImagem)}
          className="bg-blue-500 text-white p-4 rounded"
        >
          {showImagem ? (
            <MinusIcon className="h-8 w-8" />
          ) : (
            <PlusIcon className="h-8 w-8" />
          )}
        </button>
        {showImagem && (
          <img
            src={detalhesEvento}
            alt="tela detalhes evento"
            className="mx-auto rounded-lg shadow-lg"
          />
        )} */}
        <img
          src={detalhesEvento}
          alt="tela detalhes evento"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4 ">
        <p className="font-semibold text-justify p-4">
          Ao clicar no botão verde com a caneta é possível editar o evento em
          questão
        </p>
        {/* <button
          onClick={() => setShowImagem(!showImagem)}
          className="bg-blue-500 text-white p-4 rounded"
        >
          {showImagem ? (
            <MinusIcon className="h-8 w-8" />
          ) : (
            <PlusIcon className="h-8 w-8" />
          )}
        </button>
        {showImagem && (
          <img
            src={editarEvento}
            alt="tela editar evento"
            className="mx-auto rounded-lg shadow-lg"
          />
        )} */}
        <img
          src={editarEvento}
          alt="tela editar evento"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4 ">
        <p className="font-semibold text-justify p-4">
          Ao clicar no botão roxo com o "X" é possível cancelar o evento em
          questão
        </p>
        {/* <button
          onClick={() => setShowImagem(!showImagem)}
          className="bg-blue-500 text-white p-4 rounded"
        >
          {showImagem ? (
            <MinusIcon className="h-8 w-8" />
          ) : (
            <PlusIcon className="h-8 w-8" />
          )}
        </button>
        {showImagem && (
          <img
            src={cancelarEvento}
            alt="tela cancelar evento"
            className="mx-auto rounded-lg shadow-lg"
          />
        )} */}
        <img
          src={cancelarEvento}
          alt="tela cancelar evento"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4 ">
        <p className="font-semibold text-justify p-4">
          Ao clicar no botão vermelho com a lixeira é possível excluir o evento
          em questão
        </p>
        {/* <button
          onClick={() => setShowImagem(!showImagem)}
          className="bg-blue-500 text-white p-4 rounded"
        >
          {showImagem ? (
            <MinusIcon className="h-8 w-8" />
          ) : (
            <PlusIcon className="h-8 w-8" />
          )}
        </button>
        {showImagem && (
          <img
            src={deletarEvento}
            alt="tela deletar evento"
            className="mx-auto rounded-lg shadow-lg"
          />
        )} */}
        <img
          src={deletarEvento}
          alt="tela deletar evento"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

export default AjudaAgenda;
