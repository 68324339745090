import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'




const PacientePrestador = ({ states, setters, action }) => {


    const [servico, setServico] = useState('')
    const [error, setError] = useState(false)
    const [selectService, setSelectService] = useState({})
    const [select, setSelect] = useState(false)


    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }


    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }



    const handleServico = async (data) => {
        if (data === 'outro') {
            setters.setOffsetPrestadores(states.offsetPrestadores + states.limitePrestadores)
            return
        }
        const findServico = states.dataPrestadores.find(servico => servico.id === data)
        setSelectService(findServico)
        setServico(data)
        setSelect(true)
        setError(false)
    }

    const removeServico = () => {
        setSelectService({})
        setServico('')
        setSelect(false)
    }

    const actionServico = async () => {
        let error = false;
        if (servico === '') {
            error = true
            setError(true)
        } else setError(false)

        if (error === true) return

        action({
            prestador_id: servico,
            clienteSecundario_id: states.Paciente.id,
        }, selectService)
    }



    useEffect(() => {
        if (states.modalPacientePrestador === false) {
            setters.setDataPrestadores([])
            setters.setLimiteBuscaPrestadores(false)
            setters.setOffsetPrestadores(0)
            setServico('')
            setError(false)
            setSelectService({})
            setSelect(false)
        }

    }, [states.modalPacientePrestador])


    return (
        <>
            <Transition.Root show={states.modalPacientePrestador} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setters.setModalPacientePrestador(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {/* Mexer na altura, só colocar o height na classname abaixo */}
                                <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => setters.setModalPacientePrestador(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className='flex-col border-b border-gray-900/10'>
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Vincular psicólogo a paciente</h3>
                                        <div className='flex justify-center flex-col items-center gap-x-4 pb-4'>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                Adicionar psicólogo ao paciente <span className="font-semibold">{states.Paciente.nome}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='pt-10 pl-10 pr-10'>
                                        <div className="space-y-12">

                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações do atendimento</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do atendimento</p>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    {/* COLOCAR OS DADOS DO SERVIÇO AO SELECIONAR -> limiteBuscaCategoriaPrestador */}
                                                    <div className="grid grid-cols-1 gap-y-6  lg:grid-cols-3 gap-x-6 ">
                                                        <div className="sm:col-span-3">
                                                            {
                                                                !select ? (
                                                                    <>
                                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Selecione o psicólogo</label>
                                                                        <div className="flex border rounded-[15px] p-3 hover:shadow mt-4">
                                                                            <select
                                                                                value={servico}
                                                                                onChange={(e) => handleServico(e.target.value)}
                                                                                className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                            >
                                                                                <option value="">Selecione o psicólogo</option>
                                                                                {states.dataPrestadores.map((servico, index) => (
                                                                                    <option key={index} value={servico.id}>{servico.nome}</option>
                                                                                ))}
                                                                                {
                                                                                    (!states.limiteBuscaPrestadores && states.dataPrestadores.length >= states.limitePrestadores) &&
                                                                                    <option value="outro">Carregar mais</option>
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        {
                                                                            error && <span className="text-red-500 text-sm">Selecione um psicólogo</span>
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-4">
                                                                        <div className="flex flex-col">
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Dados</label>
                                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{selectService.nome} - {formatToCpfOrCnpj(selectService.cpf)}</p>
                                                                        </div>
                                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Contato</label>
                                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{formatToPhoneBrl(selectService.telefone)} - {selectService.email}</p>
                                                                        </div>
                                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Categoria</label>
                                                                            <p className="text-sm font-semibold leading-5 text-gray-900">{selectService.nome_categoria}</p>
                                                                        </div>
                                                                        <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                            <button type="button" className="text-sm font-semibold leading-5 text-white bg-red-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-red-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-100"
                                                                                onClick={removeServico}
                                                                            >
                                                                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 flex flex-col sm:flex-row items-center justify-end gap-x-6">
                                            <button type="button" className="text-sm font-semibold leading-5 text-white bg-blue-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                                                onClick={actionServico}
                                            >
                                                Adicionar psicólogo
                                            </button>
                                            <button type="button" className="text-sm mt-3 sm:mt-0 font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                                onClick={() => setters.setModalPacientePrestador(false)}>
                                                Fechar
                                            </button>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}


export default PacientePrestador;