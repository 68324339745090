import NavMenu from "../../../Components/NavMenu/Ajuda";

import paciente from "../../../Assets/Pacientes/pacientes.png";
import paciente2 from "../../../Assets/Pacientes/pacientes2.png";
import addPaciente from "../../../Assets/Pacientes/addPaciente.png";
import infoPaciente from "../../../Assets/Pacientes/infoPacientes.png";
import visualizarPaciente from "../../../Assets/Pacientes/visualisarPaciente.png";

const AjudaPacientes = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <NavMenu />
      <div className="mb-4 border flex-col flwx-w-full md:w-[100%] items-center justify-center p-4 border-1 border-gray-300 rounded-[10px] overflow-hidden bg-white hover:shadow-sm">
        <h1 className="text-3xl text-center font-bold">
          Painel de Ajuda: Pacientes
        </h1>
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Essa é a tela de Pacientes do sistema, nela temos o link para o acesso
          do paciente.
        </p>
        <hr />
        <img
          src={paciente}
          alt="tela dashboard"
          className="mx-auto rounded-lg shadow-lg"
        />
        <img
          src={paciente2}
          alt="tela dashboard"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Na tabela de clientes no final da página ao clicar no '+' é possível adicionar pacientes.
        </p>
        <hr />
        <img
          src={addPaciente}
          alt="tela dashboard"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Na tabela de clientes no final da página temos uma lupa que abre a tela para visualizar os dados do paciente.
        </p>
        <hr />
        <img
          src={visualizarPaciente}
          alt="tela dashboard"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Ao lado da lupa temos o icone que encaminha o usuario para a tela com as informações do paciente em questão.
        </p>
        <hr />
        <img
          src={infoPaciente}
          alt="tela dashboard"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>


    </div>
  );
};

export default AjudaPacientes;
