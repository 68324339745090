import NavMenu from "../../../Components/NavMenu/Ajuda";

import perfil from "../../../Assets/Perfil/perfil.png";
import mudarSenha from "../../../Assets/Perfil/mudarSenhaPerfil.png";
const AjudaPerfil = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <NavMenu />
      <div className="mb-4 border flex-col flwx-w-full md:w-[100%] items-center justify-center p-4 border-1 border-gray-300 rounded-[10px] overflow-hidden bg-white hover:shadow-sm">
        <h1 className="text-3xl text-center font-bold">
          Painel de Ajuda: Perfil
        </h1>
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Essa é a tela do Perfil do sisema. Nessa tela é possivel fazer a
          mudança do email como podemos visualizar na parte superior da tela.
        </p>
        <hr />
        <img
          src={perfil}
          alt="tela perfil"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Ao clicar em trocar senha, temos a seguinte tela.
        </p>
        <hr />
        <img
          src={mudarSenha}
          alt="tela perfil"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

export default AjudaPerfil;
