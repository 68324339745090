import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../Context';
import { AuthContextApi } from '../../../Context/api';
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import Table from '../../../Components/Table/Relatorios';
import NavMenu from '../../../Components/NavMenu/Financeiro'
import { useParams } from 'react-router-dom';
import moment from 'moment'


const RelatorioFinanceiro = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getRelatorioDataSistema,
        getRelatoriosData,
        RelatorioEnviarEmail
    } = useContext(AuthContextApi)

    const [query, setQuery] = useState('')
    const { token } = useParams()



    const primeiroLoad = async () => {
        await getRelatorioDataSistema(token)
        await getRelatoriosData()
        await checkLogin()
    }

    const loadUsuarios = async () => {
        await getRelatoriosData()
    }

    const next = async () => {
        if (states.limiteBuscaRelatorio) {
            if (states.offsetRelatorio + states.limiteRelatorio > 0) return
            setters.setLimiteBuscaRelatorio(true)
        }
        setters.setOffsetRelatorio(states.offsetRelatorio + states.limiteRelatorio)
    }

    const previous = async () => {
        if (states.offsetRelatorio - states.limiteRelatorio < 0) return
        if (states.limiteBuscaFaturas) {
            setters.setLimiteBuscaRelatorio(false)
        }
        setters.setOffsetRelatorio(states.offsetRelatorio - states.limiteRelatorio)
    }



    const dowloadArquivoFaturaFunction = async (data) => {
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja mesmo realizar o dowload do relatório de início ${moment(data.inicio).format('DD/MM/YYYY')} e fim ${moment(data.fim).format('DD/MM/YYYY')}?`)
        setters.setDescModalMsg('Após a confirmação o arquivo será baixado.')
        setters.setTitleButtonModalMsg('Confirmar dowload')
        setters.setFunctionModalMsg(() => async () => {
            const link = document.createElement("a");
            link.href = `data:text/csv;base64,${data.arquivo_path}`;
            link.download = data.arquivo_nome;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setters.setModalMsg(false)
        })
    }

    const enviarEmail = async (data) => {

        setters.setModalExecucoesServicoPaciente(false)
        setters.setModalMsg2(true)
        setters.setPerigoModalMsg2(false)
        setters.setMsgModalMsg2(`Deseja enviar por e-mail o relatório?`)
        setters.setDescModalMsg2(`Após a confirmação o serviço será iniciado`)
        setters.setTitleButtonModalMsg2('Confirmar')
        setters.setFunctionModalMsg2(() => async () => {
            const response = await RelatorioEnviarEmail(data)
            setters.setModalMsg2(false)
            if (response.status === true && response.confirm) {
                setters.setModalMsg2(true)
                setters.setPerigoModalMsg2(true)
                setters.setMsgModalMsg2(response.msg)
                setters.setDescModalMsg2(response.msg)
                setters.setTitleButtonModalMsg2('Confirmar envio de e-mail')
                setters.setFunctionModalMsg2(() => async () => {
                    await RelatorioEnviarEmail({ ...data, confirm: "true" })
                    setters.setModalMsg2(false)

                })
                setters.setFunctionBtnModalMsg2(() => async () => {
                    await RelatorioEnviarEmail({ ...data, confirm: "false" })
                    setters.setModalMsg2(false)
                })
            }
        })

        setters.setFunctionBtnModalMsg2(() => async () => {
            setters.setModalMsg2(false)
        })

    }

    useEffect(() => {
        primeiroLoad()

        return () => {
            setQuery('')
            setters.setDataEstatisticaRelatorio([])
            setters.setDataRelatorio([])
            setters.setRelatorio({})
            setters.setOffsetRelatorio(0)
            setters.setLimiteBuscaRelatorio(false)

        }

    }, [])

    // const [dataEstatisticaRelatorio, setDataEstatisticaRelatorio] = useState([])
    // const [dataRelatorio, setDataRelatorio] = useState([])
    // const [Relatorio, setRelatorio] = useState({})
    // const [offsetRelatorio, setOffsetRelatorio] = useState(0)
    // const [limiteRelatorio, setLimiteRelatorio] = useState(jsonConfig.limitDefault)
    // const [limiteBuscaRelatorio, setLimiteBuscaRelatorio] = useState(false)

    useEffect(() => {
        if (states.dataRelatorio?.length === 0) return
        loadUsuarios()
    }, [states.offsetRelatorio])

    const headers = ['', 'Início', 'Fim', 'Processado', 'Enviado',
        'Data de Criação', 'Data de Atualização']
    return (
        <>
            <div className="flex flex-col">
                <NavMenu />
                <div className="flex-1 p-4 border rounded-[10px]  hover:shadow-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Relatórios Financeiros</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Informações dos relatorios financeiros gerados pelo sistema
                    </p>
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mt-4">
                    {states.dataEstatisticaRelatorio?.length > 0 && states.dataEstatisticaRelatorio.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {
                    states.dataRelatorio?.length > 0 ? (
                        <>
                            <div className=" p-4 border hover:shadow-md mt-5">
                                <Table title='Relatórios gerados' description='Registro dos relatórios gerados'
                                    next={next}
                                    previous={previous} states={states} setters={setters} headers={headers}
                                    infoTable={{ offset: states.offsetRelatorio, limit: states.limiteRelatorio }} dataTable={states.dataRelatorio} query={query}

                                    dowload={dowloadArquivoFaturaFunction}
                                    enviarEmail={enviarEmail}

                                />
                            </div>
                        </>
                    ) : (
                        <div className="flex-1 p-12 border  hover:shadow-md mt-5">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Sem registro de relatório</h1>
                        </div>
                    )
                }
            </div>
        </>
    )

}



export default RelatorioFinanceiro;