import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'




const Servico = ({ states, setters, action }) => {



    const [nome, setNome] = useState('')
    const [categoria, setCategoria] = useState('')
    const [descricao, setDescricao] = useState('')
    const [preco, setPreco] = useState('')
    const [duracao, setDuracao] = useState('')

    const [errorNome, setErrorNome] = useState(false)
    const [errorCategoria, serErrorCategoria] = useState(false)
    const [errorDescricao, setErrorDescricao] = useState(false)
    const [errorPreco, setErrorPreco] = useState(false)
    const [errorDuracao, setErrorDuracao] = useState(false)




    const formatToMoney = value => {
        if (!value) return
        const valorFloat = value / 100;
        return valorFloat.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }


    const handleSubmit = async () => {
        let error = false
        if (nome === '') {
            setErrorNome(true)
            error = true
        } else setErrorNome(false)
        if (categoria === '') {
            serErrorCategoria(true)
            error = true
        } else serErrorCategoria(false)

        if (descricao === '') {
            setErrorDescricao(true)
            error = true
        } else setErrorDescricao(false)

        if (preco === '') {
            setErrorPreco(true)
            error = true
        } else setErrorPreco(false)

        if (duracao === '') {
            setErrorDuracao(true)
            error = true
        } else setErrorDuracao(false)

        if (error) return

        let duracaoForm = '';
        if (states.isEdit) {
            duracaoForm = duracao !== states.Servicos.duracao ? `${duracao}:00` : states.Servicos.duracao
        } else {
            duracaoForm = `${duracao}:00`
        }

        let data = {
            nome,
            categoria_id: categoria,
            descricao,
            preco: preco.replace(/[^0-9]/g, ''),
            duracao: duracaoForm
        }
        if (states.isEdit) data.status = states.Servicos.status === 1 ? 'true' : 'false'
        await action(data)

    }






    useEffect(() => {
        if (states.modalServicos === false) {
            setters.setServicos({})
            setNome('')
            setCategoria('')
            setDescricao('')
            setPreco('')
            setDuracao('')
            setErrorNome(false)
            serErrorCategoria(false)
            setErrorDescricao(false)
            setErrorPreco(false)
            setErrorDuracao(false)
            setters.setIsEdit(false)
            setters.setDataCategoriasForm([])
            setters.setOffsetCategorias(0)
        } else {
            if (states.isEdit) {
                const haveCategoria = states.dataCategoriasForm.find(categoria => categoria.id === states.Servicos.categoria_id)
                if (!haveCategoria) {
                    setters.setDataCategoriasForm([...states.dataCategoriasForm, { id: states.Servicos.categoria_id, nome: states.Servicos.categoria_nome }])
                }
                setNome(states.Servicos.nome)
                setCategoria(states.Servicos.categoria_id)
                setDescricao(states.Servicos.descricao)
                setPreco(formatToMoney(states.Servicos.preco))
                setDuracao(states.Servicos.duracao)
            } else {
                setters.setServicos({})
                setNome('')
                setCategoria('')
                setDescricao('')
                setPreco('')
                setDuracao('')
                setErrorNome(false)
                serErrorCategoria(false)
                setErrorDescricao(false)
                setErrorPreco(false)
                setErrorDuracao(false)
            }
        }

    }, [states.modalServicos])

    useEffect(() => {
        if (categoria === 'more') {
            setCategoria('')
            setters.setOffsetCategorias(states.offsetCategorias + states.limiteCategorias)
        }
    }, [categoria])


    return (
        <>
            <Transition.Root show={states.modalServicos} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => null}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {/* Mexer na altura, só colocar o height na classname abaixo */}
                                <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => setters.setModalServicos(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className='flex-col border-b border-gray-900/10'>
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Serviço</h3>
                                        <div className='flex justify-center flex-col items-center gap-x-4 pb-4'>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                {
                                                    states.isEdit ? `Editar serviço ${states.Servicos.nome}` : `Cadastrar novo serviço`
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className='pt-10 pl-10 pr-10'>
                                        <div className="space-y-12">
                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações principais</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do serviço.</p>
                                                </div>
                                                <div className="sm:col-span-2">

                                                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 gap-x-6 ">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    className="w-full text-sm leading-6 text-gray-600"
                                                                    value={nome}
                                                                    onChange={(e) => setNome(e.target.value)}
                                                                />
                                                            </div>
                                                            {
                                                                errorNome && <p className="text-red-500 text-sm">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Categoria</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <select
                                                                    className="w-full text-sm leading-6 text-gray-600"
                                                                    value={categoria}
                                                                    onChange={(e) => setCategoria(e.target.value)}
                                                                >
                                                                    <option value="">Selecione uma categoria</option>
                                                                    {
                                                                        states.dataCategoriasForm.map((categoria, index) => (
                                                                            <option key={index} value={categoria.id}>{categoria.nome}</option>
                                                                        ))
                                                                    }
                                                                    {
                                                                        (states.dataCategoriasForm.length >= states.limiteCategorias && !states.limiteBuscaCategorias) &&
                                                                        <option value="more">Carregar mais</option>
                                                                    }
                                                                </select>
                                                            </div>
                                                            {
                                                                errorCategoria && <p className="text-red-500 text-sm">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Descrição</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <textarea
                                                                    className="w-full text-sm leading-6 text-gray-600"
                                                                    value={descricao}
                                                                    onChange={(e) => setDescricao(e.target.value)}
                                                                />
                                                            </div>
                                                            {
                                                                errorDescricao && <p className="text-red-500 text-sm">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2  mt-6">
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Duração</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="time"
                                                                    className="w-full text-sm leading-6 text-gray-600"
                                                                    value={duracao}
                                                                    onChange={(e) => setDuracao(e.target.value)}
                                                                />
                                                            </div>
                                                            {
                                                                errorDuracao && <p className="text-red-500 text-sm">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Valor</label>
                                                            <div className="flex border rounded-[15px] p-3 hover:shadow">
                                                                <input
                                                                    type="text"
                                                                    className="w-full text-sm leading-6 text-gray-600"
                                                                    value={preco}
                                                                    onChange={e => {
                                                                        const onlyNumber = e.target.value.replace(/[^0-9]/g, '');
                                                                        if (onlyNumber === '') {
                                                                            setErrorPreco(true)
                                                                            setPreco('')
                                                                            return;
                                                                        }
                                                                        const formatToMoneyWithBrl = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(onlyNumber / 100)
                                                                        setPreco(formatToMoneyWithBrl)
                                                                    }}
                                                                />
                                                            </div>
                                                            {
                                                                errorPreco && <p className="text-red-500 text-sm">Campo obrigatório</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 flex items-center justify-end gap-x-6">
                                            <button type="button" className="text-sm font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                                onClick={() => setters.setModalServicos(false)}>
                                                Fechar
                                            </button>
                                            <button type="button" className="text-sm font-semibold leading-5 text-white bg-blue-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                                                onClick={handleSubmit}>
                                                {
                                                    states.isEdit ? 'Editar' : 'Cadastrar'
                                                }
                                            </button>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}


export default Servico;