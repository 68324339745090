import NavMenu from "../../../Components/NavMenu/Ajuda";

import imagem from "../../../Assets/Dashboard/dashboard.png";

const AjudaDashboard = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <NavMenu />
      <div className="mb-4 border flex-col flwx-w-full md:w-[100%] items-center justify-center p-4 border-1 border-gray-300 rounded-[10px] overflow-hidden bg-white hover:shadow-sm">
        <h1 className="text-3xl text-center font-bold">
          Painel de Ajuda: Dashboard
        </h1>
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Essa é a tela do dashboard e marcado de vermelho temos os cards que ao
          clicar neles o usuário é direcionado para a página em questão, assim
          como no segundo bloco vermelho ao clicar no nome em azul também
          encaminha o usuário para a página desejada
        </p>
        <hr />
        <img
          src={imagem}
          alt="tela dashboard"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

export default AjudaDashboard;
