import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../Context';
import { jsonConfig } from '../../../Config';
import { AuthContextApi } from '../../../Context/api';
import { InformationCircleIcon, ArrowLeftIcon, ArrowDownIcon, ArrowUpIcon, XCircleIcon } from '@heroicons/react/20/solid'
import TableExecucoes from '../../../Components/Table/ExecucoesServicos';
import TabelaProntuaio from '../../../Components/Table/Prontuario';
import ModalShow from '../../../Components/Modal/ExecucaoServico'
import ModalServico from '../../../Components/Modal/ExecucaoServicoPaciente'
import ModalPacientePrestador from '../../../Components/Modal/PacientePrestador'




const ClienteSecundario = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin,

        getPacienteData,
        getPacientProntuarios,
        getPacienteExecucoes,
        getServicosPrestador,
        iniciarServico,
        getPacientePrestador,
        getPrestadoresForm,
        postPrestadorPaciente,
        postRemoverPrestadorPaciente

    } = useContext(AuthContextApi)
    const { token, id } = useParams()
    const navigate = useNavigate()

    const [chvronExecucoes, setChvronExecucoes] = useState(false)
    const [chvronProntuario, setChvronProntuario] = useState(false)
    const [chvronPsicologos, setChvronPsicologos] = useState(false)



    const primeiroLoad = async () => {
        await getPacienteData(id)
        await checkLogin()
    }

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }

    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }

    const openChvronExecucoes = async () => {
        if (chvronExecucoes === false) {
            if (states.dataExecucoesServicoPaciente.length === 0) {
                await getPacienteExecucoes(id)
            }
            setChvronExecucoes(!chvronExecucoes)
        } else {
            setChvronExecucoes(!chvronExecucoes)
        }
    }

    const openChvronProntuarios = async () => {
        if (chvronProntuario === false) {
            if (states.dataProntuariosPaciente.length === 0) {
                await getPacientProntuarios(id)
            }
            setChvronProntuario(!chvronProntuario)
        } else {
            setChvronProntuario(!chvronProntuario)
        }
    }

    const showServico = async (data) => {
        setters.setExecucoesServico(data)
        setters.setModalExecucoesServicoShow(true)
    }

    const showProntuario = async (data) => {
        ///paciente/:id/prontuario/:prontuario
        navigate(`/pacientes/${token}/paciente/${id}/prontuario/${data.id}`)
    }

    const dowloadComprovanteServico = data => {
        setters.setModalUsuarios(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja baixar o comprovante de execução do serviço?`)
        setters.setDescModalMsg(`Após a confirmação o comprovante será baixado`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const link = document.createElement("a");
            link.href = `data:application/pdf;base64,${data.path}`;
            link.download = data.nomeArquivo;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setters.setModalMsg(false)
        })
    }

    const nextExecucoes = async () => {
        if (states.limiteBuscaExecucoesServicoPaciente) {
            if (states.offsetExecucoesServicoPaciente + states.limiteExecucoesServicoPaciente > 0) return
            setters.setLimiteBuscaExecucoesServicoPaciente(true)
        }
        setters.setOffsetExecucoesServicoPaciente(states.offsetExecucoesServicoPaciente + states.limiteExecucoesServicoPaciente)
    }

    const previousExecucoes = async () => {
        if (states.offsetExecucoesServicoPaciente - states.limiteExecucoesServicoPaciente < 0) return
        if (states.limiteBuscaExecucoesServicoPaciente) {
            setters.setLimiteBuscaExecucoesServicoPaciente(false)
        }
        setters.setOffsetExecucoesServicoPaciente(states.offsetExecucoesServicoPaciente - states.limiteExecucoesServicoPaciente)
    }

    const loadMoreExecucoes = async () => {
        await getPacienteExecucoes(id)
    }

    const nextProntuario = async () => {
        if (states.limiteBuscaProntuariosPaciente) {
            if (states.offsetProntuariosPaciente + states.limiteProntuariosPaciente > 0) return
            setters.setLimiteBuscaProntuariosPaciente(true)
        }
        setters.setOffsetProntuariosPaciente(states.offsetProntuariosPaciente + states.limiteProntuariosPaciente)
    }

    const previousProntuario = async () => {
        if (states.offsetProntuariosPaciente - states.limiteProntuariosPaciente < 0) return
        if (states.limiteBuscaProntuariosPaciente) {
            setters.setLimiteBuscaProntuariosPaciente(false)
        }
        setters.setOffsetProntuariosPaciente(states.offsetProntuariosPaciente - states.limiteProntuariosPaciente)
    }

    const loadMoreProntuario = async () => {
        await getPacientProntuarios(id)
    }

    const chat = async () => {
        navigate(`/pacientes/${token}/paciente/${id}/conversas`)
    }

    const loadMoreCategoriaPrestador = async () => {
        await getServicosPrestador()
    }

    const loadMorePacientePrestador = async () => {
        await getPacientePrestador(id)
    }

    const novoAtendimento = async () => {
        await getServicosPrestador()
        setters.setModalExecucoesServicoPaciente(true)
    }

    const actionModal = async (data, servico) => {
        setters.setModalExecucoesServicoPaciente(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja iniciar o serviço ${servico.nome}?`)
        setters.setDescModalMsg(`Após a confirmação o serviço será iniciado`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const response = await iniciarServico(data)
            setters.setModalMsg(false)
            if (response.status === true && response.confirm) {
                setters.setModalMsg(true)
                setters.setPerigoModalMsg(true)
                setters.setMsgModalMsg(response.msg)
                setters.setDescModalMsg(response.msg)
                setters.setTitleButtonModalMsg('Confirmar início do serviço')
                setters.setFunctionModalMsg(() => async () => {
                    setters.setModalMsg(false)
                    await getPacienteExecucoes(id)
                })
            }
            if (response.status === true && !response.confirm) {
                ///paciente/:id/atendimento/:prontuario
                navigate(`/pacientes/${token}/paciente/${id}/atendimento/${response.prontuario}`)
            }
        })

    }

    const morePrestadorPsicologos = async () => {
        setters.setOffsetPacientePrestador(states.offsetPacientePrestador + states.limitePacientePrestador)
    }

    const openChvronPsicologos = async () => {
        if (chvronPsicologos === false) {
            if (states.dataPacientePrestador.length === 0) {
                await getPacientePrestador(id)
            }
            setChvronPsicologos(!chvronPsicologos)
        } else {
            setChvronPsicologos(!chvronPsicologos)
        }
    }

    const loadMorePrestador = async () => {
        await getPrestadoresForm()
    }

    const addPrestador = async () => {
        await getPrestadoresForm()
        setters.setModalPacientePrestador(true)

    }

    const actionModalPrestador = async (data, select) => {
        setters.setModalPacientePrestador(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja adicionar o psicólogo ${select.nome} ao paciente ${states.Paciente.nome}?`)
        setters.setDescModalMsg(`Após a confirmação o psicólogo será adicionado ao paciente`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const response = await postPrestadorPaciente(data)
            setters.setModalMsg(false)
            if (response.status === true) {
                setters.setModalMsg(true)
                setters.setPerigoModalMsg(true)
                setters.setMsgModalMsg(response.msg)
                setters.setDescModalMsg(response.msg)
                setters.setTitleButtonModalMsg('Confirmar adição do psicólogo')
                setters.setFunctionModalMsg(() => async () => {
                    setters.setModalMsg(false)
                })
            }
        })

    }

    const actionDeletePacientePrestador = async (data) => {
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(true)
        setters.setMsgModalMsg(`Deseja remover o psicólogo ${data.nome} do paciente ${states.Paciente.nome}?`)
        setters.setDescModalMsg(`Após a confirmação o psicólogo será removido do paciente`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const response = await postRemoverPrestadorPaciente({ prestador_id: data.id, clienteSecundario_id: id })
            setters.setModalMsg(false)
            if (response.status === true) {
                setters.setModalMsg(true)
                setters.setPerigoModalMsg(true)
                setters.setMsgModalMsg(response.msg)
                setters.setDescModalMsg(response.msg)
                setters.setTitleButtonModalMsg('Confirmar remoção do psicólogo')
                setters.setFunctionModalMsg(() => async () => {
                    setters.setModalMsg(false)
                })
            }
        })


    }

    useEffect(() => {
        primeiroLoad()

        return () => {
            setters.setDataEstatisticaPaciente([])
            setters.setDataPaciente([])
            setters.setPaciente({})
            setters.setOffsetPaciente(0)
            setters.setLimiteBuscaPaciente(false)
            setters.setModalPaciente(false)
            setters.setModalPacienteShow(false)
            setters.setDataExecucoesServicoPaciente([])
            setters.setExecucoesServicoPaciente({})
            setters.setOffsetExecucoesServicoPaciente(0)
            setters.setLimiteBuscaExecucoesServicoPaciente(false)
            setters.setModalExecucoesServicoPaciente(false)
            setters.setModalExecucoesServicoPacienteShow(false)
            setters.setDataProntuariosPaciente([])
            setters.setProntuariosPaciente({})
            setters.setOffsetProntuariosPaciente(0)
            setters.setLimiteBuscaProntuariosPaciente(false)
            setters.setModalProntuariosPaciente(false)
            setters.setModalProntuariosPacienteShow(false)
            setters.setDataConversasPaciente([])
            setters.setConversasPaciente({})
            setters.setOffsetConversasPaciente(0)
            setters.setLimiteBuscaConversasPaciente(false)
            setters.setModalConversasPaciente(false)
            setters.setDataConversaPaciente([])
            setters.setConversaPaciente({})
            setters.setOffsetConversaPaciente(0)
            setters.setLimiteBuscaConversaPaciente(false)
            setters.setDataCategoriaPrestador([])
            setters.setCategoriaPrestador({})
            setters.setOffsetCategoriaPrestador(0)
            setters.setLimiteBuscaCategoriaPrestador(false)
            setters.setDataPacientePrestador([])
            setters.setPacientePrestador({})
            setters.setOffsetPacientePrestador(0)
            setters.setLimiteBuscaPacientePrestador(false)
            setChvronPsicologos(false)
            setChvronExecucoes(false)
            setChvronProntuario(false)
        }

    }, [])


    useEffect(() => {
        if (Object.keys(states.Paciente).length === 0) {
            primeiroLoad()
            return
        }
        if (states.Paciente.haveAtendimento === false) {
            setChvronExecucoes(false)
        }
    }, [states.Paciente])

    useEffect(() => {
        if (states.dataExecucoesServicoPaciente.length === 0) {
            setChvronExecucoes(false)
            return
        }
        loadMoreExecucoes()
    }, [states.offsetExecucoesServicoPaciente])

    useEffect(() => {
        if (states.dataProntuariosPaciente.length === 0) {
            setChvronProntuario(false)
            return
        }
        loadMoreProntuario()
    }, [states.offsetProntuariosPaciente])

    useEffect(() => {
        if (states.dataCategoriaPrestador.length > 0) {
            loadMoreCategoriaPrestador()
        }
    }, [states.offsetCategoriaPrestador])

    //

    useEffect(() => {
        if (states.dataPacientePrestador.length > 0) {
            loadMorePacientePrestador()
        }
    }, [states.offsetPacientePrestador])

    useEffect(() => {
        if (states.dataPrestadores.length > 0) {
            loadMorePrestador()
        }
    }, [states.offsetPrestadores])

    const headersExecucao = ['', 'Status', 'Nome do serviço', 'Nome do psicólogo', 'Cpf do psicólogo', 'Nome do paciente', 'Cpf do paciente', 'Início', 'Fim', 'Data atualização', 'Data de criação']
    const headersProntuario = ['', 'Título', 'Status', 'Início', 'Fim', 'Data Atualização', 'Data de criação']
    return (
        <>
            <div className="flex flex-col">


                <div className="flex flex-col sm:flex-row p-4 border hover:shadow-md rounded-md">
                    <div className='flex flex items-center justify-between'>
                        <button onClick={() => navigate(`/pacientes/${token}`)} className="flex h-[50%] items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                            <ArrowLeftIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                        </button>
                    </div>
                    <div className='mt-5 ml-5'>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Paciente</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {states.Paciente.nome}
                        </p>
                    </div>
                    {
                        (localStorage.getItem('iamPrestador') === 'true' && states.Paciente.myClienteSecundario === true) &&
                        <div className='flex flex-col sm:flex-row w-full items-center justify-end'

                        >
                            <button className="flex items-center sm:mt-0 mt-3 justify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]"
                                onClick={novoAtendimento}>
                                Iniciar atendimento
                            </button>
                            {
                                ((localStorage.getItem('areaCliente') === "true") && (localStorage.getItem('conversaCliente') === "true")) ?
                                    <button className="flex items-center ml-0 ml-2 sm:mt-0 mt-3 justify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]"
                                        onClick={() => chat()}
                                    >
                                        Conversas com o paciente
                                    </button> :
                                    <button className="flex items-center ml-0 ml-2 sm:mt-0 mt-3 justify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]"
                                        disabled
                                    >
                                        Faça upgrade do plano para poder conversar com o paciente
                                    </button>
                            }
                        </div>
                    }
                </div>
                <div className="flex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Dados de paciente</h1>
                    <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Dados de cadastro: </p>
                            <p className="text-md text-gray-700 mt-2">{states.Paciente.nome} - {formatToCpfOrCnpj(states.Paciente.cpf)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Contato:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.Paciente.email} - {formatToPhoneBrl(states.Paciente.telefone)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Endereço:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.Paciente.rua}, {states.Paciente.numero} - {states.Paciente.bairro} - {formatCep(states.Paciente.cep)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Localização:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.Paciente.cidade} - {states.Paciente.estado}</p>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4  mt-4">
                    {states.dataEstatisticaPaciente?.length > 0 && states.dataEstatisticaPaciente.map((card, index) => (
                        <div key={card.title} className={card.value > 0 && index === -1 ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md" : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"}>
                            <div className="p-5">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        {
                                            card.value > 0 && index === -1 ? (<InformationCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />) : (<InformationCircleIcon className="w-6 h-6 text-gray-400" aria-hidden="true" />)
                                        }
                                    </div>
                                    <div className="ml-5 w-0 flex-1">
                                        <dl>
                                            {
                                                card.value > 0 && index === -1 ? (<dt className="truncate text-sm font-medium text-red-500">{card.title}</dt>) : (<dt className="truncate text-sm font-medium text-gray-500">{card.title}</dt>)
                                            }

                                            <dd>
                                                {
                                                    card.value > 0 && index === -1 ? (<div className="text-lg font-medium text-red-500">{card.value}</div>) : (<div className="text-lg font-medium text-gray-900">{card.value}</div>)
                                                }
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* PRIMEIRO SERÁ REALIZADO O GRID DAS CONSULTAS -> CASO TENHA PODE ABRIR E TRARÁ QUAIS FORAM AS EXECUÇÕES QUE TEVE */}

                <div className="lex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                    <div className='flex items-between justify-between '>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Painel de Atendimentos</h1>
                        {
                            states.Paciente.haveAtendimento ? (
                                <button className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' onClick={() => openChvronExecucoes()}>
                                    {chvronExecucoes ? <ArrowDownIcon className="w-5 h-5 " aria-hidden="true" /> : <ArrowUpIcon className="w-5 h-5 " aria-hidden="true" />}
                                </button>
                            ) : (
                                <p className='text-sm text-gray-700'>
                                    O paciente {states.Paciente.nome} não possui atendimentos
                                </p>
                            )
                        }
                    </div>
                    {
                        chvronExecucoes && (
                            <>
                                <div className="border-t border-1 border-gray-300 border-dashed my-4"></div>
                                <div className='flex flex-col items-center'>
                                    {
                                        states.dataExecucoesServicoPaciente?.length > 0 ? (
                                            <>
                                                <div className="p-4 border hover:shadow-md mt-5 w-full max-w-full overflow-x-auto">
                                                    {
                                                        states.dataExecucoesServicoPaciente?.length > 0 &&
                                                        <TableExecucoes
                                                            title='Execuções de serviço'
                                                            description='Registro de serviços executados'
                                                            previous={previousExecucoes}
                                                            next={nextExecucoes}
                                                            show={showServico}
                                                            dowload={dowloadComprovanteServico}
                                                            states={states}
                                                            setters={setters}
                                                            headers={headersExecucao}
                                                            infoTable={{ offset: states.offsetExecucoesServicoPaciente, limit: states.limiteExecucoesServicoPaciente }}
                                                            dataTable={states.dataExecucoesServicoPaciente}
                                                        />
                                                    }
                                                </div>
                                            </>
                                        ) : (
                                            <div className="flex-1 p-12 border hover:shadow-md mt-5">
                                                <h1 className="text-base font-semibold leading-6 text-gray-900">Sem registro de serviços realizados</h1>
                                            </div>
                                        )
                                    }
                                </div>
                            </>

                        )
                    }
                </div>

                {
                    (localStorage.getItem('iamPrestador') === 'true' && states.Paciente.myClienteSecundario === true) &&
                    <div className="lex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                        <div className='flex items-between justify-between '>
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Painel de Prontuários</h1>
                            {
                                states.Paciente.haveAtendimento ? (
                                    <button className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' onClick={openChvronProntuarios}>
                                        {chvronProntuario ? <ArrowDownIcon className="w-5 h-5 " aria-hidden="true" /> : <ArrowUpIcon className="w-5 h-5 " aria-hidden="true" />}
                                    </button>
                                ) : (
                                    <p className='text-sm text-gray-700'>
                                        O paciente {states.Paciente.nome} não possui prontuários
                                    </p>
                                )
                            }
                        </div>
                        {
                            chvronProntuario && (
                                <>
                                    <div className="border-t border-1 border-gray-300 border-dashed my-4"></div>
                                    <div className='flex flex-col items-center'>
                                        {
                                            states.dataProntuariosPaciente?.length > 0 ? (
                                                <>
                                                    <div className="p-4 border hover:shadow-md mt-5 w-full max-w-full overflow-x-auto">
                                                        {
                                                            states.dataProntuariosPaciente?.length > 0 &&
                                                            <TabelaProntuaio
                                                                title='Prontuários'
                                                                description='Registro dos prontuários'
                                                                previous={previousProntuario}
                                                                next={nextProntuario}
                                                                show={showProntuario}
                                                                dowload={dowloadComprovanteServico}
                                                                states={states}
                                                                setters={setters}
                                                                headers={headersProntuario}
                                                                infoTable={{ offset: states.offsetProntuariosPaciente, limit: states.limiteProntuariosPaciente }}
                                                                dataTable={states.dataProntuariosPaciente}
                                                            />
                                                        }
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="flex-1 p-12 border hover:shadow-md mt-5">
                                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Sem registro de prontuários</h1>
                                                </div>
                                            )
                                        }
                                    </div>
                                </>

                            )
                        }
                    </div>
                }

                {
                    (localStorage.getItem('permisssion') === jsonConfig.adminPermission && localStorage.getItem('empresa') === 'true') &&
                    <div className="lex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                        <div className='flex items-between justify-between '>
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Psicólogos vinculados</h1>
                            <button className="flex p-2 items-center justify-center border p-1 rounded-[10px] hover:shadow" onClick={addPrestador}>
                                Adicionar Psicólogo
                            </button>
                            {
                                states.Paciente.haveAtendimento ? (
                                    <button className='flex items-center justify-center border p-1 rounded-[10px] hover:shadow' onClick={openChvronPsicologos}>
                                        {chvronPsicologos ? <ArrowDownIcon className="w-5 h-5 " aria-hidden="true" /> : <ArrowUpIcon className="w-5 h-5 " aria-hidden="true" />}
                                    </button>
                                ) : (
                                    <p className='text-sm text-gray-700'>
                                        O paciente {states.Paciente.nome} não possui psicólogos vinculados
                                    </p>
                                )
                            }
                        </div>
                        {
                            chvronPsicologos && (
                                <>
                                    <div className="border-t border-1 border-gray-300 border-dashed my-4"></div>
                                    <div className='flex flex-col items-center'>
                                        {
                                            states.dataPacientePrestador?.length > 0 ? (
                                                <>
                                                    {
                                                        states.dataPacientePrestador?.length > 0 &&
                                                        <>
                                                            {
                                                                states.dataPacientePrestador.map((card, index) => (
                                                                    <div key={card.id} className="flex  w-full flex-col items-between justify-between sm:flex-row p-4 border hover:shadow-md mt-5">
                                                                        <div className='flex flex-col  mt-2 p-1'>
                                                                            <p className="text-md text-gray-700">Nome do psicólogo: </p>
                                                                            <p className="text-md text-gray-700 mt-2">{card.nome}</p>
                                                                        </div>
                                                                        <div className='flex flex-col  mt-2 p-1'>
                                                                            <p className="text-md text-gray-700">Cpf do psicólogo:</p>
                                                                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{formatToCpfOrCnpj(card.cpf)}</p>
                                                                        </div>
                                                                        <div className='flex flex-col  mt-2 p-1'>
                                                                            <p className="text-md text-gray-700">Nome:</p>
                                                                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{card.nome}</p>
                                                                        </div>
                                                                        <div className='flex flex-col  mt-2 p-1'>
                                                                            <p className="text-md text-gray-700">Registro:</p>
                                                                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{card.entidadeRegistro} - {card.codigoEntidadeRegistro}</p>
                                                                        </div>
                                                                        {
                                                                            states.dataPacientePrestador.length > 1 &&
                                                                            <div className='flex flex-col  mt-2 p-1'>
                                                                                <button className="flex items-center justify-center border p-1 rounded-[10px] hover:shadow" onClick={() => actionDeletePacientePrestador(card)}>
                                                                                    <XCircleIcon className="w-5 h-5 " aria-hidden="true" />
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                ))
                                                            }
                                                            {
                                                                (!states.limiteBuscaPacientePrestador && states.dataPacientePrestador.length >= states.limitePacientePrestador) && (
                                                                    <div className="flex items-center justify-center w-full mt-5">
                                                                        <button className="p-2 flex items-center justify-center border p-1 rounded-[10px] hover:shadow" onClick={morePrestadorPsicologos}>
                                                                            Carregar mais
                                                                        </button>
                                                                    </div>
                                                                )

                                                            }
                                                        </>
                                                    }
                                                </>
                                            ) : (
                                                <div className="flex-1 p-12 border hover:shadow-md mt-5">
                                                    <h1 className="text-base font-semibold leading-6 text-gray-900">Sem registro de psicólogos</h1>
                                                </div>
                                            )
                                        }
                                    </div>
                                </>

                            )
                        }
                    </div>
                }


            </div>
            <ModalShow states={states} setters={setters} />
            <ModalServico states={states} setters={setters} action={actionModal} />
            <ModalPacientePrestador states={states} setters={setters} action={actionModalPrestador} />
        </>
    )

}



export default ClienteSecundario;