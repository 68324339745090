import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline'
import moment from 'moment'


const ExecucoesServico = ({ states, setters, action, get }) => {


    const [servico, setServico] = useState('')
    const [cpf, setCpf] = useState('')
    const [titulo, setTitulo] = useState('')
    const [descricao, setDescricao] = useState('')
    const [tipo, setTipo] = useState('')
    const [enviarCliente, setEnviarCliente] = useState('')
    const [inicio, setInicio] = useState('')
    const [fim, setFim] = useState('')
    const [dia, setDia] = useState('')



    const [errorInicio, setErrorInicio] = useState(false)
    const [errorFim, setErrorFim] = useState(false)
    const [errorDia, setErrorDia] = useState(false)
    const [errorEnviarCliente, setErrorEnviarCliente] = useState(false)
    const [errorTipo, setErrorTipo] = useState(false)
    const [errorTitulo, setErrorTitulo] = useState(false)
    const [errorDescricao, setErrorDescricao] = useState(false)


    const [error, setError] = useState(false)
    const [errorCpf, setErrorCpf] = useState(false)
    const [selectService, setSelectService] = useState({})
    const [select, setSelect] = useState(false)

    const [selectPsicologo, setSelectPsicologo] = useState(false)
    const [psicologo, setPsicologo] = useState('')
    const [selectServicePsicologo, setSelectServicePsicologo] = useState({})
    const [errorPsicologo, setErrorPsicologo] = useState(false)


    const formatToMoney = value => {
        if (!value) return
        const valorFloat = value / 100;
        return valorFloat.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }


    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }


    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }



    const handleServico = async (data) => {
        if (data === 'outro') {
            setters.setOffsetCategoriaPrestador(states.offsetCategoriaPrestador + states.limiteCategoriaPrestador)
            setServico('')
            return
        }
        const findServico = states.dataCategoriaPrestador.find(servico => servico.id === data)
        setSelectService(findServico)
        setServico(data)
        setSelect(true)
        setError(false)
    }

    const handlePsicologo = async (data) => {
        if (data === 'outro') {
            setters.setOffsetPrestadores(states.offsetPrestadores + states.limitePrestadores)
            setPsicologo('')
            return
        }
        const findServico = states.dataPrestadores.find(servico => servico.id === data)
        setSelectServicePsicologo(findServico)
        setPsicologo(data)
        setSelectPsicologo(true)
        setErrorPsicologo(false)
    }

    const removePsicologo = () => {
        setSelectServicePsicologo({})
        setPsicologo('')
        setSelectPsicologo(false)
    }

    const removeServico = () => {
        setSelectService({})
        setServico('')
        setSelect(false)
    }

    const actionServico = async () => {
        let error = false;

        if (tipo === '') {
            error = true
            setErrorTipo(true)
        } else setErrorTipo(false)

        if (dia === '') {
            error = true
            setErrorDia(true)
        } else setErrorDia(false)

        if (inicio === '') {
            error = true
            setErrorInicio(true)
        } else setErrorInicio(false)

        if (titulo === '') {
            error = true
            setErrorTitulo(true)
        } else setErrorTitulo(false)

        if (descricao === '') {
            error = true
            setErrorDescricao(true)
        } else setErrorDescricao(false)

        if (localStorage.getItem('empresa') === 'true') {
            if (psicologo === '') {
                error = true
                setErrorPsicologo(true)
            } else setErrorPsicologo(false)
        }


        if (tipo === '2') {
            if (fim === '') {
                error = true
                setErrorFim(true)
            } else setErrorFim(false)

            if (enviarCliente === '') {
                error = true
                setErrorEnviarCliente(true)
            } else setErrorEnviarCliente(false)

            if (enviarCliente === 'true') {
                if (Object.keys(states.Paciente).length === 0) {
                    error = true
                    setError(true)
                } else setError(false)
            }
        }

        if (tipo === '3') {
            if (servico === '') {
                error = true
                setError(true)
            } else setError(false)

            if (Object.keys(states.Paciente).length === 0) {
                error = true
                setErrorCpf(true)
            } else setErrorCpf(false)


        }


        if (error === true) return

        //prestador_id
        //servico_id
        //enviarCliente
        let obj = {};
        if (tipo === '3') {
            obj = {
                tipo: '0',
                inicio: dia + ' ' + inicio + ':00',
                titulo,
                descricao,
                prestador_id: localStorage.getItem('empresa') === 'true' ? psicologo : null,
                servico_id: servico,
                clienteSecundario_id: states.Paciente.id
            }
        } else {
            obj = {
                tipo: '1',
                inicio: dia + ' ' + inicio + ':00',
                fim: fim ? dia + ' ' + fim + ':00' : undefined,
                titulo,
                descricao,
                prestador_id: localStorage.getItem('empresa') === 'true' ? psicologo : null,
                enviarCliente,
                clienteSecundario_id: states.Paciente.id
            }
        }
        if (states.isEdit) {
            obj.id = states.Tarefas.id;
        }
        action(obj)

    }

    const getPaciente = async (cpf) => {
        if (cpf === '') {
            setErrorCpf(true)
            return;
        }
        const resp = await get(cpf)
        if (!resp) {
            setCpf('')
        }
    }



    useEffect(() => {
        if (states.modalTarefas === false) {
            setters.setDataCategoriaPrestador([])
            setters.setLimiteBuscaCategoriaPrestador(false)
            setters.setOffsetCategoriaPrestador(0)
            setters.setPaciente({})
            setters.setDataPrestadores([])
            setters.setLimiteBuscaPrestadores(false)
            setters.setOffsetPrestadores(0)
            setServico('')
            setSelectService({})
            setSelect(false)
            setSelectPsicologo(false)
            setPsicologo('')
            setSelectServicePsicologo({})
            setFim('')
            setCpf('')
            setTitulo('')
            setDescricao('')
            setTipo('')
            setEnviarCliente('')
            setInicio('')
            setDia('')
            setError(false)
            setErrorCpf(false)
            setErrorTitulo(false)
            setErrorDescricao(false)
            setErrorTipo(false)
            setErrorEnviarCliente(false)
            setErrorInicio(false)
            setErrorFim(false)
            setErrorDia(false)
            setters.setDay(moment().format('YYYY-MM-DD'))
            setters.setMonth(moment().format('YYYY-MM'))
        } else {
            setDia(states.day)
            if (states.isEdit) {
                setDia(moment(states.Tarefas.inicio).format('YYYY-MM-DD'))
                setInicio(moment(states.Tarefas.inicio).format('HH:mm'))
                setTipo(states.Tarefas.tipo === '0' ? '3' : '2')
                setTitulo(states.Tarefas.titulo)
                setDescricao(states.Tarefas.descricao)
                setPsicologo(states.Tarefas.prestador_id)
                setSelectPsicologo(true)
                setSelectServicePsicologo({
                    nome: states.Tarefas.prestador_nome,
                    cpf: states.Tarefas.prestador_cpf,
                    telefone: states.Tarefas.prestador_telefone,
                    nome_categoria: states.Tarefas.categoria_nome
                })
                if (states.Tarefas.tipo === '0') {
                    setServico(states.Tarefas.servico_id)
                    setSelect(true)
                    setSelectService({
                        nome: states.Tarefas.servico_nome,
                        categoria_nome: states.Tarefas.categoria_nome
                    })
                }
                if (states.Tarefas.tipo === '1') {
                    setFim(moment(states.Tarefas.fim).format('HH:mm'))
                    setEnviarCliente(states.Tarefas.cpf ? 'true' : 'false')
                }

            }
        }

    }, [states.modalTarefas])

    useEffect(() => {
        if (!states.isEdit) {
            setSelectService({})
            setServico('')
            setSelect(false)
            setSelectPsicologo(false)
            setPsicologo('')
            setSelectServicePsicologo({})
            setFim('')
            setters.setPaciente({})
        }
    }, [tipo])

    useEffect(() => {
        if (enviarCliente === 'false') {
            setters.setPaciente({})
            setError(false)
        }
    }, [enviarCliente])

    useEffect(() => {
        if (dia !== '') {
            setters.setDay(dia)
            setters.setMonth(dia)
        }
    }, [dia])


    return (
        <>
            <Transition.Root show={states.modalTarefas} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setters.setModalTarefas(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full w-[100%] itens-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {/* Mexer na altura, só colocar o height na classname abaixo */}
                                <Dialog.Panel className="w-[80%] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => setters.setModalTarefas(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className='flex-col border-b border-gray-900/10'>
                                        <h3 className="text-base font-semibold leading-6 text-gray-900 text-center mb-4">Adicionar Evento</h3>
                                        <div className='flex justify-center flex-col items-center gap-x-4 pb-4'>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                Preencha os dados para adicionar um novo evento na ageda
                                            </p>
                                        </div>
                                    </div>
                                    <div className='pt-10 pl-10 pr-10'>
                                        <div className="space-y-12">

                                            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                <div>
                                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Informações Principais do evento</h2>
                                                    <p className="mt-1 text-sm leading-6 text-gray-600">Dados principais do atendimento</p>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <div className="grid grid-cols-1 gap-y-6  lg:grid-cols-4 gap-x-6 ">
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Dia</label>
                                                            <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                <input
                                                                    type="date"
                                                                    value={dia}
                                                                    onChange={(e) => setDia(e.target.value)}
                                                                    className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                />
                                                            </div>
                                                            {
                                                                errorDia && <span className="text-red-500 text-sm">Selecione um dia</span>
                                                            }
                                                        </div>

                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Tipo</label>
                                                            <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                <select
                                                                    value={tipo}
                                                                    onChange={(e) => setTipo(e.target.value)}
                                                                    className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                >
                                                                    <option value="">Selecione o tipo</option>
                                                                    <option value="2">Lembrete</option>
                                                                    <option value="3">Serviço</option>
                                                                </select>
                                                            </div>
                                                            {
                                                                errorTipo && <span className="text-red-500 text-sm">Selecione um tipo</span>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-1">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Início</label>
                                                            <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                <input
                                                                    type="time"
                                                                    value={inicio}
                                                                    onChange={(e) => setInicio(e.target.value)}
                                                                    className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                />
                                                            </div>
                                                            {
                                                                errorInicio && <span className="text-red-500 text-sm">Selecione um horário</span>
                                                            }
                                                        </div>
                                                        {
                                                            (tipo === "2") && (
                                                                <div className="sm:col-span-2">
                                                                    <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Fim</label>
                                                                    <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                        <input
                                                                            type="time"
                                                                            value={fim}
                                                                            onChange={(e) => setFim(e.target.value)}
                                                                            className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                        />
                                                                    </div>
                                                                    {
                                                                        errorFim && <span className="text-red-500 text-sm">Selecione um horário</span>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            (tipo === "2") && (
                                                                <div className="sm:col-span-2">
                                                                    <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Enviar para o cliente?</label>
                                                                    <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                        <select
                                                                            value={enviarCliente}
                                                                            onChange={(e) => setEnviarCliente(e.target.value)}
                                                                            className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                        >
                                                                            <option value="">Selecione</option>
                                                                            <option value="true">Enviar</option>
                                                                            <option value="false">Não Enviar</option>
                                                                        </select>
                                                                    </div>
                                                                    {
                                                                        errorEnviarCliente && <span className="text-red-500 text-sm">Selecione uma opção</span>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Título</label>
                                                            <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                <input
                                                                    type="text"
                                                                    value={titulo}
                                                                    onChange={(e) => setTitulo(e.target.value)}
                                                                    className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                />
                                                            </div>
                                                            {
                                                                errorTitulo && <span className="text-red-500 text-sm">Digite um título</span>
                                                            }
                                                        </div>
                                                        <div className="sm:col-span-2">
                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Descrição</label>
                                                            <div className='flex border rounded-[15px] p-3 hover:shadow mt-4'>
                                                                <textarea
                                                                    value={descricao}
                                                                    onChange={(e) => setDescricao(e.target.value)}
                                                                    className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                />
                                                            </div>
                                                            {
                                                                errorDescricao && <span className="text-red-500 text-sm">Digite uma descrição</span>
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {(tipo === '3' || enviarCliente === 'true' || localStorage.getItem('empresa') === 'true') &&
                                                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                                    <div>
                                                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informações adicionais do evento</h2>
                                                        <p className="mt-1 text-sm leading-6 text-gray-600">Dados adicionais</p>
                                                    </div>

                                                    <div className="sm:col-span-2">
                                                        {/* COLOCAR OS DADOS DO SERVIÇO AO SELECIONAR -> limiteBuscaCategoriaPrestador */}
                                                        {
                                                            (!selectPsicologo && localStorage.getItem('empresa') === 'true') ? (
                                                                <>
                                                                    <label htmlFor="title" className=" block text-sm font-semibold leading-5 text-gray-700">Selecione um psicólogo</label>
                                                                    <div className="flex border rounded-[15px] p-3 hover:shadow mt-4">
                                                                        <select
                                                                            value={psicologo}
                                                                            onChange={(e) => handlePsicologo(e.target.value)}
                                                                            className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                        >
                                                                            <option value="">Selecione um psicólogo</option>
                                                                            {states.dataPrestadores.map((servico, index) => (
                                                                                <option key={index} value={servico.id}>{servico.nome} - Categoria: {servico.nome_categoria}</option>
                                                                            ))}
                                                                            {
                                                                                (!states.limiteBuscaPrestadores && states.dataPrestadores.length >= states.limitePrestadores) &&
                                                                                <option value="outro">Carregar mais</option>
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                    {
                                                                        errorPsicologo && <span className="text-red-500 text-sm">Selecione um psicólogo</span>
                                                                    }
                                                                </>
                                                            ) : (selectPsicologo && localStorage.getItem('empresa') === 'true') ? (
                                                                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-4 ">
                                                                    <div className="flex flex-col">
                                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Psicologo</label>
                                                                        <p className="text-sm font-semibold leading-5 text-gray-900">{selectServicePsicologo.nome}</p>
                                                                    </div>
                                                                    <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">CPF</label>
                                                                        <p className="text-sm font-semibold leading-5 text-gray-900">{formatToCpfOrCnpj(selectServicePsicologo.cpf)}</p>
                                                                    </div>
                                                                    <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                                                        <p className="text-sm font-semibold leading-5 text-gray-900">{formatToPhoneBrl(selectServicePsicologo.telefone)}</p>
                                                                    </div>
                                                                    <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                        <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Categoria</label>
                                                                        <p className="text-sm font-semibold leading-5 text-gray-900">{selectServicePsicologo.nome_categoria}</p>
                                                                    </div>
                                                                    <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                        <button type="button" className="text-sm font-semibold leading-5 text-white bg-red-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-red-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-100"
                                                                            onClick={removePsicologo}
                                                                        >
                                                                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            ) : <></>
                                                        }
                                                        {
                                                            (tipo === '3' || enviarCliente === 'true') &&
                                                            <>
                                                                {
                                                                    (Object.keys(states.Paciente).length === 0) ? (
                                                                        <>
                                                                            <label htmlFor="title" className="mt-8 block text-sm font-semibold leading-5 text-gray-700">Digite o CPF do paciente</label>
                                                                            <div className='flex flex-col sm:flex-row items-start sm:items-center gap-x-4 w-full'>
                                                                                <div className="flex border rounded-[15px] p-3 hover:shadow mt-4 w-full">
                                                                                    <input
                                                                                        type="text"
                                                                                        value={formatToCpfOrCnpj(cpf)}
                                                                                        maxLength={14}
                                                                                        onChange={(e) => {
                                                                                            const onlyNumbers = e.target.value.replace(/[^\d]/g, '')
                                                                                            if (onlyNumbers !== '') {
                                                                                                setErrorCpf(false)
                                                                                                setCpf(onlyNumbers)
                                                                                            } else {
                                                                                                setCpf(onlyNumbers)
                                                                                            }
                                                                                        }}
                                                                                        className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                                    />
                                                                                </div>
                                                                                <button type="button" className="text-sm mt-4 font-semibold leading-5 text-white bg-blue-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                                                                                    onClick={() => getPaciente(cpf)}
                                                                                >
                                                                                    <MagnifyingGlassCircleIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </button>
                                                                            </div>
                                                                            {
                                                                                errorCpf && <span className="text-red-500 text-sm">Selecione um paciente</span>
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        //Nome, cpf, email e telefone
                                                                        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-4 mt-12">
                                                                            <div className="flex flex-col">
                                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Nome</label>
                                                                                <p className="text-sm font-semibold leading-5 text-gray-900">{states.Paciente.nome}</p>
                                                                            </div>
                                                                            <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Cpf</label>
                                                                                <p className="text-sm font-semibold leading-5 text-gray-900">{formatToCpfOrCnpj(states.Paciente.cpf)}</p>
                                                                            </div>
                                                                            <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">E-mail</label>
                                                                                <p className="text-sm font-semibold leading-5 text-gray-900">{states.Paciente.email}</p>
                                                                            </div>
                                                                            <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Telefone</label>
                                                                                <p className="text-sm font-semibold leading-5 text-gray-900">{formatToPhoneBrl(states.Paciente.telefone)}</p>
                                                                            </div>
                                                                            <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                                <button type="button" className="text-sm font-semibold leading-5 text-white bg-red-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-red-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-100"
                                                                                    onClick={() => {
                                                                                        setters.setPaciente({})
                                                                                        setCpf('')
                                                                                    }}
                                                                                >
                                                                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                    )
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            tipo === '3' &&
                                                            <div className="sm:col-span-3 mt-5">
                                                                {
                                                                    !select ? (
                                                                        <>
                                                                            <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Selecione o serviço</label>
                                                                            <div className="flex border rounded-[15px] p-3 hover:shadow mt-4">
                                                                                <select
                                                                                    value={servico}
                                                                                    onChange={(e) => handleServico(e.target.value)}
                                                                                    className="w-full text-sm font-semibold leading-5 text-gray-700 focus:outline-none"
                                                                                >
                                                                                    <option value="">Selecione o serviço</option>
                                                                                    {states.dataCategoriaPrestador.map((servico, index) => (
                                                                                        <option key={index} value={servico.id}>{servico.nome} - Categoria: {servico.categoria_nome}</option>
                                                                                    ))}
                                                                                    {
                                                                                        (!states.limiteBuscaCategoriaPrestador && states.dataCategoriaPrestador.length >= states.limiteCategoriaPrestador) &&
                                                                                        <option value="outro">Carregar mais</option>
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            {
                                                                                error && <span className="text-red-500 text-sm">Selecione um serviço</span>
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-x-4">
                                                                            <div className="flex flex-col">
                                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Serviço</label>
                                                                                <p className="text-sm font-semibold leading-5 text-gray-900">{selectService.nome}</p>
                                                                            </div>
                                                                            <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Duração</label>
                                                                                <p className="text-sm font-semibold leading-5 text-gray-900">{selectService.duracao}</p>
                                                                            </div>
                                                                            <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Preço</label>
                                                                                <p className="text-sm font-semibold leading-5 text-gray-900">{formatToMoney(selectService.preco)}</p>
                                                                            </div>
                                                                            <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                                <label htmlFor="title" className="block text-sm font-semibold leading-5 text-gray-700">Categoria</label>
                                                                                <p className="text-sm font-semibold leading-5 text-gray-900">{selectService.categoria_nome}</p>
                                                                            </div>
                                                                            <div className="flex flex-col mt-3 sm:mt-0 sm:ml-2">
                                                                                <button type="button" className="text-sm font-semibold leading-5 text-white bg-red-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-red-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-100"
                                                                                    onClick={removeServico}
                                                                                >
                                                                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                    )
                                                                }

                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="mt-6 flex flex-col sm:flex-row items-center justify-end gap-x-6">
                                            <button type="button" className="text-sm font-semibold leading-5 text-white bg-blue-500 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-blue-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-100"
                                                onClick={actionServico}
                                            >
                                                Adicionar Evento
                                            </button>
                                            <button type="button" className="text-sm mt-3 sm:mt-0 font-semibold leading-5 text-gray-900 bg-gray-100 rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-100"
                                                onClick={() => setters.setModalTarefas(false)}>
                                                Fechar
                                            </button>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root >
        </>
    )

}


export default ExecucoesServico;