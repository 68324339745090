import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../Context';
import { AuthContextApi } from '../../../../Context/api';
import { InformationCircleIcon, ArrowLeftIcon, TrashIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid'
import moment from 'moment'
import Modal from '../../../../Components/Modal/Conversa';



const ConversasClienteSecundario = () => {

    const { states, setters } = useContext(AuthContext)
    const { checkLogin,
        getPacienteData,
        getPacientConversas,
        encerrarConversa,
        excluirtConversa,
        addConversa
    } = useContext(AuthContextApi)
    const { token, id } = useParams()
    const navigate = useNavigate()

    // console.log(id)
    // console.log(prontuario)




    const primeiroLoad = async () => {
        await getPacienteData(id)
        await checkLogin()
        await getPacientConversas(id)

    }

    const formatToCpfOrCnpj = (value) => {
        if (value?.length === 11) {
            return value?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        } else {
            return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatCep = (value) => {
        return value?.replace(/^(\d{5})(\d{3})/, '$1-$2')
    }

    const formatToPhoneBrl = (value) => {
        // Checa se o número tem 9 dígitos após o DDD
        if (value?.length === 11) {
            return value.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
        }
        // Checa se o número tem 8 dígitos após o DDD
        else if (value?.length === 10) {
            return value.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
        }
        // Retorna o valor original caso não corresponda a nenhum formato esperado
        return value;
    }

    const loadMoreConversa = async () => {
        if (states.limiteBuscaConversasPaciente) return
        setters.setOffsetConversasPaciente(states.offsetConversasPaciente + states.limiteConversasPaciente)

    }

    const encerrarConversaAction = async (data) => {
        setters.setModalUsuarios(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja mesmo encerrar a conversa com o paciente ${states.Paciente.nome}?`)
        setters.setDescModalMsg(`Após a confirmação não será possível reabrir a conversa e nem o envio de novas mensagens.`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            await encerrarConversa(data.id, id)
            setters.setModalMsg(false)
        })
    }

    const excluirConversaAction = async (data) => {
        setters.setModalUsuarios(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja mesmo excluir a conversa com o paciente ${states.Paciente.nome}?`)
        setters.setDescModalMsg(`Após a confirmação os dados da conversa serão apagados permanentemente.`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            await excluirtConversa(data.id, id)
            setters.setModalMsg(false)
        })
    }

    const addConversaModal = async () => {
        setters.setModalConversasPaciente(true)
    }

    const addConversaAction = async (data) => {
        setters.setModalConversasPaciente(false)
        setters.setModalMsg(true)
        setters.setPerigoModalMsg(false)
        setters.setMsgModalMsg(`Deseja mesmo adicionar a conversa com o paciente ${states.Paciente.nome}?`)
        setters.setDescModalMsg(`Após a confirmação a conversa será registrada e será possível enviar mensagens.`)
        setters.setTitleButtonModalMsg('Confirmar')
        setters.setFunctionModalMsg(() => async () => {
            const response = await addConversa(data, id)
            setters.setModalMsg(false)
            if (response.status) {
                navigate(`/pacientes/${token}/paciente/${id}/conversa/${response.data.id}`)
            }
        })

    }



    useEffect(() => {
        primeiroLoad()

        return () => {
            setters.setDataConversasPaciente([])
            setters.setConversasPaciente({})
            setters.setOffsetConversasPaciente(0)
            setters.setLimiteBuscaConversasPaciente(false)

        }

    }, [])

    useEffect(() => {
        if (states.offsetConversasPaciente > 0) {
            getPacientConversas(id)
        }

    }, [states.offsetConversasPaciente])



    return (
        <>
            <div className="flex flex-col">


                <div className="flex flex-col sm:flex-row p-4 border hover:shadow-md rounded-md">
                    <div className='flex flex items-center justify-between'>
                        <button onClick={() => navigate(`/pacientes/${token}/paciente/${id}`)} className="flex h-[50%] items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                            <ArrowLeftIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                        </button>
                    </div>
                    <div className='mt-5 ml-5'>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Conversas registradas</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            Paciente: {states.Paciente.nome}
                        </p>
                    </div>
                </div>
                <div className="flex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Dados de paciente</h1>
                    <div className='flex flex-col sm:flex-row  items-between justify-between p-1'>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Dados de cadastro: </p>
                            <p className="text-md text-gray-700 mt-2">{states.Paciente.nome} - {formatToCpfOrCnpj(states.Paciente.cpf)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Contato:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.Paciente.email} - {formatToPhoneBrl(states.Paciente.telefone)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Endereço:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.Paciente.rua}, {states.Paciente.numero} - {states.Paciente.bairro} - {formatCep(states.Paciente.cep)}</p>
                        </div>
                        <div className='flex flex-col  mt-2 p-1'>
                            <p className="text-md text-gray-700">Localização:</p>
                            <p className="text-md text-gray-700 sm:ml-3 mt-2">{states.Paciente.cidade} - {states.Paciente.estado}</p>
                        </div>
                    </div>
                </div>

                <div className="flex-1 p-6 border  hover:shadow-md mt-5 rounded-md">
                    <div className='flex items-center justify-between'>
                        <h1 className="text-base font-semibold leading-6 text-gray-900">Conversas Registradas</h1>
                        <button className="flex h-[50%] items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]"
                            onClick={addConversaModal}
                        >
                            <PlusIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                            <span>Nova Conversa</span>
                        </button>
                    </div>
                    <div className='flex flex-col w-full  items-between justify-between p-1'>
                        {
                            states.dataConversasPaciente.length > 0 ? (
                                <>
                                    {
                                        states.dataConversasPaciente.map((conversa, index) => {
                                            return (
                                                <div key={index} className='flex flex-col border w-full  mt-2 p-1 rounded-md hover:shadow-md '>
                                                    <div className='flex items-center flex-col sm:flex-row justify-between p-4'>
                                                        <p className="text-md text-gray-700">Conversa: </p>
                                                        <p className="text-md text-gray-700 mt-2">{conversa.titulo}</p>
                                                    </div>
                                                    <div className='flex items-center flex-col sm:flex-row justify-between p-4'>
                                                        <p className="text-md text-gray-700 mt-2">Status da conversa:</p>
                                                        <p className="text-md text-gray-700 mt-2">{conversa.aberto === 1 ? <span className="text-green-500">Aberto</span> : <span className="text-red-500">Fechado</span>}</p>
                                                    </div>
                                                    <div className='flex items-center flex-col sm:flex-row justify-between p-4'>
                                                        <p className="text-md text-gray-700 mt-2">Data da conversa:</p>
                                                        <p className="text-md text-gray-700 mt-2">{moment(conversa.data_criacao).format('DD/MM/YYYY')}</p>
                                                    </div>
                                                    <div className='flex items-center flex-col sm:flex-row justify-between p-4'>
                                                        <button onClick={() => navigate(`/pacientes/${token}/paciente/${id}/conversa/${conversa.id}`)} className="p-4 flex h-[50%] mt-2 sm:mt-0 items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                                                            <InformationCircleIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                            <span>Detalhes</span>
                                                        </button>
                                                        {
                                                            conversa.aberto === 1 ? (
                                                                <button onClick={() => encerrarConversaAction(conversa)} className="p-4 flex h-[50%] mt-2 sm:mt-0 items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                                                                    <XMarkIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                                    <span>Encerrar conversa</span>
                                                                </button>
                                                            ) : (
                                                                <button onClick={() => excluirConversaAction(conversa)} className="p-4 flex h-[50%] mt-2 sm:mt-0 items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                                                                    <TrashIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                                                                    <span>Excluir</span>
                                                                </button>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            ) : (
                                <div className='flex flex-col  mt-2 p-1'>
                                    <p className="text-md text-gray-700">Nenhuma conversa registrada</p>
                                </div>
                            )
                        }
                        {
                            (!states.limiteBuscaConversasPaciente && states.dataConversasPaciente.length > 0) && (
                                <div className='flex flex-col w-full items-center justify-center  mt-2 p-1'>
                                    <button onClick={loadMoreConversa} className="flex h-[50%] w-[10%] text-center items-center jutify-center text-sm font-medium hover:shadow text-blue-500 hover:text-blue-700 border p-2 rounded-[15px]">
                                        <span>Carregar mais</span>
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>




            </div>
            <Modal states={states} setters={setters} action={addConversaAction} />
        </>
    )

}



export default ConversasClienteSecundario;