const jsonConfig = {
  urlReact: "https://plataforma.sotertec.com.br",
  urlReactClientes: "https://paciente.sotertec.com.br",
  // urlAPI: "https://psicologo.sotertec.com.br/psicologo",
  urlAPI: 'https://psicologo.sotertec.com.br/psicologo',
  APIKEY: `Bearer ${process.env.REACT_APP_APIKEY}`,
  limitDefault: 15,
  limitDefaultMsg: 8,
  adminPermission: "CLIENTE",
  prestadorPermission: "CLIENTEPRESTADOR",
  operacionalPermission: "CLIENTEOPERACIONAL",
  entidadeRegistro: "CRP",
  nomeClienteSecundario: "Pacientes",
};

export { jsonConfig };
// git add . && git commit -m "feax: deploy" && git push

// 'https://plataforma.sotertec.com.br', 'https://paciente.sotertec.com.br', 'https://administrativo.sotertec.com.br'
