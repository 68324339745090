import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../Context";
import { AuthContextApi } from "../../../Context/api";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import NavMenu from "../../../Components/NavMenu/Administrativo";
import Table from "../../../Components/Table/Categorias";
import moment from "moment";
import Modal from "../../../Components/Modal/Categorias/Categoria";
import ModalShow from "../../../Components/Modal/Categorias/CategoriaShow";

const Categoria = () => {
  const { states, setters } = useContext(AuthContext);
  const {
    checkLogin,
    getCategoriasDataSistema,
    getCategorias,
    getCategoriasQuery,
    putCategoria,
    postCategoria,
    putCategoriaStatus,
    deleteCategoria,
  } = useContext(AuthContextApi);
  const [query, setQuery] = useState("");
  const { token } = useParams();

  const primeiroLoad = async () => {
    await getCategoriasDataSistema(token);
    await getCategorias();
    await checkLogin();
  };

  const loadUsuarios = async () => {
    if (query !== "") {
      await getCategoriasQuery(query);
    } else {
      await getCategorias();
    }
  };

  const next = async () => {
    if (states.limiteBuscaCategorias) {
      if (states.offsetCategorias + states.limiteCategorias > 0) return;
      setters.setLimiteBuscaCategorias(true);
    }
    setters.setOffsetCategorias(
      states.offsetCategorias + states.limiteCategorias
    );
  };

  const previous = async () => {
    if (states.offsetCategorias - states.limiteCategorias < 0) return;
    if (states.limiteBuscaCategorias) {
      setters.setLimiteBuscaCategorias(false);
    }
    setters.setOffsetCategorias(
      states.offsetCategorias - states.limiteCategorias
    );
  };

  const search = async () => {
    setters.setOffsetCategorias(0);
    if (query === "") {
      await getCategorias();
      return;
    }
    const response = await getCategoriasQuery(query);
    if (response === false) {
      setQuery("");
      await getCategorias();
    }
  };

  const clear = async () => {
    setters.setOffset(0);
    setQuery("");
    await getCategorias();
  };

  const addCategoria = () => {
    setters.setIsEdit(false);
    setters.setCategorias({});
    setters.setModalCategorias(true);
  };

  const editCategoria = (data) => {
    setters.setIsEdit(true);
    setters.setCategorias(data);
    setters.setModalCategorias(true);
  };

  const changeStatus = (data) => {
    setters.setModalUsuarios(false);
    setters.setModalMsg(true);
    setters.setPerigoModalMsg(data.status);
    const msg = data.status === 1 ? "Desativar" : "Ativar";
    setters.setMsgModalMsg(`Deseja mesmo ${msg} a categoria ${data.nome}?`);
    setters.setDescModalMsg(
      `Após a confirmação a categoria será ${
        data.status === 1 ? "desativada" : "ativada"
      }`
    );
    setters.setTitleButtonModalMsg("Confirmar");
    setters.setFunctionModalMsg(() => async () => {
      await putCategoriaStatus({
        ...data,
        status: data.status === 1 ? "false" : "true",
      });
      setters.setModalMsg(false);
    });
  };

  const deleteCategoriaAction = (data) => {
    setters.setModalUsuarios(false);
    setters.setModalMsg(true);
    setters.setPerigoModalMsg(true);
    setters.setMsgModalMsg(`Deseja mesmo excluir o categoria ${data.nome}?`);
    setters.setDescModalMsg(
      `Após a confirmação a categoria será excluída e não poderá ser recuperada`
    );
    setters.setTitleButtonModalMsg("Confirmar");
    setters.setFunctionModalMsg(() => async () => {
      await deleteCategoria(data);
      setters.setModalMsg(false);
    });
  };

  const actionModal = async (data) => {
    setters.setModalCategorias(false);
    if (states.isEdit) {
      setters.setIsEdit(false);
      await putCategoria(data);
    } else {
      await postCategoria(data);
    }
  };

  const showCategoria = (data) => {
    setters.setCategorias(data);
    setters.setModalCategoriasShow(true);
  };

  useEffect(() => {
    primeiroLoad();

    return () => {
      setQuery("");
      setters.setDataEstatisticaCategorias([]);
      setters.setDataCategorias([]);
      setters.setCategorias({});
      setters.setOffsetCategorias(0);
      setters.setLimiteBuscaCategorias(false);
    };
  }, []);

  useEffect(() => {
    if (states.dataCategorias?.length === 0) return;
    loadUsuarios();
  }, [states.offsetCategorias]);

  const headers = [
    "",
    "Nome",
    "Descrição",
    "Status",
    "Data de Criação",
    "Data de Atualização",
  ];

  return (
    <>
      <div className="flex flex-col">
        <NavMenu />
        <div className="flex-1 p-4 border  hover:shadow-md">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Categorias
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Informações sobre as categorias de serviços cadastrados no seu
            sistema
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 mt-4">
          {states.dataEstatisticaCategorias?.length > 0 &&
            states.dataEstatisticaCategorias.map((card, index) => (
              <div
                key={card.title}
                className={
                  card.value > 0 && index === -1
                    ? "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                    : "overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md"
                }
              >
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      {card.value > 0 && index === -1 ? (
                        <InformationCircleIcon
                          className="w-6 h-6 text-red-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <InformationCircleIcon
                          className="w-6 h-6 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        {card.value > 0 && index === -1 ? (
                          <dt className="truncate text-sm font-medium text-red-500">
                            {card.title}
                          </dt>
                        ) : (
                          <dt className="truncate text-sm font-medium text-gray-500">
                            {card.title}
                          </dt>
                        )}

                        <dd>
                          {card.value > 0 && index === -1 ? (
                            <div className="text-lg font-medium text-red-500">
                              {card.value}
                            </div>
                          ) : (
                            <div className="text-lg font-medium text-gray-900">
                              {card.value}
                            </div>
                          )}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {states.dataCategorias?.length > 0 ? (
          <>
            <div className="flex-1 p-12 border  hover:shadow-md mt-5">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Pesquisa por categoria
              </h1>
              <div className="flex flex-col mt-4">
                <p className="text-sm text-gray-700">
                  Pesquise por nome ou descrição
                </p>
                <div className="flex flex-col sm:flex-row mt-2">
                  <input
                    type="text"
                    className=" flex w-full sm:w-[80%] mt-1 p-2 border-2 border-gray-200 rounded-md"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        search();
                      }
                    }}
                  />
                  <button
                    className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                    onClick={search}
                  >
                    Pesquisar
                  </button>
                  <button
                    className="flex mt-2 sm:mt-0 w-full sm:w-[10%]  ml-2 justify-center items-center p-2 bg-green-600 text-white rounded-md hover:bg-green-500"
                    onClick={clear}
                  >
                    Limpar
                  </button>
                </div>
              </div>
            </div>
            <div className=" p-4 border hover:shadow-md mt-5">
              {states.dataCategorias?.length > 0 && (
                <Table
                  title="Categoria"
                  description="Registro das categorias cadastrados"
                  next={next}
                  add={addCategoria}
                  edit={editCategoria}
                  status={changeStatus}
                  show={showCategoria}
                  previous={previous}
                  states={states}
                  setters={setters}
                  headers={headers}
                  del={deleteCategoriaAction}
                  infoTable={{
                    offset: states.offsetCategorias,
                    limit: states.limiteCategorias,
                  }}
                  dataTable={states.dataCategorias}
                  query={query}
                />
              )}
            </div>
          </>
        ) : (
          <div className="flex-1 p-12 border  hover:shadow-md mt-5">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Sem categorias cadastrados
            </h1>
          </div>
        )}
      </div>
      <Modal states={states} setters={setters} action={actionModal} />
      <ModalShow states={states} setters={setters} />
    </>
  );
};

export default Categoria;
