import { useContext, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import { AuthContextApi } from "../../Context/api";
import {
  BanknotesIcon,
  BuildingLibraryIcon,
  CalendarDaysIcon,
  Cog6ToothIcon,
  HomeIcon,
  UserGroupIcon,
  UserIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";

const AjudaIndex = () => {
  const { checkLogin } = useContext(AuthContextApi);
  const { token } = useParams();

  const primeiroLoad = async () => {
    await checkLogin();
  };

  useEffect(() => {
    primeiroLoad();
    return () => {
      primeiroLoad();
    };
  }, []);

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mb-4 border flex-col flwx-w-full md:w-[100%] items-center justify-center p-4 border-1 border-gray-300 rounded-[10px] overflow-hidden bg-white hover:shadow-sm">
          <h1 className="text-3xl text-center font-bold">Painel de Ajuda</h1>
          <p className="text-[18px] mt-1 text-center">
            Aqui você terá acesso ao painel de ajuda do seu sistema
          </p>
        </div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
          <div className="flex items-center justify-center hover:shadow">
            <Link
              to={`/ajuda/${token}/dashboard`}
              className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border-1 border-gray-300 rounded-[15px] overflow-hidden bg-white hover:shadow-sm"
            >
              <HomeIcon className="h-8 w-8 text-blue-600" />
              <h2 className="text-xl font-bold text-center">Dashboard</h2>
              <p className="text-center">
                Clique nesse cartão para acessar o manual de funcionamento do
                painel de dashboard
              </p>
            </Link>
          </div>
          <div className="flex items-center justify-center hover:shadow">
            <Link
              to={`/ajuda/${token}/agenda`}
              className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border-1 border-gray-300 rounded-[15px] overflow-hidden bg-white hover:shadow-sm"
            >
              <CalendarDaysIcon className="h-8 w-8 text-blue-600" />
              <h2 className="text-xl text-center font-bold">Agenda</h2>
              <p className="text-center">
                Clique nesse cartão para acessar o manual de funcionamento do
                painel da agenda
              </p>
            </Link>
          </div>
          <div className="flex items-center justify-center hover:shadow">
            <Link
              to={`/ajuda/${token}/pacientes`}
              className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border-1 border-gray-300 rounded-[15px] overflow-hidden bg-white hover:shadow-sm"
            >
              <UserGroupIcon className="h-8 w-8 text-blue-600" />
              <h2 className="text-xl text-center font-bold">Pacientes</h2>
              <p className="text-center">
                Clique nesse cartão para acessar o manual de funcionamento do
                painel de pacientes
              </p>
            </Link>
          </div>
          <div className="flex items-center justify-center hover:shadow">
            <Link
              to={`/ajuda/${token}/administrativo`}
              className="w-full h-[100%] text-black border p-4 flex items-center 
            justify-center flex-col border-1 border-gray-300 rounded-[15px]
            overflow-hidden bg-white hover:shadow-sm"
            >
              <BuildingLibraryIcon className="h-8 w-8 text-blue-600" />
              <h2 className="text-xl text-center font-bold">Administrativo</h2>
              <p className="text-center">
                Clique nesse cartão para acessar o manual de funcionamento do
                painel administrativo
              </p>
            </Link>
          </div>
          <div className="flex items-center justify-center hover:shadow">
            <Link
              to={`/ajuda/${token}/financeiro`}
              className="w-full h-[100%] text-black border p-4 flex items-center 
            justify-center flex-col border-1 border-gray-300 rounded-[15px]
            overflow-hidden bg-white hover:shadow-sm"
            >
              <BanknotesIcon className="h-8 w-8 text-blue-600" />
              <h2 className="text-xl text-center font-bold">Financeiro</h2>
              <p className="text-center">
                Clique nesse cartão para acessar o manual de funcionamento do
                painel financeiro
              </p>
            </Link>
          </div>
          <div className="flex items-center justify-center hover:shadow">
            <Link
              to={`/ajuda/${token}/usuarios`}
              className="w-full h-[100%] text-black border p-4 flex items-center 
            justify-center flex-col border-1 border-gray-300 rounded-[15px]
            overflow-hidden bg-white hover:shadow-sm"
            >
              <UserPlusIcon className="h-8 w-8 text-blue-600" />
              <h2 className="text-xl text-center font-bold">Usuários</h2>
              <p className="text-center">
                Clique nesse cartão para acessar o manual de funcionamento do
                painel de usuários
              </p>
            </Link>
          </div>
          <div className="flex items-center justify-center hover:shadow">
            <Link
              to={`/ajuda/${token}/configuracoes`}
              className="w-full h-[100%] text-black border p-4 flex items-center 
            justify-center flex-col border-1 border-gray-300 rounded-[15px]
            overflow-hidden bg-white hover:shadow-sm"
            >
              <Cog6ToothIcon className="h-8 w-8 text-blue-600" />
              <h2 className="text-xl text-center font-bold">Configurações</h2>
              <p className="text-center">
                Clique nesse cartão para acessar o manual de funcionamento do
                painel de configurações
              </p>
            </Link>
          </div>
          <div className="flex items-center justify-center hover:shadow">
            <Link
              to={`/ajuda/${token}/perfil`}
              className="w-full h-[100%] text-black border p-4 flex items-center 
            justify-center flex-col border-1 border-gray-300 rounded-[15px]
            overflow-hidden bg-white hover:shadow-sm"
            >
              <UserIcon className="h-8 w-8 text-blue-600" />
              <h2 className="text-xl text-center font-bold">Perfil</h2>
              <p className="text-center">
                Clique nesse cartão para acessar o manual de funcionamento do
                painel do perfil
              </p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AjudaIndex;
