import NavMenu from "../../../Components/NavMenu/Ajuda";

import usuario from "../../../Assets/Usuarios/usuarios.png";
import addUsuario from "../../../Assets/Usuarios/addUsuario.png";
import usuarios from "../../../Assets/Usuarios/usuarioUsuarios.png";
import editarUsuario from "../../../Assets/Usuarios/editarUsuario.png";
import bloquearUsuario from "../../../Assets/Usuarios/bloquearUsuario.png";
import visualizarUsuario from "../../../Assets/Usuarios/visualisarUsuario.png";
import trocarSenha from "../../../Assets/Usuarios/trocarSenhaUsuario.png";

const AjudaUsuarios = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <NavMenu />
      <div className="mb-4 border flex-col flwx-w-full md:w-[100%] items-center justify-center p-4 border-1 border-gray-300 rounded-[10px] overflow-hidden bg-white hover:shadow-sm">
        <h1 className="text-3xl text-center font-bold">
          Painel de Ajuda: Usuarios
        </h1>
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela do painel de Usuário, temos dois cartões nela.
        </p>
        <hr />
        <img
          src={usuario}
          alt="tela usuario"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Tela de usuarios. Nessa tela temos todos os usuários do sistema.
        </p>
        <hr />
        <img
          src={usuarios}
          alt="tela de usuarios"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Na tabela de usuários ao clicar no '+' é possivel adicionar um novo
          usuário como podemos ver na tela a seguir.
        </p>
        <hr />
        <img
          src={addUsuario}
          alt="tela adicionar usuario"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Na tabela de usuários temos a lupa que é para visualizar as
          informações do usuárrio em questão.
        </p>
        <hr />
        <img
          src={visualizarUsuario}
          alt="tela visualizar usuario"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Na tabela de usuários o lápis é para fazer a edição do usuário em
          questao.
        </p>
        <hr />
        <img
          src={editarUsuario}
          alt="tela editar usuario"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Na tabela de usuários o cadeado é para bloquear usuário em questao.
        </p>
        <hr />
        <img
          src={bloquearUsuario}
          alt="tela editar usuario"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>

      <div className="mt-4 border border-1 rounded-md mb-4">
        <p className="font-semibold text-justify p-4">
          Na tabela de usuários a seta é para gerar um link para trocar a senha
          do usuário em questao.
        </p>
        <hr />
        <img
          src={trocarSenha}
          alt="tela editar usuario"
          className="mx-auto rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
};

export default AjudaUsuarios;
