import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Context";
import { AuthContextApi } from "../../Context/api";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import Calendario from "../../Components/Calendario";
import ListaTarefas from "../../Components/ListaDeTarefas";
import Modal from "../../Components/Modal/Marcacao";
import ModalShow from "../../Components/Modal/Marcacao/MarcacaoShow";
import AgendaNotFound from '../../Assets/AgendaNotFound/agenda.png';

const Agenda = () => {
  const { states, setters } = useContext(AuthContext);
  const {
    checkLogin,

    getAgendaMonth,
    getDayCompromissos,
    getDayCompromissosMore,
    getPacientesClienteCpf,
    getServicosPrestadores,
    getPrestadoresForm,
    postCompromisso,
    putCompromisso,
    cancelCompromisso,
    deletarCompromisso,
  } = useContext(AuthContextApi);
  const [cumprimento, setCumprimento] = useState("");
  const [nomeUsuario, setNomeUsuario] = useState("");

  const getGreeting = () => {
    const hour = moment().hour(); // Pega a hora atual
    if (hour < 12) {
      setCumprimento("Bom dia");
    } else if (hour < 18) {
      setCumprimento("Boa tarde");
    } else {
      setCumprimento("Boa noite");
    }
  };

  const primeiroLoad = async () => {
    await checkLogin();
    await getAgendaMonth(states.month);
    await getDayCompromissos(states.day);
  };

  const truncatFunction = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const nextMonth = () => {
    const newData = moment(states.month).add(1, "month").format("YYYY-MM");
    setters.setMonth(newData);
    getAgendaMonth(newData);
  };

  const prevMonth = () => {
    const newData = moment(states.month).subtract(1, "month").format("YYYY-MM");
    setters.setMonth(newData);
    getAgendaMonth(newData);
  };

  const tarefasDay = async (day) => {
    setters.setDay(day.day);
    setters.setOffsetTarefas(0);
    await getDayCompromissos(day.day);
  };

  const loadMoreTarefas = async () => {
    await getDayCompromissosMore(states.day);
  };

  const loadMorePrestador = async () => {
    await getPrestadoresForm();
  };

  const addEvento = async (data) => {
    if (localStorage.getItem('empresa') === 'true') {
      await getPrestadoresForm();
    }
    await getServicosPrestadores();
    setters.setIsEdit(false);
    setters.setModalTarefas(true);
    setters.setPacientes({});
  };

  const editEvento = async (data) => {
    if (localStorage.getItem('empresa') === 'true') {
      await getPrestadoresForm();
    }
    await getServicosPrestadores();
    if (data.cpf !== null) {
      await getPacientesClienteCpf(data.cpf);
    }
    setters.setIsEdit(true);
    setters.setTarefas(data);
    setters.setModalTarefas(true);
    setters.setPacientes({});
  };

  const showEvento = async (data) => {
    setters.setModalTarefasShow(true);
    setters.setTarefas(data);
  };

  const actionAddEvento = async (data) => {
    setters.setModalTarefas(false);
    if (!states.isEdit) {
      await postCompromisso(data);
    } else {
      setters.setTarefas({});
      setters.setIsEdit(false);
      await putCompromisso(data, data.id);
    }
  };

  const loadMoreCategoriaPrestador = async () => {
    await getServicosPrestadores();
  };

  const cancelarEvento = async (data) => {
    setters.setModalExecucoesServicoPaciente(false);
    setters.setModalMsg(true);
    setters.setPerigoModalMsg(true);
    setters.setMsgModalMsg(`Deseja cancelar o agendamennto ${data.titulo} ?`);
    setters.setDescModalMsg(
      `Após a confirmação o agendamento será cancelado e não poderá ser recuperado.`
    );
    setters.setTitleButtonModalMsg("Cancelar");
    setters.setFunctionModalMsg(() => async () => {
      await cancelCompromisso(data.id);
      setters.setModalMsg(false);
    });
  };

  const deletarEvento = async (data) => {
    setters.setModalExecucoesServicoPaciente(false);
    setters.setModalMsg(true);
    setters.setPerigoModalMsg(true);
    setters.setMsgModalMsg(`Deseja deletar o agendamennto ${data.titulo} ?`);
    setters.setDescModalMsg(
      `Após a confirmação o agendamento será deletado e não poderá ser recuperado.`
    );
    setters.setTitleButtonModalMsg("Deletar");
    setters.setFunctionModalMsg(() => async () => {
      await deletarCompromisso(data.id);
      setters.setModalMsg(false);
    });
  };

  useEffect(() => {
    if (states.dataCategoriaPrestador.length > 0) {
      loadMoreCategoriaPrestador();
    }
  }, [states.offsetCategoriaPrestador]);

  useEffect(() => {
    if (states.dataPrestadores.length > 0) {
      loadMorePrestador();
    }
  }, [states.offsetPrestadores]);

  useEffect(() => {
    if (states.dataTarefas.length > 0 && states.offsetTarefas > 0) {
      loadMoreTarefas();
    }
  }, [states.offsetTarefas]);

  useEffect(() => {
    if (localStorage.getItem('haveAgenda') === 'true') {
      primeiroLoad();
    }
    getGreeting();
    const nome = localStorage.getItem("nome");
    setNomeUsuario(nome);

    return () => {
      setters.setDataAgenda([]);
      setters.setDataTarefas([]);
      setters.setLimiteBuscaTarefas(false);
      setters.setTarefas({});
      setters.setOffsetCategoriaPrestador(0);
      setters.setLimiteBuscaCategoriaPrestador(false);
      setters.setPaciente({});
      setters.setDataPrestadores([]);
      setters.setOffsetPrestadores(0);
      setters.setLimiteBuscaPrestadores(false);
    };
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex-1 p-4 border  hover:shadow-md">
        <div className="flex items-center justify-between">
          <h1 className="hidden sm:flex text-2xl font-bold leading-7 text-gray-900 sm:leading-9">
            {cumprimento}, {nomeUsuario}
          </h1>
          <h1 className="flex sm:hidden text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
            {cumprimento}, {truncatFunction(nomeUsuario, 18)}
          </h1>
          <CheckCircleIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
            aria-hidden="true"
          />
        </div>
        <p className="text-sm text-gray-500 mt-4 ">
          Seja bem-vindo ao Painel de agenda, aqui você pode visualizar os
          atendimentos do dia, semana e mês.
        </p>
      </div>
      {/* Acesso rápido tem que ser aqui, antes das estatisticas */}
      {
        (localStorage.getItem('haveAgenda') === 'true') ?
          <div className="grid grid-cols-1 gap-4  lg:grid-cols-4 mt-4">
            <div className="flex items-center p-4 border hover:shadow-md col-span-3">
              <Calendario
                data={states.dataAgenda}
                nextMonth={nextMonth}
                prevMonth={prevMonth}
                states={states}
                nextDay={tarefasDay}
                add={addEvento}
              />
            </div>
            <div className="flex items-center p-4 border hover:shadow-md">
              <ListaTarefas
                states={states}
                data={states.dataTarefas}
                setters={setters}
                show={showEvento}
                edit={editEvento}
                cancel={cancelarEvento}
                del={deletarEvento}
              />
            </div>
          </div> : (
            <div className="mt-4 flex flex-col justify-start items-start">
              <p className="text-2xl font-bold text-gray-900">Faça o upgrade do seu plano para ter acesso a agenda</p>
              <p className="text-lg font-bold text-gray-900">Tenha o controle da sua agenda e das tarefas em um clique</p>
              <img src={AgendaNotFound} alt="Agenda não encontrada" />
            </div>
          )
      }

      <Modal
        states={states}
        setters={setters}
        get={getPacientesClienteCpf}
        action={actionAddEvento}
      />
      <ModalShow states={states} setters={setters} />
    </div>
  );
};

export default Agenda;
