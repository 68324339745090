
import { AuthContext } from "../../Context"
import { useParams, useNavigate } from 'react-router-dom'
import { AuthContextApi } from "../../Context/api"
import { useContext, useEffect } from "react"
import Footer from "../../Components/Footer"
import { jsonConfig } from "../../Config"
import './index.css'
// import Cookies from 'js-cookie'


const Forgot = () => {
    const { states, setters } = useContext(AuthContext)
    const { profileAutenticationEmail, checkConfigCliente } = useContext(AuthContextApi)
    const navigate = useNavigate()

    const token = useParams().token
    const cliente = useParams().cliente
    
    const action = async () => {
        const resp = await profileAutenticationEmail(token, { cliente_id: cliente })
        if (resp) {
            localStorage.removeItem('token')
            localStorage.removeItem('permisssion')
            localStorage.removeItem('cliente')
            localStorage.removeItem('usuario')
            setters.setSigned(false)
            navigate(`/plataforma/${cliente}`)
        }
    }


    const firstLoad = async () => {
        await checkConfigCliente(cliente)
    }


    useEffect(() => {
        if (states.errorAcessoCliente === true) {
            setters.setClientePossuiConfig(false)
            setters.setClienteConfig({})
        } else {
            setters.setErrorAcessoCliente(false)
            if (states.clientePossuiConfig === true) {
                setters.setClientePossuiConfig(true)
                setters.setClienteConfig(JSON.parse(localStorage.getItem('clienteConfig')))
            }
        }
    }, [states.errorAcessoCliente, states.clientePossuiConfig])



    useEffect(() => {
        firstLoad()
    }, [])





    return (
        <>
            <div className="h-screen">
                <div className="flex min-h-full">
                    <div
                        className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
                        {/* border-2 border-inherit */}
                        <div className="mx-auto w-full max-w-sm lg:w-96 items-center jutify-center">

                            {
                                states.errorAcessoCliente === false ? (
                                    <>
                                        <div className="flex flex-col justify-center items-center">
                                            {
                                                states.clientePossuiConfig === false ? (<>
                                                    <img
                                                        className="h-[5em] w-auto"
                                                        src={`${jsonConfig.urlReact}/logo.png`}
                                                        alt={`Logo ${jsonConfig.cliente_nome}`}
                                                    />
                                                    <h3 className="mt-6 text-2xl text-center font-bold tracking-tight text-gray-900">
                                                        Autenticar e-mail
                                                    </h3>
                                                </>) : (<>
                                                    <img
                                                        className="h-[5em] w-auto"
                                                        src={`data:image/png;base64,${states.clienteConfig.logo}`}
                                                        alt={`Logo ${states.clienteConfig.cliente_nome}`}
                                                    />
                                                    <h3 className="mt-6 text-2xl text-center font-bold tracking-tight text-gray-900">
                                                        {`Autenticar e-mail da área do ${states.clienteConfig.cliente_nome}`}
                                                    </h3>
                                                </>)
                                            }
                                        </div>


                                        <div className="mt-8">
                                            <div className="mt-6">
                                                <div className="flex flex-row">
                                                    <button
                                                        onClick={action}
                                                        type="button"
                                                        disabled={states.isLoading}
                                                        className={states.isLoading ? "w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600   focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" : "w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"}>
                                                        Autenticar e-mail
                                                    </button>
                                                    {
                                                        states.isLoading &&
                                                        <div className="ml-2">
                                                            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid"></div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>) : (
                                    <>
                                        <div className="flex flex-col justify-center items-center">
                                            <img
                                                className="h-[5em]"
                                                src={`${jsonConfig.urlReact}/logo.png`}
                                                alt="Soter Tec Logo"
                                            />
                                            <p className="mt-6 w-[50%] text-center font-bold tracking-tight text-gray-900">
                                                {
                                                    states.msgErrorAcessoCliente
                                                }
                                            </p>
                                        </div>
                                    </>
                                )
                            }

                            <div className="mt-8 flex items-center justify-center">
                                <Footer />
                            </div>
                        </div>
                    </div>
                    <div className="relative hidden w-0 flex-1 lg:block">
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src={jsonConfig.urlReact + '/background.jpeg'}
                            alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forgot